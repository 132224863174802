import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent {
  status:any = -1;
  offers:any = [];
  page:any = 1;
  limit:any = "10";

  modal:any = { open:false, 'cntx':'', obj:{} }

  constructor(public languageService:LanguageServiceService,public system:SystemService, public http:HttpClient, public router:Router){
  }

  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(cntx == 'mark-accept') {
      this.modal.obj.advance_value = "10";
    }
  }
  closeModal() { this.modal.open = false; }
  
  switchType() { 
    this.system.GetOffers(this.status, this.page, this.limit);
  }

  switchLimit() {
    this.system.GetOffers(this.status, this.page, this.limit);
  }


  ngOnInit() {
    this.system.GetOffers(this.status, this.page, this.limit);
  }

  openOfferDropdown(offer) {
    offer.show = true;
    offer.allowedClose = false;
    setTimeout(()=> { offer.allowedClose = true; }, 250);
  }
  onOfferDropDownClose(ev, offer) {
    if(offer.allowedClose) {
      offer.show = false;
    }
  }

  RemoveOffer() {
    this.http.delete("https://api.nexa.bg/offers/" + this.modal.obj.uid, {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetOffers(this.status, this.page, this.limit); this.closeModal(); });
  }

  MarkDenied() {
    this.modal.obj.status = 0;
    this.http.put("https://api.nexa.bg/offers/" + this.modal.obj.uid, { 'offer':this.modal.obj }, {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetOffers(this.status, this.page, this.limit); this.closeModal(); });
  }

  MarkAccepted() {
    this.modal.obj.status = 2;
    this.http.put("https://api.nexa.bg/offers/" + this.modal.obj.uid, { 'offer':this.modal.obj }, {
      withCredentials: true
    })
    .subscribe(response => { 
      this.system.GetOffers(this.status, this.page, this.limit); this.closeModal(); 
      if(this.modal.obj.invoice) {
        this.router.navigate(["/add-invoice/offer/" + this.modal.obj.uid])
      }
    });
  }

  goToPage(page) { this.page = page; this.system.GetOffers(this.status, this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetOffers(this.status, this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetOffers(this.status, this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetOffers(this.status, this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalOffersPages(); this.system.GetOffers(this.status, this.page, this.limit); }


  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }
}
