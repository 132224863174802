import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent {
 client = { name:'', mol:'', type:'company', bulstat:'', address:'', city:'',country:'', website:'', email:'', phone:'',registered_vat:'', client_group:'1' };

  constructor(public languageService:LanguageServiceService,public system:SystemService, public http:HttpClient, public router:Router) {
    
  }

  AddClient() {
    this.http.post("https://api.nexa.bg/clients", { 'client':this.client}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.router.navigate(['/clients']);
    });
  }

  ReturnIsAllowedToAddClient(){ 
    if(this.client.type == 'company') {
      if(this.client.name.length < 2) {
        return false;
      } else {
        return true;
      }
    } else {
      if(this.client.name.length < 2) {
        return false;
      } else {
        return true;
      }
    }
  }
}
