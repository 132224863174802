import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-invoice-addons',
  templateUrl: './invoice-addons.component.html',
  styleUrls: ['./invoice-addons.component.css']
})
export class InvoiceAddonsComponent {
  type:any = "all";
  invoices:any = [];
  page:any = 1;
  limit:any = "10";

  modal:any = { open:false, 'cntx':'', obj:{} }

  constructor(public languageService:LanguageServiceService,public system:SystemService, public http:HttpClient){

  }
  
  switchType() { 
    this.system.GetInvoicesAddons(this.type, this.page, this.limit);
  }

  switchLimit() {
    this.system.GetInvoicesAddons(this.type, this.page, this.limit);
  }


  ngOnInit() {
    this.system.GetInvoicesAddons(this.type, this.page, this.limit);
  }

  goToPage(page) { this.page = page; this.system.GetInvoicesAddons(this.type, this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetInvoicesAddons(this.type, this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetInvoicesAddons(this.type, this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetInvoicesAddons(this.type, this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalInvoiceAddonsPages(); this.system.GetInvoicesAddons(this.type, this.page, this.limit); }
}
