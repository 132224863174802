import { Component } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { HttpClient } from '@angular/common/http';

declare var htmlParser;
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent {
  status:any = -1;
  contacts:any = [];
  page:any = 1;
  limit:any = "10";
  available_clients:any = [];

  modal:any = { open:false, 'cntx':'', obj:{} }
  sideModal:any = { open:false, 'cntx':'', obj:{} }

  constructor(public languageService:LanguageServiceService,public system:SystemService, public http:HttpClient){
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
      this.available_clients.unshift({ 'name':'Няма назначен клиент', uid:-1 })
    });

    // this.http.get("https://api.nexa.bg/gov-uic-check/115752903", {
    //   withCredentials: true
    // })
    // .subscribe(response => {
    //   let parsed_data = htmlParser(response['sections'][0]['subDeeds'][0]['groups'][0]['fields'][4]['htmlData']);
    //   // console.log(response);
    //   // console.log(parsed_data);
    //   // console.log(parsed_data['children'][0]['children'][0]['children'][0]['content'].split(":")[1]); // COUNTRY
    //   // console.log(parsed_data['children'][0]['children'][0]['children'][4]['content'].split(":")[1].split(",")[0]); // CITY
    //   // console.log(parsed_data['children'][0]['children'][0]['children'][8]['content'].split("Телефон")[0]); // ADDRESS
    // });

    // this.http.post("https://nitehog.com/wp-admin/admin-ajax.php", { "action":"app_info" },)
    // .subscribe(response => {
    //   console.log(response);
    //   // console.log(parsed_data);
    //   // console.log(parsed_data['children'][0]['children'][0]['children'][0]['content'].split(":")[1]); // COUNTRY
    //   // console.log(parsed_data['children'][0]['children'][0]['children'][4]['content'].split(":")[1].split(",")[0]); // CITY
    //   // console.log(parsed_data['children'][0]['children'][0]['children'][8]['content'].split("Телефон")[0]); // ADDRESS
    // });
  }

  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(cntx == 'mark-accept') {
      this.modal.obj.advance_value = "10";
    }
  }
  closeModal() { this.modal.open = false; }
  
  switchType() { 
    this.system.GetContacts(this.page, this.limit);
  }

  switchLimit() {
    this.system.GetContacts(this.page, this.limit);
  }


  ngOnInit() {
    this.system.GetContacts(this.page, this.limit);
  }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }

  RemoveContact() {
    this.http.delete("https://api.nexa.bg/contacts/" + this.modal.obj.uid, {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetContacts(this.page, this.limit); this.closeModal(); });
  }

  AddContact() {
    this.http.post("https://api.nexa.bg/contacts", { 'contact':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetContacts(this.page, this.limit);
    });
  }

  EditContact() {
    this.http.put("https://api.nexa.bg/contacts/" + this.sideModal.obj.uid, { 'contact':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetContacts(this.page, this.limit);
    });
  }

  ReturnClientName(id) {
    if(this.available_clients.filter(client => client.uid == id).length > 0) {
      return this.available_clients.filter(client => client.uid == id)[0].name;
    } else {
      return "Зареждане...";
    }
  }

  ReturnIsContactAddable() {
    if(this.sideModal.obj.first_name != '' && this.sideModal.obj.last_name != '') {
      return false;
    } else {
      return true;
    }
  }

  goToPage(page) { this.page = page; this.system.GetContacts(this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetContacts(this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetContacts(this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetContacts(this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalContactsPage(); this.system.GetContacts(this.page, this.limit); }
}
