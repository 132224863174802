import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-offer',
  templateUrl: './add-offer.component.html',
  styleUrls: ['./add-offer.component.css']
})
export class AddOfferComponent {
  modal:any = { open:false, cntx:'', obj:{} }
  offer:any = { client_id:-1, client_name:"", client_mol:"",items:[{ id:1, name:"", quantity:1, vat:20, unit:'бр.', price:0.00 }], date_added:'00.00.0000', 'currency_name':'BGN', 'currency_label':'лв.', 'currency_position':'back', 'currency_spacing':1 }
  available_clients:any = [];
  results:any = [];
  
  sideModal:any = { open:false, 'cntx':'', 'obj':{} };

  constructor(public languageService:LanguageServiceService,public location:Location,public http:HttpClient, public router:Router, public system:SystemService) {
    let dt = new Date();
    this.offer.date_added = this.returnPaddedZero(dt.getDate(), 2) + "." + this.returnPaddedZero(dt.getMonth() + 1, 2) + "." + dt.getFullYear();
  
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      console.log(response);
      this.available_clients = response['clients'];
    });
  }

  changedRow(idx, item) {
    this.ChangedName(item);
    if(idx == this.offer.items.length - 1) {
      if(this.offer.items[idx].name.length != 0 || this.offer.items[idx].quantity != 0 || this.offer.items[idx].price != 0) {
        this.offer.items.push({ id:1, name:"", quantity:1, vat:20, unit:'бр.', price:0.00 });
      } 
    }
  }

  ChangedName(item) {
    this.http.post("https://api.nexa.bg/services/search/name", { 'name':item.name }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.results = response['services'];
    });
  }

  changedCurrency() {
    let selected_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.name == this.offer.currency_name)[0];
    this.offer.currency_label = selected_currency.label;
    this.offer.currency_position = selected_currency.position;
    this.offer.currency_spacing = selected_currency.spacing;
  }


  OpenModal(cntx, obj) {
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }
  }

  AddItemToInvoice() {
    this.offer.items.push({ 'name':this.modal.obj.name, 'type':this.modal.obj.type, 'quantity':this.modal.obj.quantity, 'price':this.modal.obj.price, 'vat':this.modal.obj.vat });
    this.modal.open = false;
  }

  AddOffer() {
    let products = "";
    for(var pi = 0; pi < this.offer.items.length; pi++) {
      if(this.offer.items[pi].name.length > 0 || this.offer.items[pi].quantity > 0 || this.offer.items[pi].price != 0) {
        products += this.offer.items[pi].name + "|" + this.offer.items[pi].price + "|" + this.offer.items[pi].unit + "|" + this.offer.items[pi].quantity + "|" + this.offer.items[pi].vat + ";";
      }
    }
    
    let split_date_add = this.offer.date_added.split(".");
    let dt_added = new Date(split_date_add[2] + "-" + split_date_add[1] + "-" + split_date_add[0]);
    let reformed_dt_added = dt_added.getFullYear() + "-" + this.returnPaddedZero(dt_added.getMonth() + 1, 2) + "-" + this.returnPaddedZero(dt_added.getDate() + 1, 2);
    this.http.post("https://api.nexa.bg/offers", { 'offer':{ 'client_name':this.offer.client_name, 'client_id':this.offer.client_id, 'date_added':reformed_dt_added, total:this.returnTotal(),
    status:1, 'products':products, notes:"", 'added_by':this.system.ReturnMe().first_name + " "  + this.system.ReturnMe().last_name, user_added:-1, short_description:"", 'comment':'', 'currency':'лв.'}}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.router.navigate(['/offers']);
    });
  }

  clearSelect(item) {
    setTimeout(() => {
      item.selecting = false;
    }, 250);
  }

  focusSelect(item) {
    item.selecting = true;
    this.results = [];
  }

  selectResult(item, result) {
    item.name = result.name;
    item.name += ' - ' + result.description;
    item.price = result.price;
    item.vat = result.vat;
    if(item.quantity == 0) { item.quantity = 1; }
    item.selecting = false;
  }

  
  AddClient() {
    this.http.post("https://api.nexa.bg/clients", { 'client':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetAvailableClients();
      this.closeSideModal();
    });
  }

  GetAvailableClients() {
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });
  }

  
  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }


  selectedClient() {
    let client = this.available_clients.filter(client => client.uid == this.offer.client_id)[0];
    this.http.get("https://api.nexa.bg/clients/" + this.offer.client_id, {
      withCredentials: true
    })
    .subscribe(response => {
      this.offer.client_name = response['name'];
      this.offer.client_data = response;
      this.offer.going_to = response['mol'];
    });
  }

  
  goBack() {
    this.location.back()
  }

  ReturnIsAllowedToAddClient(){ 
    if(this.sideModal.obj.type == 'company') {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    } else {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    }
  }


  removeItemFromOffer(idx) {
    this.offer.items.splice(idx, 1);
  }

  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }


  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.offer.items.length; i++) {
      total += this.offer.items[i].price * this.offer.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.offer.items.length; i++) {
      total += (this.offer.items[i].price * this.offer.items[i].quantity) * ( this.offer.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal())).toFixed(2);
  }

  returnFixed(num) { return num.toFixed(2); }

  returnPriceWithCurrency(price) {
    let str = '';
    if(this.offer.currency_position == 'front') {
      str += this.offer.currency_label;
      if(this.offer.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(this.offer.currency_spacing == 1) { str += ' ' }
      str += this.offer.currency_label;
    }
    return str;
  }
}
