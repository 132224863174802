import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { DomSanitizer } from '@angular/platform-browser';


import * as html2pdf from 'html2pdf.js';
@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css']
})
export class ViewOrderComponent {
  order:any = { items:[] };
  client:any = {};
  id:any;

  pdfSrc:any;
  pdf:any;
  pdfResponse:any;
  print_template:any;
  showDone:any = false;

  modal:any = { open:false, 'cntx':'', obj:{} }

  constructor(public languageService:LanguageServiceService,public route:ActivatedRoute, public http:HttpClient, public system:SystemService, public sanitizer:DomSanitizer) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.id = id;
      this.http.get("https://api.nexa.bg/orders/" + id, {
        withCredentials: true
      })
      .subscribe(response => {
        this.order = response;
        let items = [];
        let products_split = this.order.products.split(";");
        for(var pi = 0; pi < products_split.length - 1; pi++) {
          let product_split = products_split[pi].split("|");
          items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2], "quantity":product_split[3], "vat":parseInt(product_split[4]) })
        }
        this.order.items = items;
        this.http.get("https://api.nexa.bg/clients/" + this.order.client_id, {
          withCredentials: true
        })
        .subscribe(response_client => {
          this.client = response_client;
          this.PreparePDF();
        });

        this.order.currency = response['currency'];
        let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.label == this.order.currency)[0];
        this.order.currency_name = my_default_currency.name;
        this.order.currency_label = my_default_currency.label;
        this.order.currency_position = my_default_currency.position;
        this.order.currency_spacing = my_default_currency.spacing;
      });
    });
  }

  PreparePDF() {
    this.http.get("https://crm.nexa.bg/templates/orders/"+ this.system.ReturnProfile().template +".html", {responseType: 'text'})
    .subscribe(response => {

      this.getBase64ImageFromURL('https://crm.nexa.bg/logo/'+ this.system.ReturnProfile().logo).then((data)=>{
        response = response.replace("{{invoice.num}}", this.order.num);
        response = response.replace("{{invoice.logo}}", "" + data);
        // response = response.replace("{{invoice.copy}}", 'ОРИГИНАЛ');
        response = response.replace("{{invoice.total_without_vat}}", this.returnPriceWithCurrency(this.returnStandardTotal()));
        response = response.replace("{{invoice.total_vat}}", this.returnPriceWithCurrency(this.returnVatTotal()));
        response = response.replace("{{invoice.total}}", this.returnPriceWithCurrency(this.returnTotal()));
        response = response.replace("{{invoice.date_added}}", this.returnFormattedDate(this.order.date_added));
        response = response.replace("{{invoice.date_added}}", this.returnFormattedDate(this.order.date_added));
        response = response.replace("{{invoice.created_by}}", this.system.ReturnMe().first_name + " " + this.system.ReturnMe().last_name);
        response = response.replace("{{cipher}}", this.system.ReturnMe().code);
        let bank_details = "";
        if(this.order.type_payment == '1') {
          bank_details += "<span>Банкови реквизити: "+ this.order.bank_details.bank +"</span>";
          bank_details += "<span>BIC: "+ this.order.bank_details.bic +"</span>";
          bank_details += "<span>IBAN: "+ this.order.bank_details.iban +"</span>";
        }
        response = response.replace("{{payment_details}}", bank_details);
  
        response = response.replaceAll("{{company.name}}", this.system.ReturnProfile().company_name);
        response = response.replace("{{company.eik}}", this.system.ReturnProfile().company_gov_num);
        response = response.replace("{{company.bulstat}}", this.system.ReturnProfile().company_vat);
        response = response.replace("{{company.address}}", this.system.ReturnProfile().company_address);
        response = response.replace("{{company.city}}", this.system.ReturnProfile().company_city);
        response = response.replaceAll("{{company.owner}}", this.system.ReturnProfile().company_owner);
        response = response.replaceAll("{{primary_color}}", this.system.ReturnProfile().primary_color);
        response = response.replaceAll("{{accent_color}}", this.system.ReturnProfile().accent_color);
        //#ffc808
        //#363a44
        response = response.replaceAll("{{client.name}}", this.client.name);
        response = response.replace("{{client.eik}}", this.client.bulstat);
        response = response.replace("{{client.bulstat}}", this.client.registered_vat);
        response = response.replace("{{client.address}}", this.client.address);
        response = response.replace("{{client.city}}", this.client.city);
        response = response.replaceAll("{{client.owner}}", this.client.mol);
  
        let rows = "";
        for(let i = 0; i < this.order.items.length; i++) {
          rows += "<tr>";
          rows += "<td>" + this.order.items[i].name + "</td>";
          rows += "<td>" + this.returnPriceWithCurrency(this.order.items[i].price) + "</td>";
          rows += "<td>" + this.order.items[i].quantity + "</td>";
          rows += "<td>" + this.order.items[i].unit + "</td>";
          rows += "<td>0%</td>";
          rows += "<td>" + this.order.items[i].vat + "</td>";
          let total = this.returnFixed((this.order.items[i].price * this.order.items[i].quantity) * (1 + (this.order.items[i].vat / 100))) + "" + this.order.currency;
          rows += "<td>" + total + "</td>"
          rows += "</tr>";
        }
        response = response.replace("{{rows}}", rows);
        this.pdfResponse = response;
        let safe_html = this.sanitizer.bypassSecurityTrustHtml(response);
        this.print_template = safe_html;
        this.pdfSrc = response;

        var opt = {
          margin: 0,
          filename: 'output.pdf',
          image: { type:'jpeg', quality:1 },
          html2canvas: { scale:4, dpi:192 },
          jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
        }  
        html2pdf().from(response).set(opt).toPdf().output('datauristring').then( (data: Blob) => {
          this.pdfSrc = data
        })
      });
    });
  }

  
  DownloadPDF() {
    var opt = {
      margin: 0,
      filename: this.order['client_name'] + "-order-" + this.order['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf',
      image: { type:'jpeg', quality:1 },
      html2canvas: { scale:4, dpi:192 },
      jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
    }  
    html2pdf().from(this.pdfResponse).set(opt).toPdf().save();
  }

  PrintPDF() {
    document.getElementsByTagName('pdf-print')[0].getElementsByTagName('button')[0].click();
    // var opt = {
    //   margin: 0,
    //   filename: 'output.pdf',
    //   image: { type:'jpeg', quality:0.98 },
    //   html2canvas: { scale:1 },
    //   jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
    // }  
    // html2pdf().from(this.pdfResponse).set(opt).print();
  }

  SendDocument() {
    var opt = {
      margin: 0,
      filename: this.order['client_name'] + "-" + this.order['id'] + "-" + this.order['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf',
      image: { type:'jpeg', quality:1 },
      html2canvas: { scale:4, dpi:192 },
      jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
    }  
    html2pdf().from(this.pdfResponse).set(opt).output('datauristring').then( (data: Blob) => {
      this.http.post("https://api.nexa.bg/send-mail", { 'recipients':[this.modal.obj.contacts.filter(contact => contact.uid == this.modal.obj.selected_contact)[0]['mail']], 'subject':"Получихте поръчка", "text":"", "html":this.system.ReturnTemplates().offers, "attachment":{ 'filename':"offer" + this.returnDateForSend(this.order.date_added) + ".pdf", "path":data  } }, {
        withCredentials: true
      })
      .subscribe(response_mail => {
        this.showDone = true;
      });
    })
    setTimeout(()=>{
      this.closeModal();
      this.showDone = true;
      setTimeout(()=>{
        this.showDone = false;
      }, 2000);
    }, 700);
  }

  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(obj.contacts.length > 0) {
      this.modal.obj.selected_contact = obj.contacts[0].uid;
    }
  }
  closeModal() { this.modal.open = false; }



  returnFixed(num) { return num.toFixed(2); }
  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.order.items.length; i++) {
      total += this.order.items[i].price * this.order.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.order.items.length; i++) {
      if(this.order.items[i].vat != 0) { total += (this.order.items[i].price * this.order.items[i].quantity) * ( this.order.items[i].vat / 100); }
    }
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal())).toFixed(2);
  }

  returnPriceWithCurrency(price) {
    let str = '';
    if(this.order.currency_position == 'front') {
      str += this.order.currency_label;
      if(this.order.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(this.order.currency_spacing == 1) { str += ' ' }
      str += this.order.currency_label;
    }
    return str;
  }

  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
    
  }

  returnDateForSend(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "" + date_reorder[1] + "" + date_reorder[0];
  }
}
