import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css']
})
export class ExpensesComponent {
  modal:any = { open:false, 'cntx':'', obj:{} }
  status:any = 1;
  page:any = 1;
  limit:any = 10;

  constructor(public system:SystemService, public http:HttpClient, public languageService:LanguageServiceService) {}

  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(cntx == 'mark-accept') {
      this.modal.obj.advance_value = "10";
    }
  }
  closeModal() { this.modal.open = false; }
  
  switchType() { 
    this.system.GetExpenses(this.status, this.page, this.limit);
  }

  switchLimit() {
    this.system.GetExpenses(this.status, this.page, this.limit);
  }


  ngOnInit() {
    this.system.GetExpenses(this.status, this.page, this.limit);
  }

  openOfferDropdown(offer) {
    offer.show = true;
    offer.allowedClose = false;
    setTimeout(()=> { offer.allowedClose = true; }, 250);
  }
  onOfferDropDownClose(ev, offer) {
    if(offer.allowedClose) {
      offer.show = false;
    }
  }

  RemoveOrder() {
    this.http.delete("https://api.nexa.bg/orders/" + this.modal.obj.uid, {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetExpenses(this.status, this.page, this.limit); this.closeModal(); });
  }

  goToPage(page) { this.page = page; this.system.GetExpenses(this.status, this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetExpenses(this.status, this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetExpenses(this.status, this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetExpenses(this.status, this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalExpensesPage(); this.system.GetExpenses(this.status, this.page, this.limit); }


  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }

  returnProductCount(order) {
    return order.products.split(";").length;
  }
}
