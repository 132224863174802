import { Component } from '@angular/core';
import { SystemService } from '../system.service';
import { LanguageServiceService } from '../language-service.service';
import { HttpClient } from '@angular/common/http';

declare var sha1;
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent {
  available_clients:any = [];

  sideModal:any = { open:false, 'cntx':'', obj:{} }

  constructor(public system:SystemService, private http:HttpClient, public languageService:LanguageServiceService){
    this.system.GetTasks();
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });
  }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    if(cntx == 'add-task'){
      this.sideModal.obj.members.push(this.system.ReturnMe());
    }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }

  selectedClient() {
    let client_filter = this.available_clients.filter(client => client.uid == this.sideModal.obj.client_id);
    if(client_filter.length > 0) {
      this.sideModal.obj.client_name = client_filter[0].client_name;
    }
  }

  AddTask(){
    let members_idx = "";
    let members = this.sideModal.obj.members;
    members.splice(0, 1);
    for(let mi = 0; mi < members.length; mi++) {
      if(mi > 0) { members_idx += ';'; }
      members_idx += members[mi].uid;
    }
    //name, description, dt, members, project, kanban, created_by, client_id, client_name, mdl
    //  { 'name':name, 'description': description, 'date_added': dt_reformed, 'date_ended':-1, 'deadline':'', 'status':0, 'members':members, 'project':project, 'kanban':kanban, 'created_by':created_by, 'client_id':client_id, 'client_name':client_name
    this.system.AddTask(this.sideModal.obj.name, this.sideModal.obj.description, new Date(), members_idx, '-1', this.sideModal.obj.kanban, this.system.ReturnMe().uid, this.sideModal.obj.client_id, this.sideModal.obj.client_name, this);
  }


  allowDrop(ev) {
    ev.preventDefault();
  }
  
  drag(ev, task) {
    ev.dataTransfer.setData("text", ev.target.id);
    ev.dataTransfer.setData("task", JSON.stringify(task));
  }
  
  drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    let task = JSON.parse(ev.dataTransfer.getData('task'));
    //alert();
    task.kanban = ev.target.getAttribute('id').split("-")[ev.target.getAttribute('id').split("-").length - 1];
    this.system.EditTask(task);
    ev.target.appendChild(document.getElementById(data));
  }


  OpenAddMember() {
    this.sideModal.obj.member_selecting = true;
    this.sideModal.obj.member_selecting_close = false;
    setTimeout(()=> {
      this.sideModal.obj.member_selecting_close = true;
    }, 250);
  }

  CloseAddMember() {
    if(this.sideModal.obj.member_selecting_close) {
      this.sideModal.obj.member_selecting = false;
      this.sideModal.obj.member_selecting_close = true;
    }
  }

  RemoveMemberFromTask(member, member_idx) {
    for(var mid = 0; mid < this.sideModal.obj.members.length; mid++) {
      if(mid == member_idx) { this.sideModal.obj.members.splice(mid, 1); }
    }
  }

  ReturnAvatarSha(user) {
    return sha1(user.uid.toString());
  }

  ReturnTaskByKanban(kanban) {
    return this.system.ReturnTasks().filter(task => task.kanban == kanban.uid);
  }

  ReturnMembers(members){
    if(members != null) {
      return members.split(";");
    }
  }

  ReturnAvatarShaByUid(uid) {
    return sha1(uid.toString());
  }

  ReturnSearchMembers() {
    let arr = [];
    if(this.sideModal.obj.add_member.length > 0) {
      arr = this.system.ReturnUsers().filter(user => user.first_name.toLowerCase().indexOf(this.sideModal.obj.add_member.toLowerCase()) > -1 || user.last_name.toLowerCase().indexOf(this.sideModal.obj.add_member.toLowerCase()) > -1)
    }
    for(var ui = 0; ui < this.sideModal.obj.members.length; ui++) {
      for(var rui = 0; rui < arr.length; rui++) {
        if(arr[rui].uid == this.sideModal.obj.members[ui].uid) {
          arr.splice(rui, 1);
        }
      }
    }
    return arr;
  }

  AddMemberToMembers(member) {
    this.sideModal.obj.add_member = '';
    this.sideModal.obj.members.push(member);
    this.sideModal.obj.member_selecting = false;
  }
}
