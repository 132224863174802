<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xlg mx-auto relative absolute left-0 right-0 overflow-hidden" *ngIf="modal.cntx == 'send-invoice'">
        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'send-invoice'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Изпращане на фактура</h2>

            <!-- <div class="flex" *ngIf="modal.obj.contacts.length == 0 && !modal.obj.has_email">
                <span>Няма въведени контакти за този клиент</span>
            </div>

            <div class="flex flex-col mb-4" *ngIf="modal.obj.contacts.length == 0 && modal.obj.has_email">
                <span class="w-full flex text-sm">Клиента няма въведени контактни лица, но има въведен стандартен е-мейл</span>
                <input class="w-full flex text-sm" type="text" style="border: 1px solid gray;height: 30px;border-radius: 5px;padding: 5px;margin-top: 5px;margin-bottom: 5px;" readonly value="{{ modal.obj.standard_email }}" />
                <span class="w-full flex text-xs text-gray-500">Ако не искате, да използвате стандартния е-мейл, моля въведете лице за контакт на клиента</span>
            </div> -->

            <div class="flex flex-col mb-4" >
                <span class="w-full flex text-sm">Изберете получател:</span>
                <div class="custom-multiselect">
                    <div class="custom-multiselect-option" (click)="SwitchSelectedRecepient(contact.mail)" *ngFor="let contact of modal.obj.contacts">
                        <div class="custom-multiselect-option-avatar">{{ ReturnFirstLetters(contact.first_name, contact.last_name) }}</div>
                        <div class="custom-multiselect-info">
                            <span class="custom-multiselect-info-name">{{ contact.first_name }} {{ contact.last_name }}</span>
                            <span class="custom-multiselect-info-email">{{ contact.mail }}</span>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" *ngIf="ReturnIsMarkedAsRecepient(contact.mail)" viewBox="0 0 24 24" fill="currentColor" class="checked-tick">
                            <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="custom-multiselect-option" *ngIf="modal.obj.standard_email != undefined">
                        <div class="custom-multiselect-option-avatar">O</div>
                        <div class="custom-multiselect-info">
                            <span class="custom-multiselect-info-name">Фирмен емейл</span>
                            <span class="custom-multiselect-info-email">{{ modal.obj.standard_email }}</span>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" *ngIf="ReturnIsMarkedAsRecepient(modal.obj.standard_email)" viewBox="0 0 24 24" fill="currentColor" class="checked-tick">
                            <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
                        </svg>
                    </div>
                </div>
            </div>
            <!-- <div class="addon-btn">Промени основния е-мейл</div> -->
            <div class="addon-btn secondary" (click)="openSideModal('add-contact', { 'first_name':'', 'last_name':'', 'client':modal.obj.invoice.client_id, 'position':'', 'mail':'', 'phone':'', old_obj:modal.obj })">Добавете контакт</div>

            <!-- <div class="flex flex-col mb-4" *ngIf="modal.obj.contacts.length > 0">
                <span class="text-sm mb-2 font-semibold">Изберете лице за контакт</span>
                <select class="w-full border p-2">
                    <option *ngFor="let contact of modal.obj.contacts" value="{{ contact.uid }}">{{ contact.first_name }} {{ contact.last_name }} ({{ contact.mail }})</option>
                </select>
            </div> -->

            <div class="flex w-full items-center">
                <input [(ngModel)]="modal.obj.copy" type="checkbox" id="copy" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="copy" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Копие за изпращача</label>
            </div>


            <div class="flex w-full items-center mt-2">
                <input [(ngModel)]="modal.obj.edit_template" type="checkbox" id="edit_template" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="edit_template" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Редактиране на шаблон преди изпращане</label>
            </div>

            <angular-editor class="w-full flex mt-4" [(ngModel)]="modal.obj.template" *ngIf="modal.obj.edit_template"></angular-editor>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Назад
                </button>	
                <!-- <button *ngIf="modal.obj.contacts.length == 0" (click) type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm mr-2">
                    Добави контакт
                </button>	 -->
                <button *ngIf="modal.obj.contacts.length > 0 || modal.obj.has_email" (click)="SendInvoice()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Изпрати
                </button>	
            </div>
        </div>
    </div>
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24" *ngIf="modal.cntx != 'send-invoice'">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'missing-external-invoice-settings'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Липсващи настройки</h2>

            <div class="flex">
                <span>Трябва да попълните липсващите чуждестранни настройки за вашата компания, преди да може да генерирате Invoice! </span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Назад
                </button>	
                <button routerLink="/settings" [queryParams]="{'settings_go_to': '1,2' }" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Към настройки
                </button>	
            </div>
        </div>


        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'remove-invoice'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Премахване на фактура</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да премахнете фактурата ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Не
                </button>	
                <button (click)="RemoveInvoice()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Да
                </button>	
            </div>
        </div>
        

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'anull-invoice'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Анулиране на фактура</h2>

            <div class="flex">
                <span>Сигурни ли сте, че искате да анулирате фактурата ?</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Не
                </button>	
                <button (click)="RemoveInvoice()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Да
                </button>	
            </div>
        </div>

    </div>
</div>
<!-- /Modal -->


<div class="side-modal" *ngIf="sideModal.open">
    <div class="backdrop"></div>
    <div class="modal" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'stats' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clip-rule="evenodd" />
                <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
              </svg>
              
            <span class="title float-left ml-2 font-bold">{{ languageService.ReturnTranslatedLabel('label_report') }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full">

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_type') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <select [(ngModel)]="sideModal.obj.type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8">
                        <option value="documents">{{ languageService.ReturnTranslatedLabel('label_report_documents') }}</option>
                    </select> 
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4">
                <div class="w-2/4 flex flex-col">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_start_date') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.date_start" #date_start id="date_start" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" type="text">
                    </div>
                </div>
    
                <div class="w-2/4 flex flex-col ml-4">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_end_date') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.date_end" #date_end id="date_end" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" type="text">
                    </div>
                </div>
            </div>

            <button class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" (click)="DownloadStats()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>{{ languageService.ReturnTranslatedLabel('action_issue_report') }}</span>
            </button>
        </div>
    </div>

    <div class="modal" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'add-contact' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
            </svg>
              
            <span class="title float-left ml-2 font-bold">{{ languageService.ReturnTranslatedLabel('add_contact') }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full">
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >{{ languageService.ReturnTranslatedLabel('label_contact_first_name') }}<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >{{ languageService.ReturnTranslatedLabel('label_contact_last_name') }}<span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.last_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_name') }}</label>
                <div class="flex w-full">
                    <ng-select style="width:calc(100% - 30px);"  class="mb-1 border-2 border-white rounded w-full text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" 
                    [items]="available_clients" 
                    bindLabel="name" 
                    bindValue="uid" 
                    (clear)="sideModal.obj.client = -1"
                    [(ngModel)]="sideModal.obj.client">
                    </ng-select>
                </div>
            </div>


            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_phone') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете телефон на контакт" type="text">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_email') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.mail" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете е-мейл на контакт" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_position') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.position" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете позиция на контакт" type="text">
                </div>
            </div>

            <button [ngClass]="{ 'disabled':ReturnIsContactAddable() }" class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" (click)="AddContact()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>{{ languageService.ReturnTranslatedLabel('add_contact') }}</span>
            </button>
        </div>
    </div>
</div>


<section class="min-h-screen custom-min-h-screen bg-nexa bg-nexa text-gray-800">
    <div class="flex flex-col p-4" style="padding-top:0px;padding-bottom:0px">
        <div class="float-left w-full p-2 mt-4">
            <div class="relative float-left font-bold text-black" style="font-size:24px;">{{ languageService.ReturnTranslatedLabel('menu_invoices') }}</div>

            <div class="flex items-center float-right gap-x-3">

                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" routerLink="/add-invoice/invoice/-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
    
                    <span>{{ languageService.ReturnTranslatedLabel('add_invoice') }}</span>
                </button>

                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" (click)="openSideModal('stats', { 'type':'documents', 'date_start':'01.09.2023' , 'date_end':'30.09.2023'})">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m6.75 12l-3-3m0 0l-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                      </svg>
                                         
    
                    <span>{{ languageService.ReturnTranslatedLabel('label_report') }}</span>
                </button>
            </div>
        </div>
        <div class="-mx-4 sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="p-3 bg-white md:rounded-lg h-14 border border-gray-200">
                    <div class="float-left" style="width:240px;">
                        <input type="text" class="h-8 p-2 font-semibold text-xs text-black rounded-lg search-field" (keyup)="SearchInvoices()" [(ngModel)]="search" placeholder="{{ languageService.ReturnTranslatedLabel('label_search') }}" />
                        <div class="btn float-right rounded-lg bg-positive text-white text-center search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>                              
                        </div>
                    </div>
                    <div class="float-left ml-2">
                        <select [(ngModel)]="type" (change)="switchType()" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                            <option value="all">{{ languageService.ReturnTranslatedLabel('label_all_invoices') }}</option>
                            <option value="proforma">{{ languageService.ReturnTranslatedLabel('label_proforma') }}</option>
                            <option value="invoice">{{ languageService.ReturnTranslatedLabel('label_invoice') }}</option>
                        </select>
                    </div>
                    <div class="float-left ml-2">
                        <select class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                            <option>{{ languageService.ReturnTranslatedLabel('label_all_payments') }}</option>
                            <option>{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</option>
                            <option>{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</option>
                            <option>{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</option>
                        </select>
                    </div>
                    <span class="float-right">
                        <div class="float-left ml-2">
                            <span class="mr-2 text-xs font-bold text-gray-600">{{ languageService.ReturnTranslatedLabel('label_results_per_page') }}</span>
                            <select (change)="switchLimit()" [(ngModel)]="limit" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <span class="page-btn" (click)="StartPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentInvoicePages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>                                                      
                        </span>
                        <span class="page-btn" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentInvoicePages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                              </svg>                              
                        </span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnCurrentInvoicePages() > 1" (click)="goToPage( system.ReturnCurrentInvoicePages() - 1)">{{ system.ReturnCurrentInvoicePages() - 1 }}</span>
                        <span class="page-btn bg-positive text-white hover:bg-positive hover:text-white" (click)="goToPage( system.ReturnCurrentInvoicePages())">{{ system.ReturnCurrentInvoicePages() }}</span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnTotalInvoicePages() > system.ReturnCurrentInvoicePages()" (click)="goToPage( system.ReturnCurrentInvoicePages() + 1)">{{ system.ReturnCurrentInvoicePages() + 1 }}</span>
                        <span class="page-btn" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentInvoicePages() == system.ReturnTotalInvoicePages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                        <span class="page-btn" (click)="LastPage()" [ngClass]="{ 'disabled':system.ReturnCurrentInvoicePages() == system.ReturnTotalInvoicePages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                    </span>
                </div>
            </div>
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="border border-gray-200  md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200 ">
                        <thead class="bg-white">
                            <tr class="text-sm font-bold text-black">
                                <th scope="col" class="py-3.5 px-4 text-left rtl:text-right" (click)="SwitchOrder('num')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_document') }}</span>

                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'num'"></div>
                                    </button>
                                </th>


                                <th scope="col" class="px-12 py-3.5 text-left rtl:text-right" (click)="SwitchOrder('client_name')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_client') }}</span>
                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'client_name'"></div>
                                    </button>
                                </th>

                                <th scope="col" class="px-12 py-3.5 text-left rtl:text-right" (click)="SwitchOrder('status')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        {{ languageService.ReturnTranslatedLabel('label_status') }}

                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'status'"></div>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right" (click)="SwitchOrder('date_added')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        {{ languageService.ReturnTranslatedLabel('label_information') }}
                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'date_added'"></div>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    <button class="flex items-center gap-x-3 focus:outline-none" (click)="SwitchOrder('type_payment')"> 
                                        {{ languageService.ReturnTranslatedLabel('label_payment_method') }}
                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'type_payment'"></div>
                                    </button>
                                </th>
                                    

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right" (click)="SwitchOrder('total_value')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        {{ languageService.ReturnTranslatedLabel('label_price') }}
                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'total_value'"></div>
                                    </button>
                                </th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200  ">
                            <tr *ngIf="system.ReturnInvoices().length == 0">
                                <td class="text-center p-4 bg-gray-100" colspan="6">{{ languageService.ReturnTranslatedLabel('label_no_invoices') }}</td>
                            </tr>
                            <tr *ngFor="let invoice of system.ReturnInvoices()">
                                <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                    <div class="flex flex-row">
                                        <div>
                                            <h2 class="font-medium text-gray-800  " *ngIf="invoice.subtype == 'invoice'">{{ languageService.ReturnTranslatedLabel('label_invoice') }}</h2>
                                            <h2 class="font-medium text-gray-800  " *ngIf="invoice.subtype == 'proforma'">{{ languageService.ReturnTranslatedLabel('label_proforma') }}</h2>
                                            <p class="text-sm font-normal text-gray-600 ">#{{ invoice.num }}</p>
                                        </div>
                                        <div *ngIf="invoice.external_invoice == 1" (click)="ViewExternalInvoice(invoice)">
                                            <svg style="margin-left: 10px;margin-top: 8px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clip-rule="evenodd" />
                                              </svg>
                                        </div>
                                    </div>
                           
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700 ">{{ invoice.client_name }}</h4>
                                    </div>
                                </td>
                                <td class="px-12 py-2 text-sm font-medium whitespace-nowrap" style="position:relative;">
                                    <div style="text-transform: uppercase;font-size: 10px;" (click)="openStatus(invoice)" class="inline-flex px-3 py-1 text-sm font-bold rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 " *ngIf="invoice.status == '2'">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_paid') }}</span>
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-emerald-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                          </button>
                                    </div>
                                    <div style="text-transform: uppercase;font-size: 10px;" (click)="openStatus(invoice)" class="inline-flex px-3 py-1 text-sm font-bold rounded-full text-orange-500 gap-x-2 bg-orange-100 " *ngIf="invoice.status == '1'">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}</span>
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-orange-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                          </button>                                      
                                    </div>
                                    <div style="text-transform: uppercase;font-size: 10px;" (click)="openStatus(invoice)" class="inline-flex px-3 py-1 text-sm font-bold rounded-full text-red-500 gap-x-2 bg-red-100/60 " *ngIf="invoice.status == '0'">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_anulled') }}</span>
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-red-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                          </button>
                                    </div>
                                    <div class="status-dropdown" *ngIf="invoice.open_status" (clickOutside)="closeOpenStatus(invoice, $event)">
                                        <div class="status-dropdown-item" (click)="changeInvoiceStatus(invoice, 1)">{{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}</div>
                                        <div class="status-dropdown-item" (click)="changeInvoiceStatus(invoice, 2)">{{ languageService.ReturnTranslatedLabel('label_paid') }}</div>
                                        <div class="status-dropdown-item" (click)="changeInvoiceStatus(invoice, 0)">{{ languageService.ReturnTranslatedLabel('label_anulled') }}</div>
                                    </div>
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div>
                                        <p class="text-gray-500 ">{{ languageService.ReturnTranslatedLabel('label_created_at_date') }} {{ returnFormattedDate(invoice.date_added) }}</p>
                                        <p class="text-gray-500 ">{{ languageService.ReturnTranslatedLabel('label_due_date') }} {{ returnFormattedDate(invoice.date_due) }}</p>
                                    </div>
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '3'">{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</div>
                                </td>


                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div class="flex item-center">
                                        {{ invoice.total_value }} {{ invoice.currency }}
                                    </div>
                                </td>

                                <td class="px-4 py-2 text-sm whitespace-nowrap text-right">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" routerLink="/print-invoice" [queryParams]="{id: invoice.uid }">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                            <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                        </svg>  
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_view_invoice') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" routerLink="/edit-invoice/{{ invoice.uid }}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>     
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_edit_invoice') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" (click)="openModal('send-invoice', { 'contacts':system.ReturnClientContacts(invoice.client_id), 'invoice':invoice, template:ReturnShadowCopy(this.system.ReturnTemplates())['invoices'], 'selected_contact':'', 'selected_recepients':[] })">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                        </svg>   
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_send_invoice') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative" (click)="DownloadInvoice(invoice)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z" clip-rule="evenodd" />
                                        </svg>      
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_download_invoice') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 group relative"  routerLink="/add-invoice/invoice/{{invoice.uid}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                        </svg>
                                          
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_duplicate_invoice') }}
                                        </div>
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 mr-2 group relative" routerLink="/print-invoice" [queryParams]="{id: invoice.uid, 'print':true }">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z" clip-rule="evenodd" />
                                        </svg>    
                                        
                                        <!-- Tooltip -->
                                        <div style="padding-left:10px;padding-right:10px;" class="opacity-0 bg-black text-white text-sm rounded-md py-2 absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 pointer-events-none transition-opacity duration-300 group-hover:opacity-100">
                                            {{ languageService.ReturnTranslatedLabel('label_hint_print_invoice') }}
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<script>
const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-te-toggle="tooltip"]'));
tooltipTriggerList.map((tooltipTriggerEl) => new te.Tooltip(tooltipTriggerEl));
</script>