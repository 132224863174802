import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-client-groups',
  templateUrl: './client-groups.component.html',
  styleUrls: ['./client-groups.component.css']
})
export class ClientGroupsComponent {
  client_groups:any = [];
  page:any = 1;
  limit:any = 10;
  type:any = "all";

  modal:any = { open:false, 'cntx':'', obj:{} }
  sideModal:any = { open:false, 'cntx':'', obj:{} }

  constructor(public languageService:LanguageServiceService,public system:SystemService, public http:HttpClient){

  }
  switchType() { 
    this.system.GetClientGroups(this.page, this.limit);
  }

  switchLimit() {
    this.system.GetClientGroups(this.page, this.limit);
  }
  
  ngOnInit() {
    this.system.GetClientGroups(this.page, this.limit);
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }

  AddClientGroup() {
    this.http.post("https://api.nexa.bg/client-groups", { 'client_group':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetClientGroups(this.page, this.limit);
    });
  }

  EditClientGroup() {
    this.http.put("https://api.nexa.bg/client-groups/" + this.sideModal.obj.uid, { 'client_group':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetClientGroups(this.page, this.limit);
      this.system.RefreshSystemClientGroups();
    });
  }

  RemoveClientGroup() {
    this.http.delete("https://api.nexa.bg/client-groups/" + this.modal.obj.uid, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeModal();
      this.system.GetClientGroups(this.page, this.limit);
    });
  }

  goToPage(page) { this.page = page; this.system.GetClientGroups(this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetClientGroups(this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetClientGroups(this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetClientGroups(this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalClientGroupsPages(); this.system.GetClientGroups(this.page, this.limit); }

  ReturnIsAllowedToAddClientGroup(){ 
    if(this.sideModal.obj.name.length < 2) {
      return false;
    } else {
      return true;
    }
  }
}
