import { HttpClient } from '@angular/common/http';
import { Component, ViewChild,ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { Location } from '@angular/common';

declare var $;
@Component({
  selector: 'app-edit-external-invoice',
  templateUrl: './edit-external-invoice.component.html',
  styleUrls: ['./edit-external-invoice.component.css']
})
export class EditExternalInvoiceComponent {
  sideModal:any = { open:false, 'cntx':'', obj:{} };
  modal:any = { open:false, cntx:'', obj:{} }
  id:any = -1;
  currency:any = 'EUR';
  currency_rate = 0.00;
  invoice:any = { invoice_num:3000000001, type:'invoice', days:"7", payment_type:'2', date_added:'00.00.0000' ,'currency_name':'BGN', 'currency_label':'лв.', 'currency_position':'back', 'currency_spacing':1, items:[    { id:1, name:"", quantity:1, vat:20, unit:'бр.', price:0.00 }], client_id:-1, client_name:"", client_mol:"", client_address:""  }
  available_clients:any = [
    { client_id:1, client_name:"Аксион Солюшънс ЕООД", client_mol:"Тодор Тодоров", client_address:"Бул.Освобождение 93, блок 193, вх.В, ет.2, офис 4"  },
    { client_id:2, client_name:"Темпекс ЕООД", client_mol:"Атанас Лозанов", client_address:"улица Георги Данчов 16"  }
  ]
  results:any = [];
  loaded:any = true;

  @ViewChild('date_paid') date_paid: ElementRef;

  constructor(public languageService:LanguageServiceService,private http:HttpClient, public router:Router, public system:SystemService, public route:ActivatedRoute, public location:Location) {
    let id = this.route.snapshot.params['id'];
    // let currency = this.route.snapshot.params['currency'];
    // let currency_rate = this.route.snapshot.params['currency_rate'];

    this.id = id;
    // this.currency_rate = parseFloat(currency_rate);
    if(id != -1) {
      this.http.get("https://api.nexa.bg/invoices/" + id, {
        withCredentials: true
      })
      .subscribe(response => {
        let invoice = response;
        let items = [];
        this.currency = invoice['external_currency'];
        this.currency_rate = parseFloat(invoice['external_rate']);
        let products_split = invoice['external_products'].split(";");
        items = this.system.ReturnProductsFromItems(products_split);
        items.splice(items.length - 1, 1);
        this.invoice.client_id = parseInt(invoice['client_id']);
        this.invoice.client_name = invoice['client_name'];
        this.invoice.bank_id = invoice['bank_id'];
        this.invoice.type = invoice['subtype'];
        this.invoice.payment_type = invoice['type_payment'];
        this.invoice.invoice_num = invoice['num'];
        this.invoice.items = items;
        // this.invoice.currency = invoice['currency'];
        this.invoice.status = invoice['status'];
        this.invoice.added_by = invoice['added_by'];
        this.selectedClient();
        
        let split_date_add = invoice['date_added'].split("T")[0].split("-");
        this.invoice.date_added = split_date_add[2] + "." + split_date_add[1] + "." + split_date_add[0];
        
        let split_date_due = invoice['date_due'].split("T")[0].split("-");
        this.invoice.date_due = split_date_due[2] + "." + split_date_due[1] + "." + split_date_due[0];

        let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency_found => currency_found.name == this.currency)[0];
        this.invoice.currency = my_default_currency;
        this.invoice.currency_name = my_default_currency.name;
        this.invoice.currency_label = my_default_currency.label;
        this.invoice.currency_position = my_default_currency.position;
        this.invoice.currency_spacing = my_default_currency.spacing;
        this.loaded = true;
      });
    }
  
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });
  }

  OpenModal(cntx, obj) {
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }
  }

  changedInvoiceType() {
    this.http.get("https://api.nexa.bg/invoices/max-num?subtype=" + this.invoice.type, {
      withCredentials: true
    })
    .subscribe(response => {
      this.invoice.invoice_num = response['max_num'];
    });
  }

  changedRow(idx, item) {
    //this.ChangedName(item);
    // if(idx == this.invoice.items.length - 1) {
    //   if(this.invoice.items[idx].name.length != 0 || this.invoice.items[idx].quantity != 0 || this.invoice.items[idx].price != 0) {
    //     this.invoice.items.push({ id:1, name:"", quantity:1, vat:20, unit:'бр.', price:0.00 });
    //   } 
    // }
  }

  ChangedName(item) {
    this.http.post("https://api.nexa.bg/services/search/name", { 'name':item.name }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.results = response['services'];
    });
  }

  clearSelect(item) {
    setTimeout(() => {
      item.selecting = false;
    }, 250);
  }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }xs



  focusSelect(item) {
    item.selecting = true;
    this.results = [];
  }

  selectResult(item, result) {
    item.name = result.name;
    item.name += ' - ' + result.description;
    item.price = result.price;
    item.vat = result.vat;
    if(item.quantity == 0) { item.quantity = 1; }
    item.selecting = false;
  }

  EditInvoice() {
    let products = "";
    for(var pi = 0; pi < this.invoice.items.length; pi++) {
      if(this.invoice.items[pi].name.length > 0 || this.invoice.items[pi].quantity > 0 || this.invoice.items[pi].price != 0) {
        products += this.invoice.items[pi].name + "|" + this.invoice.items[pi].price + "|" + this.invoice.items[pi].unit + "|" + this.invoice.items[pi].quantity + "|" + this.invoice.items[pi].vat + ";";
      }
    }
    
    this.http.put("https://api.nexa.bg/invoices/" + this.id, { 'invoice':{ 'external_invoice':1, 'external_rate':this.currency_rate, 'external_currency':this.invoice.currency_name, 'external_products':products  }}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.router.navigate(['/print-invoice-external'], {queryParams: {id: this.id}});
    });
  }

  AddItemToInvoice() {
    this.invoice.items.push({ 'name':this.modal.obj.name, 'type':this.modal.obj.type, 'quantity':this.modal.obj.quantity, 'price':this.modal.obj.price, 'vat':this.modal.obj.vat });
    this.modal.open = false;
  }
  removeItemFromInvoice(idx) {
    this.invoice.items.splice(idx, 1);
  }

  selectedClient() {
    let client = this.available_clients.filter(client => client.uid == this.invoice.client_id)[0];
    this.http.get("https://api.nexa.bg/clients/" + this.invoice.client_id, {
      withCredentials: true
    })
    .subscribe(response => {
      this.invoice.client_name = response['name'];
      this.invoice.client_data = response;
      this.invoice.going_to = response['mol'];
    });
  }

  changedCurrency() {
    let selected_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.name == this.invoice.currency_name)[0];
    this.invoice.currency_label = selected_currency.label;
    this.invoice.currency_position = selected_currency.position;
    this.invoice.currency_spacing = selected_currency.spacing;
  }

  AddedBank() { this.modal.open = false; }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }

  GetAvailableClients() {
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });
  }
  
  goBack() {
    this.location.back()
  }

  AddClient() {
    this.http.post("https://api.nexa.bg/clients", { 'client':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetAvailableClients();
      this.closeSideModal();
    });
  }

  
  ReturnIsAllowedToAddClient(){ 
    if(this.sideModal.obj.type == 'company') {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    } else {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    }
  }


  returnFixed(num) { return num.toFixed(2); }
  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.invoice.items.length; i++) {
      total += this.invoice.items[i].price * this.invoice.items[i].quantity;
    }
    total = total / this.currency_rate;
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.invoice.items.length; i++) {
      total += (this.invoice.items[i].price * this.invoice.items[i].quantity) * ( this.invoice.items[i].vat / 100);
    }
    total = total / this.currency_rate;
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal()) / this.currency_rate).toFixed(2);
  }
  returnPriceWithCurrency(price) {
    let str = '';
    if(this.invoice.currency_position == 'front') {
      str += this.invoice.currency_label;
      if(this.invoice.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(this.invoice.currency_spacing == 1) { str += ' ' }
      str += this.invoice.currency_label;
    }
    return str;
  }
  ReturnCurrencyPrice(price) {
    return (price / this.currency_rate).toFixed(2);
  }
}
