import { Component, ViewChild, ElementRef } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { HttpClient } from '@angular/common/http';

declare var sha1;
@Component({
  selector: 'app-storehouse-categories',
  templateUrl: './storehouse-categories.component.html',
  styleUrls: ['./storehouse-categories.component.css']
})
export class StorehouseCategoriesComponent {
  @ViewChild('inputImage', { static: false }) inputImage: ElementRef;
  @ViewChild('inputImage1', { static: false }) inputImage1: ElementRef;

  status:any = -1;
  offers:any = [];
  page:any = 1;
  limit:any = "10";
  available_storages:any = [];
  available_categories:any = [];
  base64String:any = "";
  filtered_available_categories:any = [];

  sideModal:any = { open:false, 'cntx':'', obj:{} }
  modal:any = { open:false, 'cntx':'', obj:{} }
  constructor(public languageService:LanguageServiceService, public system:SystemService, public http:HttpClient) {
    this.system.GetStorehouseCategories(1, 10);
    this.GetAvailableStorehouses();
    this.GetAvailableStorehousesCategories();
  }
  ngOnInit() { this.system.GetStorehouseCategories(this.page, this.limit);}


  // ********** MODAL FUNCTIONS ****************//
  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }
  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(this.sideModal.cntx == 'edit-storehouse-category') {
      this.sideModal.obj.image_file = {};
    }
  }
  closeSideModal() { this.sideModal.open = false;this.base64String = ""; }
  // *******************************************//

  // **************** HTTP FUNCTIONS ****************//
  GetAvailableStorehouses() {
    this.http.get("https://api.nexa.bg/storages?page=1&limit=1000", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_storages = response['storages'];
    });
  }

  GetAvailableStorehousesCategories() {
    this.http.get("https://api.nexa.bg/storage-categories?page=1&limit=1000", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_categories = response['storage_categories'];
    });
  }

  AddCategory() {
    const formData = new FormData();
    formData.append('file', this.sideModal.obj.image_file);

    this.http.post('https://images.nexa.bg/upload_category_image.php?u=' + sha1(this.system.ReturnMe().uid.toString()) + "&c=" + this.generateSafeUUID()  + "&ui=" + this.system.ReturnMe().uid.toString(), formData)
      .subscribe(response => {
        this.sideModal.obj.image = 'https://images.nexa.bg/' + response['file'];
        console.log('File uploaded successfully:', response);
        this.http.post("https://api.nexa.bg/storage-categories", { 'storage_category': { 'name':this.sideModal.obj.name, 'description':this.sideModal.obj.description, 'image':this.sideModal.obj.image, 'storage_id':this.sideModal.obj.storage_id, 'parent_id':this.sideModal.obj.parent_id }}, { withCredentials: true })
        .subscribe(resp => {
          this.closeSideModal();
          this.system.GetStorehouseCategories(this.page, this.limit);
        });
      });
  }

  
  EditCategory() {
    if(this.base64String == "") {
      this.http.put("https://api.nexa.bg/storage-categories/" + this.sideModal.obj.uid, { 'storage_category': { 'name':this.sideModal.obj.name, 'description':this.sideModal.obj.description, 'image':this.sideModal.obj.image, 'storage_id':this.sideModal.obj.storage_id, 'parent_id':this.sideModal.obj.parent_id }}, { withCredentials: true })
      .subscribe(resp => {
        this.closeSideModal();
        this.system.GetStorehouseCategories(this.page, this.limit);
      });
    } else {
      const formData = new FormData();
      formData.append('file', this.sideModal.obj.image_file);
      let split_image = this.sideModal.obj.image.split("_")[1];
      this.http.post('https://images.nexa.bg/upload_category_image.php?u=' + sha1(this.system.ReturnMe().uid.toString()) + "&c=" + split_image  + "&ui=" + this.system.ReturnMe().uid.toString(), formData)
        .subscribe(response => {
          this.sideModal.obj.image = 'https://images.nexa.bg/' + response['file'];
          console.log('File uploaded successfully:', response);
          this.http.put("https://api.nexa.bg/storage-categories/" + this.sideModal.obj.uid, { 'storage_category': { 'name':this.sideModal.obj.name, 'description':this.sideModal.obj.description, 'image':this.sideModal.obj.image, 'storage_id':this.sideModal.obj.storage_id, 'parent_id':this.sideModal.obj.parent_id }}, { withCredentials: true })
          .subscribe(resp => {
            this.closeSideModal();
            this.system.GetStorehouseCategories(this.page, this.limit);
          });
        });
    }

  }

  RemoveCategory() {
    if(this.modal.obj.image != '') {
      this.http.post('https://images.nexa.bg/remove_image.php', { 'file_path':this.modal.obj.image })
      .subscribe(response => {
        this.http.delete("https://api.nexa.bg/storage-categories/" + this.modal.obj.uid, { withCredentials: true })
        .subscribe(resp => {
          this.closeModal();
          this.system.GetStorehouseCategories(this.page, this.limit);
        });
      });
    } else {
      this.http.delete("https://api.nexa.bg/storage-categories/" + this.modal.obj.uid, { withCredentials: true })
      .subscribe(resp => {
        this.closeModal();
        this.system.GetStorehouseCategories(this.page, this.limit);
      });
    }
   
  }
  // ************************************************//

  // *************** IMAGE FUNCTIONS ****************//
  triggerFileUpload() {this.inputImage.nativeElement.click();}
  triggerFileUpload1() {this.inputImage1.nativeElement.click();}

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.sideModal.obj.image_file = file;
      this.convertToBase64(file);
    }
  }

  onFileSelected1(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.sideModal.obj.image_file = file;
      this.convertToBase64(file);
    }
  }

  convertToBase64(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      this.base64String = (reader.result as string).split(',')[1];
    };
    reader.readAsDataURL(file);
  }

  ChangedStorehouse() {
    this.filtered_available_categories = this.available_categories.filter(category => category.storage_id == this.sideModal.obj.storage_id);
  }
  // ************************************************//

  // *************** UTILITY FUNCTIONS **************//
  switchType() { this.system.GetStorehouseCategories(this.page, this.limit);}
  switchLimit() {this.system.GetStorehouseCategories(this.page, this.limit);}

  goToPage(page) { this.page = page; this.system.GetStorehouseCategories(this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetStorehouseCategories(this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetStorehouseCategories(this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetStorehouseCategories(this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalStorehouseCategoriesPage(); this.system.GetStorehouseCategories(this.page, this.limit); }

  generateSafeUUID() {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uuid = '';
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uuid += chars.charAt(randomIndex);
    }
    return uuid;
  }
  
  // ************************************************//

  // ****************** RETURN FUNCTIONS ************//
  ReturnStorageName(storage_id) {
    let filtered_storage = this.available_storages.filter(storage => storage.uid == storage_id);
    if(filtered_storage.length > 0) { return filtered_storage[0].name; }
    else { return "Неразпределен"; }
  }

  ReturnAvailableCategoriesForStorage() {
    return this.available_categories.filter(category => category.storage_id == this.sideModal.obj.storage_id);
  }

  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }
  ReturnShadowCopy(obj) { return JSON.parse(JSON.stringify(obj)); }
  // ************************************************//

}
