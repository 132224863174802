
<div class="flex min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="p-4 w-full">
	<div class="container mx-auto py-6 px-4 bg-white">
		<div class="flex justify-between">
			<h2 class="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">{{ languageService.ReturnTranslatedLabel('title_add_notice') }}</h2>
			<div>
				<div class="relative mr-4 inline-block">
					<div routerLink="/invoices" class="text-white cursor-pointer w-10 h-10 rounded-full bg-gray-400 hover:bg-gray-500 inline-flex items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
						  </svg>						  
					</div>
				</div>
				<div class="relative mr-4 inline-block">
					<div [ngClass]="{ 'disabled':invoice.client_id == -1 }" (click)="AddInvoice()" class="text-white cursor-pointer w-10 h-10 rounded-full bg-green-400 hover:bg-green-500 inline-flex items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<rect x="0" y="0" width="24" height="24" stroke="none"></rect>
							<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
						</svg>	  
					</div>
				</div>
			</div>
		</div>

		<div class="flex mb-8 justify-between">
            <div>
				<div class="w-32 h-32 mb-1 border rounded-lg overflow-hidden relative bg-white">
					<img id="image" class="object-contain w-full h-32" src="../../assets/tempex-logo.svg" />
				</div>
			</div>
			<div class="w-2/4 ml-4">
				<div class="mb-2 md:mb-1 md:flex items-center">
					<label class="w-40 text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_invoice_num') }}</label>
					<span class="mr-4 inline-block hidden md:block">:</span>
					<div class="flex-1">
					    <input readonly [(ngModel)]="invoice.invoice_num" class="bg-gray-100 appearance-none border-2 border-white rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inline-full-name" type="text">
					</div>
				</div>

				<div class="mb-2 md:mb-1 md:flex items-center">
					<label class="w-40 text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_notice_type') }}</label>
					<span class="mr-4 inline-block hidden md:block">:</span>
					<div class="flex-1">
                        <select [(ngModel)]="invoice.type" class="bg-gray-100 border-2 border-white rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                            <option value="debit">{{ languageService.ReturnTranslatedLabel('label_debit_notice') }}</option>
                            <option value="credit">{{ languageService.ReturnTranslatedLabel('label_credit_notice') }}</option>
                        </select>
					<!-- <input class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-48 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 js-datepicker" type="text" id="datepicker1" placeholder="eg. 17 Feb, 2020" x-model="invoiceDate" x-on:change="invoiceDate = document.getElementById('datepicker1').value" autocomplete="off" readonly> -->
					</div>
				</div>

				<div class="mb-2 md:mb-1 md:flex items-center">
					<label class="w-40 text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_payment_method') }}</label>
					<span class="mr-4 inline-block hidden md:block">:</span>
					<div class="flex-1">
                        <select [(ngModel)]="invoice.payment_type" class="bg-gray-100  border-2 border-white rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                            <option value="1">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</option>
                            <option value="2">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</option>
                            <option value="3">{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</option>
                        </select>
                    </div>
				</div>
			</div>
            <div class="w-2/4">
				<div class="mb-2 md:mb-1 md:flex items-center">
					<label class="w-40 text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_date_added') }}</label>
					<span class="mr-4 inline-block hidden md:block">:</span>
					<div class="flex-1">
					<input [(ngModel)]="invoice.date_added" class="bg-gray-100 appearance-none border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inline-full-name" type="text" placeholder="eg. #INV-100001" x-model="invoiceNumber">
					</div>
				</div>

				<div class="mb-2 md:mb-1 md:flex items-center">
					<label class="w-40 text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_time_to_pay) }}</label>
					<span class="mr-4 inline-block hidden md:block">:</span>
					<div class="flex-1">
                        <select id="countries" class="bg-gray-100  border-2 border-gray-200 rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500">
                            <option value="7">7 {{ languageService.ReturnTranslatedLabel('label_days') }}</option>
                            <option value="15">15 {{ languageService.ReturnTranslatedLabel('label_days') }}</option>
                        </select>
					</div>
				</div>

				<div class="mb-2 md:mb-1 md:flex items-center">
					<label class="w-40 text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_invoice_num_to') }}</label>
					<span class="mr-4 inline-block hidden md:block">:</span>
					<div class="flex-1">
					    <input [(ngModel)]="invoice.attached_invoice" class="bg-gray-100 appearance-none border-2 border-white rounded w-64 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" id="inline-full-name" type="text">
					</div>
				</div>
			</div>
		</div>

		<div class="-mx-3 md:flex mb-6">
			<div class="w-full px-3 mb-6 md:mb-0">
				<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="grid-first-name">
					{{ languageService.ReturnTranslatedLabel('label_client') }}
				</label>
				<ng-select  class="mb-1 bg-gray-100 border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" 
						(change)="selectedClient()"
						[items]="available_clients" 
						bindLabel="name" 
						bindValue="uid" 
						[(ngModel)]="invoice.client_id">
				</ng-select>
				<p class="text-red text-xs italic">{{ languageService.ReturnTranslatedLabel('label_choose_client_hint') }}</p>
			</div>
		</div>

		<div class="flex -mx-1 border-b py-2 items-start">
			<div class="w-20 px-1">
				<p class="text-gray-800 uppercase tracking-wide text-sm font-bold">#</p>
			</div>

			<div class="flex-1">
				<p class="text-gray-800 uppercase tracking-wide text-sm font-bold">{{ languageService.ReturnTranslatedLabel('label_description') }}</p>
			</div>

            <div class="px-1 w-20 text-left">
				<p class="text-gray-800 uppercase tracking-wide text-sm font-bold">{{ languageService.ReturnTranslatedLabel('label_quantity_short') }}</p>
			</div>


			<div class="px-1 w-20 text-left">
				<p class="text-gray-800 uppercase tracking-wide text-sm font-bold">{{ languageService.ReturnTranslatedLabel('label_metric') }}</p>
			</div>

            <div class="px-1 w-20 text-left">
				<p class="text-gray-800 uppercase tracking-wide text-sm font-bold">{{ languageService.ReturnTranslatedLabel('label_vat') }} (%)</p>
			</div>


			<div class="px-1 w-32 text-left">
				<p class="leading-none">
					<span class="block uppercase tracking-wide text-sm font-bold text-gray-800">{{ languageService.ReturnTranslatedLabel('label_single_price') }}</span>
					<span class="font-medium text-xs text-gray-500">{{ languageService.ReturnTranslatedLabel('label_without_vat_hint') }}</span>
				</p>
			</div>

			<div class="px-1 w-32 text-right">
				<p class="leading-none">
					<span class="block uppercase tracking-wide text-sm font-bold text-gray-800">{{ languageService.ReturnTranslatedLabel('label_total') }}</span>
					<span class="font-medium text-xs text-gray-500">{{ languageService.ReturnTranslatedLabel('label_with_vat_hint') }}</span>
				</p>
			</div>

			<div class="px-1 w-32 text-center">
			</div>
		</div>
	    <div  :key="invoice.id">
			<div class="flex -mx-1 py-2 border-b" *ngFor="let item of invoice.items;let idx = index">
				<div class="w-20 px-1">
					<p class="text-gray-800 uppercase tracking-wide text-sm font-bold" style="line-height:36px;">{{ idx + 1 }}</p>
				</div>

				<div class="flex-1">
					<input (keyup)="changedRow(idx)" class="mb-1 bg-gray-100 appearance-none border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" type="text" [(ngModel)]="item.name" placeholder="Въведете описание на продукта" />
				</div>

				<div class="px-1 w-20 text-right">
					<input (keyup)="changedRow(idx)" class="mb-1 bg-gray-100 appearance-none border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" type="number" [(ngModel)]="item.quantity" placeholder="К-во" />
				</div>

                <div class="px-1 w-20 text-right">
                    <select class="mb-1 bg-gray-100 appearance-none border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" type="number" [(ngModel)]="item.unit">
						<option>бр.</option>
						<option>м.</option>
					</select>
                </div>

                <div class="px-1 w-20 text-right">
                    <input (keyup)="changedRow(idx)" class="mb-1 bg-gray-100 appearance-none border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" type="number" [(ngModel)]="item.vat" placeholder="ДДС %" />
                </div>

                <div class="px-1 w-32 text-right">
					<input (keyup)="changedRow(idx)" class="mb-1 bg-gray-100 appearance-none border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" type="number" [(ngModel)]="item.price" placeholder="Ед.цена" />
				</div>

				<div class="px-1 w-32 text-right">
					<p class="text-gray-800" x-text="numberFormat(invoice.total)" style="line-height:36px;">{{ returnFixed((item.price * item.quantity) * (1 + (item.vat / 100))) }} лв</p>
				</div>

				<div class="px-1 w-32 text-right cursor-pointer">
					<span *ngIf="invoice.items.length > 1"  style="line-height:36px;" href="#" class="text-red-500 hover:text-red-600 text-sm font-semibold cursor-pointer" (click)="removeItemFromInvoice(idx)">{{ languageService.ReturnTranslatedLabel('item_action_remove') }}</span>
				</div>
			</div>
		</div>

		<div class="py-2 ml-auto mt-5 w-full sm:w-2/4 lg:w-1/4">
			<div class="flex justify-between mb-3">
				<div class="text-gray-800 text-right flex-1">{{ languageService.ReturnTranslatedLabel('label_base') }}</div>
				<div class="text-right w-40">
					<div class="text-gray-800 font-medium" x-html="netTotal">{{ returnStandardTotal() }} лв.</div>
				</div>
			</div>
			<div class="flex justify-between mb-4">
				<div class="text-sm text-gray-600 text-right flex-1">{{ languageService.ReturnTranslatedLabel('label_vat') }}</div>
				<div class="text-right w-40">
					<div class="text-sm text-gray-600" x-html="totalGST">{{ returnVatTotal() }} лв.</div>
				</div>
			</div>
		
			<div class="py-2 border-t border-b">
				<div class="flex justify-between">
					<div class="text-xl text-gray-600 text-right flex-1">{{ languageService.ReturnTranslatedLabel('label_total_with_vat') }}</div>
					<div class="text-right w-40">
						<div class="text-xl text-gray-800 font-bold" x-html="netTotal">{{ returnTotal() }} лв.</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

</div>