import { Component } from '@angular/core';
import { SystemService } from '../system.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { LanguageServiceService } from '../language-service.service';

declare var sha1;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
  currentProfile:any = {};
  originalProfile:any = {};
  avatar:any = "";
  avatar_uploaded:any = false; 
  avatar_changed:any = false;

  modal:any = { open:false, cntx:"", obj:{} };

  showDone:any = false;

  constructor(public system:SystemService, public domSanitizer:DomSanitizer, public http:HttpClient, public languageService:LanguageServiceService){
    this.originalProfile = JSON.parse(JSON.stringify(this.system.ReturnMe()));
    this.currentProfile = JSON.parse(JSON.stringify(this.system.ReturnMe()));
    this.avatar = this.returnSanitiziedAvatar("https://crm.nexa.bg/avatars/"+ sha1(this.originalProfile.uid.toString()));
    setTimeout(() => {
      this.originalProfile = JSON.parse(JSON.stringify(this.system.ReturnMe()));
      this.currentProfile = JSON.parse(JSON.stringify(this.system.ReturnMe()));
      this.avatar = this.returnSanitiziedAvatar("https://crm.nexa.bg/avatars/"+ sha1(this.originalProfile.uid.toString()))
    }, 500)

  }

  onFileSelected(event) {

    const file:File = event.target.files[0];

    if (file) {
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
          //this.message = "Only images are supported.";
          return;
      }
  
      const reader = new FileReader();
      // this.imagePath = files;
      reader.readAsDataURL(file); 
      reader.onload = (_event) => { 
          this.avatar = reader.result; 
          this.avatar_changed = true;
      }

        // this.fileName = file.name;

        // const formData = new FormData();

        // formData.append("thumbnail", file);

        // const upload$ = this.http.post("/api/thumbnail-upload", formData);


        // upload$.subscribe();

    }
}

SaveProfile() {
  if(this.avatar_changed) {
    this.UploadAvatar(this.avatar);
  }
  if(this.originalProfile.password != this.currentProfile.password) {
    this.currentProfile.password = sha1(this.currentProfile.password);
  }
  this.system.SaveProfile(this.currentProfile);
  setTimeout(()=>{
    this.ShowDone();
    this.originalProfile = JSON.parse(JSON.stringify(this.system.ReturnMe()));
    this.currentProfile = JSON.parse(JSON.stringify(this.system.ReturnMe()));
    let timestamp = Math.floor(Date.now() / 1000);
  }, 500);
}

ShowDone() {
  this.showDone = true;
  setTimeout(()=>{ this.showDone = false; }, 2000);
}

UploadAvatar(avatar) {
  this.http.post("avatar.php", { 'name':sha1(this.originalProfile.uid.toString()), 'file':avatar}).subscribe(response => { console.log("DONE") });
}

CheckPasswordCorrect() {
  if(sha1(this.modal.obj.old_password) != this.originalProfile.password) {
    this.modal.obj.show_old_password_error = true;
  } else {
    this.modal.obj.show_old_password_error = false;
  }
}


switchOldShowPassword() {
  if(this.modal.obj.old_password_show == 'text') { this.modal.obj.old_password_show = 'password'; }
  else { this.modal.obj.old_password_show = 'text'; }
}


switchNewShowPassword() {
  if(this.modal.obj.new_password_show == 'text') { this.modal.obj.new_password_show = 'password'; }
  else { this.modal.obj.new_password_show = 'text'; }
}

SaveNewPassword() {
  this.currentProfile.password = this.modal.obj.new_password;
  this.SaveProfile();
  this.CloseModal();
}

OpenModal(cntx, obj) {
  this.modal = { 'open':true, 'cntx':cntx, 'obj':obj }
}
CloseModal() { this.modal.open = false; }


ReturnIsProfileChanged() {
  if(this.originalProfile.first_name != this.currentProfile.first_name || this.originalProfile.last_name != this.currentProfile.last_name || this.avatar_changed || this.originalProfile.code != this.currentProfile.code) {
    return true;
  } else {
    return false;
  }
}

  returnSanitiziedAvatar(url) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  ReturnDefaultAvatar() {
    let avatar = 'assets/placeholder.jpeg';
    return avatar;
  }

  ReturnOldPasswordIsCorrect() {
    if(sha1(this.modal.obj.old_password) != this.originalProfile.password) {
      return false;
    } else {
      return true;
    }
  }
}
