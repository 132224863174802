<section class="min-h-screen custom-min-h-screen bg-nexa bg-nexa text-gray-800">
  <div class="flex flex-col p-4" style="padding-top:0px;padding-bottom:0px">
    <div class="mt-4">
        <div class="flex flex-wrap -mx-6">
            <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
                <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white" style="box-shadow:0px 0px 10px 1px rgba(0,0,0,0.1)">
                    <div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
                        <!-- <svg class="h-8 w-8 text-white" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.2 9.08889C18.2 11.5373 16.3196 13.5222 14 13.5222C11.6804 13.5222 9.79999 11.5373 9.79999 9.08889C9.79999 6.64043 11.6804 4.65556 14 4.65556C16.3196 4.65556 18.2 6.64043 18.2 9.08889Z" fill="currentColor"/>
                            <path d="M25.2 12.0444C25.2 13.6768 23.9464 15 22.4 15C20.8536 15 19.6 13.6768 19.6 12.0444C19.6 10.4121 20.8536 9.08889 22.4 9.08889C23.9464 9.08889 25.2 10.4121 25.2 12.0444Z" fill="currentColor"/>
                            <path d="M19.6 22.3889C19.6 19.1243 17.0927 16.4778 14 16.4778C10.9072 16.4778 8.39999 19.1243 8.39999 22.3889V26.8222H19.6V22.3889Z" fill="currentColor"/>
                            <path d="M8.39999 12.0444C8.39999 13.6768 7.14639 15 5.59999 15C4.05359 15 2.79999 13.6768 2.79999 12.0444C2.79999 10.4121 4.05359 9.08889 5.59999 9.08889C7.14639 9.08889 8.39999 10.4121 8.39999 12.0444Z" fill="currentColor"/>
                            <path d="M22.4 26.8222V22.3889C22.4 20.8312 22.0195 19.3671 21.351 18.0949C21.6863 18.0039 22.0378 17.9556 22.4 17.9556C24.7197 17.9556 26.6 19.9404 26.6 22.3889V26.8222H22.4Z" fill="currentColor"/>
                            <path d="M6.64896 18.0949C5.98058 19.3671 5.59999 20.8312 5.59999 22.3889V26.8222H1.39999V22.3889C1.39999 19.9404 3.2804 17.9556 5.59999 17.9556C5.96219 17.9556 6.31367 18.0039 6.64896 18.0949Z" fill="currentColor"/>
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        
                    </div>

                    <div class="mx-5">
                        <h4 class="text-2xl font-semibold text-gray-700">{{ system.ReturnDashboard().unpaid_1d_invoices }} лв.</h4>
                        <div class="text-gray-500">{{ languageService.ReturnTranslatedLabel('label_sales_for_day') }}</div>
                    </div>
                </div>
            </div>

            <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
                <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white" style="box-shadow:0px 0px 10px 1px rgba(0,0,0,0.1)">
                    <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
                        <!-- <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z" fill="currentColor"/>
                            <path d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z" fill="currentColor"/>
                            <path d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z" fill="currentColor"/>
                        </svg> -->

                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-white">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                        </svg>
                        
                    </div>

                    <div class="mx-5">
                        <h4 class="text-2xl font-semibold text-gray-700">{{ system.ReturnDashboard().unpaid_all_invoices }} лв.</h4>
                        <div class="text-gray-500">{{ languageService.ReturnTranslatedLabel('label_income') }}</div>
                    </div>
                </div>
            </div>

            <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
                <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-white" style="box-shadow:0px 0px 10px 1px rgba(0,0,0,0.1)">
                    <div class="p-3 rounded-full bg-pink-600 bg-opacity-75">
                        <svg class="h-8 w-8 text-white" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.99998 11.2H21L22.4 23.8H5.59998L6.99998 11.2Z" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linejoin="round"/>
                            <path d="M9.79999 8.4C9.79999 6.08041 11.6804 4.2 14 4.2C16.3196 4.2 18.2 6.08041 18.2 8.4V12.6C18.2 14.9197 16.3196 16.8 14 16.8C11.6804 16.8 9.79999 14.9197 9.79999 12.6V8.4Z" stroke="currentColor" stroke-width="2"/>
                        </svg>
                    </div>

                    <div class="mx-5">
                        <h4 class="text-2xl font-semibold text-gray-700">0 лв.</h4>
                        <div class="text-gray-500">{{ languageService.ReturnTranslatedLabel('label_expense') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-row mt-8">
        <!-- <div class="-my-2 py-2 w-2/4 mr-8">
            <span class="custom-title">{{ languageService.ReturnTranslatedLabel('label_last_incoming_invoices') }}</span>
            <div class="align-middle inline-block w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full divide-y divide-gray-200 ">
                <thead class="bg-white">
                    <tr class="text-sm font-bold text-black">
                        <th scope="col" class="py-3.5 px-4 text-left rtl:text-right">
                            <button class="flex items-center gap-x-3 focus:outline-none">
                                <span>{{ languageService.ReturnTranslatedLabel('label_document') }}</span>

                                <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                    <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                    <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                </svg>
                            </button>
                        </th>

                        <th scope="col" class="px-12 py-3.5 text-left rtl:text-right">
                            {{ languageService.ReturnTranslatedLabel('label_status') }}
                        </th>

                        <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                            {{ languageService.ReturnTranslatedLabel('label_information') }}
                        </th>

                        <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">{{ languageService.ReturnTranslatedLabel('label_payment_method') }}</th>

                        <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">{{ languageService.ReturnTranslatedLabel('label_price') }}</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200  ">
                    <tr *ngIf="system.ReturnDashboard().last_10_expenses.length == 0">
                        <td class="text-center p-4 bg-gray-100" colspan="6">{{ languageService.ReturnTranslatedLabel('label_no_invoices') }}</td>
                    </tr>
                    <tr *ngFor="let invoice of system.ReturnDashboard().last_10_expenses">
                        <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
                            <div>
                                <h2 class="font-medium text-gray-800  " *ngIf="invoice.subtype == 'invoice'">{{ languageService.ReturnTranslatedLabel('label_invoice') }}</h2>
                                <h2 class="font-medium text-gray-800  " *ngIf="invoice.subtype == 'proforma'">{{ languageService.ReturnTranslatedLabel('label_proforma') }}</h2>
                                <p class="text-sm font-normal text-gray-600 ">#{{ invoice.num }}</p>
                            </div>
                        </td>
                        <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
                            <div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 " *ngIf="invoice.status == '2'">
                                {{ languageService.ReturnTranslatedLabel('label_paid') }}
                            </div>
                            <div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-orange-500 gap-x-2 bg-orange-100 " *ngIf="invoice.status == '1'">
                                {{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}
                            </div>
                        </td>
                        <td class="px-4 py-2 text-sm whitespace-nowrap">
                            <div>
                                <h4 class="text-gray-700 ">{{ invoice.client_name }}</h4>
                                <p class="text-gray-500 ">{{ languageService.ReturnTranslatedLabel('label_created_at_date') }} {{ returnFormattedDate(invoice.date_added) }}</p>
                            </div>
                        </td>
                        <td class="px-4 py-2 text-sm whitespace-nowrap">
                            <div class="flex items-center" *ngIf="invoice.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
                            <div class="flex items-center" *ngIf="invoice.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
                        </td>

                        <td class="px-4 py-2 text-sm whitespace-nowrap">
                            <div class="flex item-center">
                                {{ invoice.total_value }} {{ invoice.currency }}
                            </div>
                        </td>
                    </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="-my-2 py-2 w-2/4">
            <span class="custom-title">{{ languageService.ReturnTranslatedLabel('label_last_outgoing_invoices') }}</span>
            <div class="align-middle inline-block w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
              <table class="min-w-full divide-y divide-gray-200 ">
                <thead class="bg-white">
                    <tr class="text-sm font-bold text-black">
                        <th scope="col" class="py-3.5 px-4 text-left rtl:text-right">
                            <button class="flex items-center gap-x-3 focus:outline-none">
                                <span>{{ languageService.ReturnTranslatedLabel('label_document') }}</span>

                                <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                    <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                    <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                </svg>
                            </button>
                        </th>

                        <th scope="col" class="px-12 py-3.5 text-left rtl:text-right">
                            {{ languageService.ReturnTranslatedLabel('label_status') }}
                        </th>

                        <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                            {{ languageService.ReturnTranslatedLabel('label_information') }}
                        </th>

                        <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">{{ languageService.ReturnTranslatedLabel('label_payment_method') }}</th>

                        <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">{{ languageService.ReturnTranslatedLabel('label_price') }}</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200  ">
                    <tr *ngFor="let invoice of system.ReturnDashboard().last_10_invoices">
                        <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
                            <div>
                                <h2 class="font-medium text-gray-800  " *ngIf="invoice.subtype == 'invoice'">{{ languageService.ReturnTranslatedLabel('label_invoice') }}</h2>
                                <h2 class="font-medium text-gray-800  " *ngIf="invoice.subtype == 'proforma'">{{ languageService.ReturnTranslatedLabel('label_proforma') }}</h2>
                                <p class="text-sm font-normal text-gray-600 ">#{{ invoice.num }}</p>
                            </div>
                        </td>
                        <td class="px-12 py-2 text-sm font-medium whitespace-nowrap">
                            <div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 " *ngIf="invoice.status == '2'">
                                {{ languageService.ReturnTranslatedLabel('label_paid') }}
                            </div>
                            <div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-orange-500 gap-x-2 bg-orange-100 " *ngIf="invoice.status == '1'">
                                {{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}
                            </div>
                            <div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-red-500 gap-x-2 bg-red-100/60 " *ngIf="invoice.status == '0'">
                                Анулирана
                            </div>
                        </td>
                        <td class="px-4 py-2 text-sm whitespace-nowrap">
                            <div>
                                <h4 class="text-gray-700 ">{{ invoice.client_name }}</h4>
                                <p class="text-gray-500 ">{{ languageService.ReturnTranslatedLabel('label_created_at_date') }} {{ returnFormattedDate(invoice.date_added) }}</p>
                            </div>
                        </td>
                        <td class="px-4 py-2 text-sm whitespace-nowrap">
                            <div class="flex items-center" *ngIf="invoice.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
                            <div class="flex items-center" *ngIf="invoice.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
                        </td>

                        <td class="px-4 py-2 text-sm whitespace-nowrap">
                            <div class="flex item-center">
                                {{ invoice.total_value }} {{ invoice.currency }}
                            </div>
                        </td>
                    </tr>
                </tbody>
              </table>
          </div>
        </div> -->
    </div>
  </div>
</section>