
<div class="flex min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="p-4 w-full">
	<div class="container mx-auto py-6 px-4 bg-white">
		<div class="flex justify-between">
			<h2 class="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">{{ languageService.ReturnTranslatedLabel('title_add_client') }}</h2>
			<div>
				<div class="relative mr-4 inline-block">
					<div routerLink="/clients" class="text-white cursor-pointer w-10 h-10 rounded-full bg-gray-400 hover:bg-gray-500 inline-flex items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
						  </svg>						  
					</div>
				</div>
				<div class="relative mr-4 inline-block">
					<div [ngClass]="{ 'disabled':!ReturnIsAllowedToAddClient() }" (click)="AddClient()" class="text-white cursor-pointer w-10 h-10 rounded-full bg-green-400 hover:bg-green-500 inline-flex items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<rect x="0" y="0" width="24" height="24" stroke="none"></rect>
							<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
						</svg>	  
					</div>
				</div>
			</div>
		</div>

        <span class="ml-4 font-semibold text-xl	mb-4 w-full flex">{{ languageService.ReturnTranslatedLabel('label_general_information') }}</span>
		<div class="flex mb-8 justify-between">
			<div class="w-1/4 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_client_type') }} <span class="text-red-500">*</span></label>
                    <select [(ngModel)]="client.type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full">
                        <option value="company">{{ languageService.ReturnTranslatedLabel('label_company') }}</option>
                        <option value="person">{{ languageService.ReturnTranslatedLabel('label_person') }}</option>
                    </select>
				</div>
			</div>
            <div class="w-1/4 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_client_group') }} <span class="text-red-500">*</span></label>
                    <select [(ngModel)]="client.client_group" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full">
                        <option *ngFor="let client_group of system.ReturnCompanyClientGroups()" value="{{ client_group.uid }}">{{ client_group.name }}</option>
                    </select>
				</div>
			</div>
            <div class="w-2/4 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_client_name') }} <span class="text-red-500">*</span></label>
					<input [(ngModel)]="client.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>
        </div>
        <div class="flex mb-8 justify-between">
            <div class="w-1/3 ml-4"  *ngIf="client.type == 'company'">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_client_owner') }}</label>
                    <input [(ngModel)]="client.mol" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" placeholder="Въведете МОЛ на фирмата" type="text">
				</div>
			</div>
            <div class="w-1/3 ml-4" *ngIf="client.type == 'company'">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_client_eik') }}</label>
                    <input [(ngModel)]="client.bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" placeholder="Въведете ЕИК/БУЛСТАТ на фирма" type="number">
				</div>
			</div>
            <div class="w-full ml-4" *ngIf="client.type == 'person'">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_client_social') }}</label>
                    <input [(ngModel)]="client.bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" placeholder="Въведете ЕГН на клиент" type="number">
				</div>
			</div>
            <div class="w-1/3 ml-4" [ngClass]="{ 'hidden':client.type == 'person' }">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_vat_number') }}</label>
                    <input [(ngModel)]="client.registered_vat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" placeholder="Ако фирмата е регистрирана по ДДС, въведете ДДС номер" type="text">
				</div>
			</div>
		</div>
        <span class="ml-4 font-semibold text-xl	mb-4 w-full flex">{{ languageService.ReturnTranslatedLabel('label_location') }}</span>
        <div class="flex mb-8 justify-between">
            <div class="w-2/3 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_address') }}</label>
                    <input [(ngModel)]="client.address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" placeholder="Въведете адрес на регистрация" type="text">
				</div>
			</div>
            <div class="w-1/3 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_city_town') }}</label>
                    <input [(ngModel)]="client.city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name"  placeholder="Въведете град на регистрация" type="text">
				</div>
			</div>
		</div>
        <div class="flex mb-8 justify-between">
            <div class="w-1/3 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_country') }}</label>
                    <input [(ngModel)]="client.country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name"  placeholder="Въведете държава на регистрация" type="text">
				</div>
			</div>
        </div>
        <span class="ml-4 font-semibold text-xl	mb-4 w-full flex">{{ languageService.ReturnTranslatedLabel('label_additional_information') }}</span>
        <div class="flex mb-8 justify-between">
            <div class="w-1/3 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_email') }}</label>
                    <input [(ngModel)]="client.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>
            <div class="w-1/3 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_phone') }}</label>
                    <input [(ngModel)]="client.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>
            <div class="w-1/3 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_website') }}</label>
                    <input [(ngModel)]="client.website" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>
		</div>
	</div>
</div>

</div>