import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CommonModule } from '@angular/common';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { ColorPickerModule } from 'ngx-color-picker';
import {NgPipesModule} from 'ngx-pipes';

import { ClickOutsideModule } from 'ng-click-outside';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import { OffersComponent } from './offers/offers.component';
import { AddOfferComponent } from './add-offer/add-offer.component';
import { OrdersComponent } from './orders/orders.component';
import { InvoiceAddonsComponent } from './invoice-addons/invoice-addons.component';
import { TasksComponent } from './tasks/tasks.component';
import { ProjectsComponent } from './projects/projects.component';
import { AddOrderComponent } from './add-order/add-order.component';
import { PrintInvoiceComponent } from './print-invoice/print-invoice.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AddInvoiceAddonComponent } from './add-invoice-addon/add-invoice-addon.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { ClientsComponent } from './clients/clients.component';
import { SettingsComponent } from './settings/settings.component';
import { ClientGroupsComponent } from './client-groups/client-groups.component';
import { AddClientComponent } from './add-client/add-client.component';
import { AddClientGroupComponent } from './add-client-group/add-client-group.component';
import { ServicesComponent } from './services/services.component';
import { AddServiceComponent } from './add-service/add-service.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { AddExpenseComponent } from './add-expense/add-expense.component';
import { EditOrderComponent } from './edit-order/edit-order.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ViewOfferComponent } from './view-offer/view-offer.component';
import { EditOfferComponent } from './edit-offer/edit-offer.component';
import { ProfileComponent } from './profile/profile.component';
import { PrintInvoiceExternalComponent } from './print-invoice-external/print-invoice-external.component';
import { GenerateExternalInvoiceComponent } from './generate-external-invoice/generate-external-invoice.component';
import { EditExternalInvoiceComponent } from './edit-external-invoice/edit-external-invoice.component';
import { AutomaticInvoicesComponent } from './automatic-invoices/automatic-invoices.component';
import { AddAutomaticInvoiceComponent } from './add-automatic-invoice/add-automatic-invoice.component';
import { EditAutomaticInvoiceComponent } from './edit-automatic-invoice/edit-automatic-invoice.component';
import { StorehousesComponent } from './storehouses/storehouses.component';
import { VirtualItemsComponent } from './virtual-items/virtual-items.component';
import { StorehouseCategoriesComponent } from './storehouse-categories/storehouse-categories.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InvoicesComponent,
    AddInvoiceComponent,
    OffersComponent,
    AddOfferComponent,
    OrdersComponent,
    InvoiceAddonsComponent,
    TasksComponent,
    ProjectsComponent,
    AddOrderComponent,
    PrintInvoiceComponent,
    AddInvoiceAddonComponent,
    ViewOrderComponent,
    ClientsComponent,
    SettingsComponent,
    ClientGroupsComponent,
    AddClientComponent,
    AddClientGroupComponent,
    ServicesComponent,
    AddServiceComponent,
    ExpensesComponent,
    EditInvoiceComponent,
    AddExpenseComponent,
    EditOrderComponent,
    ContactsComponent,
    ViewOfferComponent,
    EditOfferComponent,
    ProfileComponent,
    PrintInvoiceExternalComponent,
    GenerateExternalInvoiceComponent,
    EditExternalInvoiceComponent,
    AutomaticInvoicesComponent,
    AddAutomaticInvoiceComponent,
    EditAutomaticInvoiceComponent,
    StorehousesComponent,
    VirtualItemsComponent,
    StorehouseCategoriesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClickOutsideModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    CommonModule,
    ColorPickerModule,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    NgPipesModule,
    AngularEditorModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
