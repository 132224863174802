import { HttpClient } from '@angular/common/http';
import { Component, NgZone } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent {
  type:any = "all";
  services:any = [];
  page:any = 1;
  limit:any = "10";

  modal:any = { open:false, 'cntx':'', obj:{} }
  sideModal:any = { open:false, 'cntx':'', obj:{} }

  constructor(public languageService:LanguageServiceService, public ngZone:NgZone,public system:SystemService, public http:HttpClient){

  }
  
  switchType() { 
    this.system.GetServices(this.page, this.limit);
  }

  switchLimit() {
    this.system.GetServices(this.page, this.limit);
  }

  
  ngOnInit() {
    this.system.GetServices(this.page, this.limit);
  }

  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    if(cntx == 'add-service') {
      this.sideModal.obj.vat = this.system.ReturnVats()[0].vat;
      // alert(this.system.ReturnVats()[0].vat);
      this.ngZone.run(()=>{});
    }
    if(cntx == 'edit-service') {
      this.sideModal.obj['description'] = this.unescape(this.sideModal.obj['description']);
    }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }


  AddService() {
    this.sideModal.obj['description'] = this.escape(this.sideModal.obj['description']);
    this.http.post("https://api.nexa.bg/services", { 'service':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetServices(this.page, this.limit);
    });
  }

  EditService() {
    this.sideModal.obj['description'] = this.escape(this.sideModal.obj['description']);
    this.http.put("https://api.nexa.bg/services/" + this.sideModal.obj.uid, { 'service':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetServices(this.page, this.limit);
    });
  }


  RemoveService() {
    this.http.delete("https://api.nexa.bg/services/" + this.modal.obj.uid, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeModal();
      this.system.GetServices(this.page, this.limit);
    });
  }

  goToPage(page) { this.page = page; this.system.GetServices(this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetServices(this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetServices(this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetServices(this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalServicesPages();  this.system.GetServices(this.page, this.limit); }

  returnPriceWithCurrency(price) {
    let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.name == this.system.ReturnProfile()['currency'])[0];
    let currency_obj = { currency_name:"", currency_label:"", currency_position:"", currency_spacing:0 };
    currency_obj.currency_name = my_default_currency.name;
    currency_obj.currency_label = my_default_currency.label;
    currency_obj.currency_position = my_default_currency.position;
    currency_obj.currency_spacing = my_default_currency.spacing;
    let str = '';
    if(currency_obj.currency_position == 'front') {
      str += currency_obj.currency_label;
      if(currency_obj.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(currency_obj.currency_spacing == 1) { str += ' ' }
      str += currency_obj.currency_label;
    }
    return str;
  }
  
  escape(s) {
      return ('' + s)
          .replace(/\\/g, '\\\\')
          .replace(/\t/g, '\\t')
          .replace(/\n/g, '\\n')
          .replace(/\u00A0/g, '\\u00A0')
          .replace(/&/g, '\\x26')
          .replace(/'/g, '\\x27')
          .replace(/"/g, '\\x22')
          .replace(/</g, '\\x3C')
          .replace(/>/g, '\\x3E');
  }

  unescape(s) {
      s = ('' + s)
        .replaceAll(/x3E/g, '>')
        .replaceAll(/x3C/g, '<')
        .replaceAll(/x22/g, '"')
        .replaceAll(/x27/g, "'")
        .replaceAll(/x26/g, '&')
        .replaceAll(/u00A0/g, '\u00A0')
        .replaceAll(/\n/g, '\n')
        .replaceAll(/\t/g, '\t');

      return s.replaceAll(/\\\\/g, '\\');
  }
}
