<section class="min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="flex flex-col p-4">
        <div class="float-left w-full p-2">
            <div class="relative float-left font-bold text-black" style="font-size:24px;">{{ languageService.ReturnTranslatedLabel('menu_addons') }}</div>

            <div class="flex items-center float-right gap-x-3">

                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" routerLink="/add-invoice-addon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
    
                    <span>{{ languageService.ReturnTranslatedLabel('add_addon') }}</span>
                </button>
            </div>
        </div>
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="p-3 bg-white md:rounded-lg h-14 border border-gray-200">
                    <div class="float-left" style="width:240px;">
                        <input type="text" class="h-8 p-2 font-semibold text-xs text-black rounded-lg search-field" placeholder="Търсене..." />
                        <div class="btn float-right rounded-lg bg-positive text-white text-center search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>                              
                        </div>
                    </div>
                    <div class="float-left ml-2">
                        <select [(ngModel)]="type" (change)="switchType()" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                            <option value="all">Всички известия</option>
                            <option value="credit">Кредитно известие</option>
                            <option value="debit">Дебитно известие</option>
                        </select>
                    </div>
                    <span class="float-right">
                        <div class="float-left ml-2">
                            <span class="mr-2 text-xs font-bold text-gray-600">{{ languageService.ReturnTranslatedLabel('label_results_per_page') }}</span>
                            <select (change)="switchLimit()" [(ngModel)]="limit" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <span class="page-btn" (click)="StartPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentInvoicePages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>                                                      
                        </span>
                        <span class="page-btn" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentInvoicePages() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                              </svg>                              
                        </span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnCurrentInvoicePages() > 1" (click)="goToPage( system.ReturnCurrentInvoicePages() - 1)">{{ system.ReturnCurrentInvoicePages() - 1 }}</span>
                        <span class="page-btn bg-positive text-white hover:bg-positive hover:text-white" (click)="goToPage( system.ReturnCurrentInvoicePages())">{{ system.ReturnCurrentInvoicePages() }}</span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnTotalInvoicePages() > system.ReturnCurrentInvoicePages()" (click)="goToPage( system.ReturnCurrentInvoicePages() + 1)">{{ system.ReturnCurrentInvoicePages() + 1 }}</span>
                        <span class="page-btn" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentInvoicePages() == system.ReturnTotalInvoicePages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                        <span class="page-btn" (click)="LastPage()" [ngClass]="{ 'disabled':system.ReturnCurrentInvoicePages() == system.ReturnTotalInvoicePages() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                    </span>
                </div>
            </div>
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>Документ</span>

                                        <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                        </svg>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_connected_to') }}
                                </th>

                                <th scope="col" class="px-12 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_status') }}
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    {{ languageService.ReturnTranslatedLabel('label_information') }}
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">{{ languageService.ReturnTranslatedLabel('label_payment_method') }}</th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">Цена</th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr *ngIf="system.ReturnInvoiceAddons().length == 0">
                                <td class="text-center p-4 bg-gray-100" colspan="7">{{ languageService.ReturnTranslatedLabel('label_no_addons') }}</td>
                            </tr>
                            <tr *ngFor="let invoice of system.ReturnInvoiceAddons()">
                                <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                    <div>
                                        <h2 class="font-medium text-gray-800" *ngIf="invoice.subtype == 'debit'">{{ languageService.ReturnTranslatedLabel('label_debit_addon') }}</h2>
                                        <h2 class="font-medium text-gray-800" *ngIf="invoice.subtype == 'crdit'">{{ languageService.ReturnTranslatedLabel('label_credit_addon') }}</h2>
                                        <p class="text-sm font-normal text-gray-600">#{{ invoice.num }}</p>
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700">{{ languageService.ReturnTranslatedLabel('label_invoice') }}</h4>
                                        <p class="text-gray-500">{{ invoice.attached_invoice }}</p>
                                    </div>
                                </td>
                                <td class="px-12 py-4 text-sm font-medium whitespace-nowrap">
                                    <div class="inline px-3 py-1 text-sm font-normal rounded-full text-yellow-500 gap-x-2 bg-yellow-100/60" *ngIf="invoice.status == '1'">
                                        {{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700">{{ invoice.client_name }}</h4>
                                        <p class="text-gray-500">{{ languageService.ReturnTranslatedLabel('label_created_at_date') }} {{ invoice.date_added }}</p>
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="flex item-center">
                                        {{ invoice.price }} {{ invoice.currency }}
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap text-right">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2" routerLink="/view-offer" [queryParams]="{id: invoice.uid }">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                            <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                        </svg>                                          
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2" routerLink="/edit-offer/{{ invoice.uid }}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>                                          
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                          </svg>                                          
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z" clip-rule="evenodd" />
                                        </svg>                                        
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 mr-2" routerLink="/print-offer" [queryParams]="{id: invoice.uid }">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z" clip-rule="evenodd" />
                                          </svg>                                                                            
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>