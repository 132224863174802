<div class="side-modal" *ngIf="sideModal.open">
    <div class="backdrop"></div>
    <div class="modal" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'add-client' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
            </svg>
              
            <span class="title float-left ml-2 font-bold">{{ languageService.ReturnTranslatedLabel('add_client') }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full" style="overflow-y: auto; overflow-x: hidden; height: calc(100% - 50px);">
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >{{ languageService.ReturnTranslatedLabel('label_client_name') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_type') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <select [(ngModel)]="sideModal.obj.type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8">
                        <option value="company">{{ languageService.ReturnTranslatedLabel('label_company') }}</option>
                        <option value="person">{{ languageService.ReturnTranslatedLabel('label_person') }}</option>
                    </select> 
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_group') }} <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <select [(ngModel)]="sideModal.obj.client_group" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched  w-full mr-8">
                        <option *ngFor="let client_group of system.ReturnCompanyClientGroups()" value="{{ client_group.uid }}">{{ client_group.name }}</option>
                    </select>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4"  *ngIf="sideModal.obj.type == 'company'">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_owner') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.mol" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете МОЛ на фирмата" type="text">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.type == 'person'">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_social') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете ЕГН на клиент" type="number">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-row ml-4" *ngIf="sideModal.obj.type == 'company'">
                <div class="w-2/4 flex flex-col" *ngIf="sideModal.obj.type == 'company'">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_client_eik') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.bulstat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете ЕИК/БУЛСТАТ на фирма" type="number">
                    </div>
                </div>

                <div class="w-2/4 flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company'">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_vat_number') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.registered_vat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Ако фирмата е регистрирана по ДДС, въведете ДДС номер" type="text">
                    </div>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company'">
                <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_address') }}</label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.address" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name"  placeholder="Въведете адрес на регистрация" type="text">
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4" *ngIf="sideModal.obj.type == 'company'">
                <div class="w-2/4 flex flex-col" *ngIf="sideModal.obj.type == 'company'">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_city_town') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.city" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете град на регистрация" type="text">
                    </div>
                </div>
    
                <div class="w-2/4 flex flex-col ml-4" *ngIf="sideModal.obj.type == 'company'">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_country') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.country" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете държава на регистрация" type="text">
                    </div>
                </div>
            </div>

            <div class="mb-2 mt-2 w-full flex flex-row ml-4">
                <div class="w-2/4 flex flex-col">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_email') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете е-мейл" type="text">
                    </div>
                </div>
    
                <div class="w-2/4 flex flex-col ml-4">
                    <label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide w-full mr-2">{{ languageService.ReturnTranslatedLabel('label_phone') }}</label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.phone" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" placeholder="Въведете телефон" type="text">
                    </div>
                </div>
            </div>

            <button [ngClass]="{ 'disabled':!ReturnIsAllowedToAddClient() }" class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" (click)="AddClient()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>{{ languageService.ReturnTranslatedLabel('add_client') }}</span>
            </button>
        </div>
    </div>
</div>

<div class="flex min-h-screen custom-min-h-screen bg-nexa bg-gray-200 text-gray-800">
    <div class="p-4 w-full">
	<div class="container mx-auto py-6 flex flex-col bg-white p-4 rounded-md">
		<div id="title-invoice" class="py-4">   
			<p class="text-xl font-bold mt-3 mb-5 float-left">{{ languageService.ReturnTranslatedLabel('create_invoice') }}</p>
			<button (click)="EditInvoice()" type="button" id="btn-invoice" class="mt-2 text-xs float-right py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0">  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
				<path d="M12 15a3 3 0 100 -6 3 3 0 000 6z" />
				<path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
			</svg>
			   {{ languageService.ReturnTranslatedLabel('action_save_and_preview') }}</button>
			<button (click)="goBack()" type="button" id="btn-invoice" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-gray-700 hover:text-white focus:outline-none focus:ring-0 mr-2">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="ltr:mr-2 rtl:ml-2 inline-block bi bi-printer mr-2" stroke="currentColor" height="16" width="16">
					<path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
				  </svg>			  
				{{ languageService.ReturnTranslatedLabel('action_back') }}</button>
		</div>
		<div class="flex justify-between">
			<div class="flex flex-col bg-white p-4 " style="width:35%">
				<span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_client') }}</span>
				<span class="text-xs text-gray-600">{{ languageService.ReturnTranslatedLabel('label_enter_name_or_social_hint') }}</span>
				<ng-select  class="mb-1 bg-gray-100 border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" 
							(change)="selectedClient()"
							[items]="available_clients" 
							bindLabel="name" 
							bindValue="uid" 
							(clear)="invoice.client_id = -1"
							[(ngModel)]="invoice.client_id">
				</ng-select>
				<div class="w-full flex flex-row" *ngIf="invoice.client_id != -1">
					<span class="text-sm text-gray-600 font-bold">{{ languageService.ReturnTranslatedLabel('label_client_type')}}:</span><span class="text-sm text-gray-700 ml-2" *ngIf="invoice.client_data.type == 'company'">{{ languageService.ReturnTranslatedLabel('label_company_client') }}</span><span class="text-sm text-gray-700 ml-2" *ngIf="invoice.client_data.type == 'person'">{{ languageService.ReturnTranslatedLabel('label_single_client') }}</span>
				</div>
				<div class="w-full flex flex-row" *ngIf="invoice.client_id != -1">
					<span class="text-sm text-gray-600 font-bold">{{ languageService.ReturnTranslatedLabel('label_client_owner') }}:</span><span class="text-sm text-gray-700 ml-2">{{invoice.client_data.mol}}</span>
				</div>
				<div class="w-full flex flex-row" *ngIf="invoice.client_id != -1">
					<span class="text-sm text-gray-600 font-bold">{{ languageService.ReturnTranslatedLabel('label_address_registration') }}:</span><span class="text-sm text-gray-700 ml-2">{{invoice.client_data.address}}</span>
				</div>
				<div class="w-full flex flex-row" *ngIf="invoice.client_id != -1">
					<span class="text-sm text-gray-600 font-bold">{{ languageService.ReturnTranslatedLabel('label_bulstat') }}:</span><span class="text-sm text-gray-700 ml-2" *ngIf="invoice.client_vat == ''">{{invoice.client_data.bulstat}}</span><span class="text-sm text-gray-700 ml-2" *ngIf="invoice.client_data.registered_vat != ''">{{ invoice.client_data.registered_vat }}</span>
				</div>

				<button (click)="openSideModal('add-client', { name:'', mol:'', type:'company', bulstat:'', address:'', city:'',country:'', website:'', email:'', phone:'',registered_vat:'', client_group:'0' })" *ngIf="invoice.client_id == '-1'" type="button" id="btn-invoice" class="mt-2 text-xs float-right py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0 w-auto text-sm mx-auto"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 float-left mr-2">
					<path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
				  </svg>
				  
				  {{ languageService.ReturnTranslatedLabel('label_new_client') }}</button>
			</div>
			<div class="flex flex-col bg-white p-4 ml-2 " style="width:40%">
				<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-4">{{ languageService.ReturnTranslatedLabel('label_document_number') }}</label>
				<div class="flex w-full mb-4">
					<input [(ngModel)]="invoice.invoice_num" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
				<div class="flex flex-row w-full">
					<div class="flex flex-col w-1/3">
						<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" style="font-size:0.6rem">{{ languageService.ReturnTranslatedLabel('label_date_creation') }}</label>
						<input [(ngModel)]="invoice.date_added" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" readonly type="text" placeholder="eg. #INV-100001" x-model="invoiceNumber">
					</div>
					<div class="flex flex-col w-1/3 ml-2">
						<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" style="font-size:0.6rem">{{ languageService.ReturnTranslatedLabel('label_date_of_payment') }}</label>
						<input [(ngModel)]="invoice.date_added" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" readonly type="text" placeholder="eg. #INV-100001" x-model="invoiceNumber">
					</div>
					<div class="flex flex-col w-1/3 ml-2">
						<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" style="font-size:0.6rem">{{ languageService.ReturnTranslatedLabel('label_date_due') }}</label>
						<input readonly id="date_paid" [(ngModel)]="invoice.date_due" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="text" placeholder="eg. #INV-100001" value="{{ invoice.date_due }}" #date_paid>
					</div>
				</div>
			</div>
			<div class="flex flex-col bg-white p-4 ml-2 " style="width:25%">
				<div class="mb-2 md:mb-1 md:flex flex-col">
					<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-4">{{ languageService.ReturnTranslatedLabel('label_invoice_type') }}</label>
					<div class="flex">
                        <select [(ngModel)]="invoice.type" (change)="changedInvoiceType()" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full">
                            <option value="invoice">{{ languageService.ReturnTranslatedLabel('label_invoice') }}</option>
                            <option value="proforma">{{ languageService.ReturnTranslatedLabel('label_proforma') }}</option>
                        </select> 
					</div>
				</div>

				<div class="mb-2 md:mb-1 md:flex flex-col mt-2">
					<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">{{ languageService.ReturnTranslatedLabel('label_currency') }}</label>
					<div class="flex-1">
                        <select [(ngModel)]="invoice.currency_name" (change)="changedCurrency()" id="currencies" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full">
							<option *ngFor="let currency of system.ReturnAvailableCurrencies()" value="{{ currency.name }}">{{ currency.label }} ({{ currency.name }})</option>
                        </select>
					</div>
				</div>
			</div>
		</div>
		<div class="flex -mx-1 border-b items-center py-1" style="background:black;border-top-left-radius: 8px; border-top-right-radius:8px;">
			<div class="w-20 px-1">
				<p class="text-white uppercase tracking-wide text-xs font-bold">#</p>
			</div>

			<div class="flex-1">
				<p class="text-white uppercase tracking-wide text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_description') }}</p>
			</div>

            <div class="px-1 w-20 text-left">
				<p class="text-white uppercase tracking-wide text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_quantity_short') }}</p>
			</div>


			<div class="px-1 w-20 text-left">
				<p class="text-white uppercase tracking-wide text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_metric') }}</p>
			</div>

            <div class="px-1 w-20 text-left">
				<p class="text-white uppercase tracking-wide text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_vat') }} (%)</p>
			</div>


			<div class="px-1 w-32 text-left">
				<p class="leading-none">
					<span class="block uppercase tracking-wide text-xs font-bold text-white">{{ languageService.ReturnTranslatedLabel('label_single_price') }}</span>
					<span class="font-medium text-xs text-gray-500">{{ languageService.ReturnTranslatedLabel('label_without_vat_hint') }}</span>
				</p>
			</div>

			<div class="px-1 w-32 text-right">
				<p class="leading-none">
					<span class="block uppercase tracking-wide text-xs font-bold text-white">{{ languageService.ReturnTranslatedLabel('label_total') }}</span>
					<span class="font-medium text-xs text-gray-500">{{ languageService.ReturnTranslatedLabel('label_with_vat_hint') }}</span>
				</p>
			</div>

			<div class="px-1 w-32 text-center">
			</div>
		</div>
	    <div  :key="invoice.id">
			<div class="flex -mx-1 py-2 border-b relative" *ngFor="let item of invoice.items;let idx = index">
				<div class="items-dropdown" *ngIf="item.selecting && results.length > 0">
					<div *ngFor="let result of results" class="flex" (click)="selectResult(item, result)">
						<div class="w-20">
						</div>
						<div class="flex-1 relative">
							{{ result.name }} - {{ result.description }}
						</div>
		
						<div class="px-1 w-20 text-left">
							1
						</div>
		
						<div class="px-1 w-20 text-left">
							{{ result.metric }}
						</div>
		
						<div class="px-1 w-20 text-left">
							{{ result.vat }}
						</div>
		
						<div class="px-1 w-32 text-left">
							{{ returnPriceWithCurrency(result.price) }}
						</div>
		
						<div class="px-1 w-32 text-right">
							{{ returnPriceWithCurrency(returnFixed((result.price * 1) * (1 + (result.vat / 100)))) }}
						</div>
		
						<div class="px-1 w-32 text-right cursor-pointer">
						</div>
					</div>
				</div>
				<div class="w-20 px-1">
					<p class="text-gray-800 uppercase tracking-wide text-sm font-bold" style="line-height:36px;">{{ idx + 1 }}</p>
				</div>

				<div class="flex-1 relative">
					<input (keyup)="changedRow(idx, item)" (blur)="clearSelect(item)" (focus)="focusSelect(item)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="text" [(ngModel)]="item.name" placeholder="Въведете описание на продукта" />
				</div>

				<div class="px-1 w-20 text-right">
					<input (keyup)="changedRow(idx, item)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="number" [(ngModel)]="item.quantity" placeholder="К-во" />
				</div>

                <div class="px-1 w-20 text-right">
                    <select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="number" [(ngModel)]="item.unit">
						<option *ngFor="let unit of system.ReturnAvailableUnits()">{{ unit.name }}</option>
					</select>
                </div>

                <div class="px-1 w-20 text-right">
					<select class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" (change)="changedRow(idx, item)" [(ngModel)]="item.vat">
						<option *ngFor="let vat of system.ReturnVats()" value="{{ vat.vat }}">{{ vat.vat }}%</option>
					</select>
                    <!-- <input (keyup)="changedRow(idx, item)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="number" [(ngModel)]="item.vat" placeholder="ДДС %" /> -->
                </div>

                <div class="px-1 w-32 text-right">
					<input (keyup)="changedRow(idx, item)" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" type="number" [(ngModel)]="item.price" placeholder="Ед.цена" />
				</div>

				<div class="px-1 w-32 text-right">
					<p class="text-gray-800" x-text="numberFormat(invoice.total)" style="line-height:36px;">{{ returnPriceWithCurrency(returnFixed((item.price * item.quantity) * (1 + (item.vat / 100)))) }}</p>
				</div>

				<div class="px-1 w-32 text-right cursor-pointer">
					<span *ngIf="invoice.items.length > 1"  style="line-height:36px;" href="#" class="text-red-500 hover:text-red-600 text-sm font-semibold cursor-pointer" (click)="removeItemFromInvoice(idx)">{{ languageService.ReturnTranslatedLabel('item_action_remove') }}</span>
				</div>
			</div>
		</div>

		<div class="flex flex-row justify-between bg-white mt-4 ">
			<div class="flex-col flex w-3/4">
				<div class="flex items-center p-4">
					<input checked id="checked-checkbox" type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
					<label for="checked-checkbox" class="ml-2 text-sm font-medium text-gray-900 text-xs">Закон за счетоводството, чл.78, ал.8 от
						Правилника за прилагане на ЗДДС и Писмо No 91-00-46 от 25.03.2002 г. на ГДД.</label>
				</div>
			</div>
			<div class="flex-col flex w-1/4">
				<span class="text-right p-2 text-xs font-semibold">{{ languageService.ReturnTranslatedLabel('label_subtotal') }}: {{returnPriceWithCurrency(returnStandardTotal())}}</span>
				<span class="text-right p-2 text-xs font-semibold">{{ languageService.ReturnTranslatedLabel('label_vat') }}: {{returnPriceWithCurrency(returnVatTotal())}}</span>
				<span class="text-right p-2 text-sm font-semibold">{{ languageService.ReturnTranslatedLabel('label_total_with_vat') }}: {{returnPriceWithCurrency(returnTotal())}}</span>
			</div>
		</div>
		<div class="flex flex-row justify-between">
			<div class="flex-col flex w-2/4 bg-white mt-4  p-4">
				<div class="mb-2 md:mb-1 md:flex flex-col mt-2">
					<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2">{{ languageService.ReturnTranslatedLabel('label_payment_method') }}</label>
					<div class="flex-1">
                        <select [(ngModel)]="invoice.payment_type" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full">
                            <option value="1">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</option>
                            <option value="2">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</option>
                            <option value="3">{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</option>
                        </select>
                    </div>
				</div>
				<div class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 mt-4 " role="alert" *ngIf="invoice.payment_type == 1 && system.ReturnBanks().length == 0">
					<svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
					<span class="sr-only">Info</span>
					<div>
					  <span class="font-medium">Забележка:</span> Не може да добавите фактура с банков превод, без да имате въведена банкова сметка
					</div>
				  </div>
				<div class="w-full flex flex-col mt-2" *ngIf="invoice.payment_type == 1 && system.ReturnBanks().length > 0">
					<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 w-full">{{ languageService.ReturnTranslatedLabel('label_enter_name_or_social_hint') }}</label>
					<div class="w-full">
						<select [(ngModel)]="invoice.bank_id" class="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full">
							<option value="-1" disabled>{{ languageService.ReturnTranslatedLabel('label_select_bank_account_hint') }}</option>
							<option *ngFor="let bank of system.ReturnBanks()" value="{{ bank.uid }}">{{ bank.name }} ({{ bank.iban }})</option>
						</select>
					</div>
				</div>
			</div>
			<div class="flex-col flex w-2/4 bg-white mt-4  p-4 ml-2">
				<div class="flex flex-col w-full">
					<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mt-2">{{ languageService.ReturnTranslatedLabel('label_created_by') }}</label>
					<input value="Георги Петков" [(ngModel)]="invoice.added_by" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text" x-model="invoiceNumber">
				</div>
				<div class="flex flex-col w-full">
					<label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mt-4">{{ languageService.ReturnTranslatedLabel('label_recepient') }}</label>
					<input value="Пламен Петров"  [(ngModel)]="invoice.going_to"  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text" x-model="invoiceNumber">
				</div>
			</div>
		</div>
	</div>
</div>