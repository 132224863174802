import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component,ElementRef,NgZone,OnInit, ViewChild  } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { DomSanitizer } from '@angular/platform-browser';

import * as html2pdf from 'html2pdf.js';
import { Location } from '@angular/common';
@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.css']
})
export class PrintInvoiceComponent implements OnInit {
  invoice:any = { num:3000000001, type:'invoice', bank_details:{}, payment_type:'2', date_added:'00.00.0000', items:[], client_id:-1, client_name:"", client_mol:"", client_address:""  }
  client:any = {};
  top_text = "Оригинал";
  print_template:any = "";
  client_loaded:any = false;
  invoice_loaded:any = false;
  bank_loaded:any = false;
  printing:any = false;
  pdfSrc:any;
  id:any;
  pdf:any;
  pdfResponse:any;
  showDone:any = false;
  
  modal:any = { open:false, 'cntx':'', obj:{} }
  @ViewChild('pdfTable') pdfTable!: ElementRef;

  constructor(public languageService:LanguageServiceService, public router:Router, private route: ActivatedRoute, private http:HttpClient, public system:SystemService, public sanitizer:DomSanitizer, public location:Location, public ngZone:NgZone) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.id = id;
      this.http.get("https://api.nexa.bg/invoices/" + id, {
        withCredentials: true
      })
      .subscribe(response => {
        this.invoice = response;
        let items = [];
        let products_split = this.invoice.products.split(";");
        for(var pi = 0; pi < products_split.length - 1; pi++) {
          let product_split = products_split[pi].split("|");
          items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2], "quantity":product_split[3], "vat":parseInt(product_split[4]) })
        }
        this.invoice.items = items;
        if(this.invoice.bank_id != -1) {
          this.invoice.bank_details = this.system.ReturnBanks().filter(bank => bank.uid == this.invoice.bank_id)[0];
          this.bank_loaded = true;
        }
        this.invoice_loaded = true;
        this.http.get("https://api.nexa.bg/clients/" + this.invoice.client_id, {
          withCredentials: true
        })
        .subscribe(response_client => {
          this.client = response_client;
          console.log(this.client);
          this.client_loaded = true;
          this.PrintDocument(params['print']);
        });

        this.invoice.currency = response['currency'];
        let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.label == this.invoice.currency)[0];
        this.invoice.currency_name = my_default_currency.name;
        this.invoice.currency_label = my_default_currency.label;
        this.invoice.currency_position = my_default_currency.position;
        this.invoice.currency_spacing = my_default_currency.spacing;

      },(error) => {                              //error() callback
        console.log(error)
     });
    });
  }

  ReloadInvoice() {
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.id = id;
      this.http.get("https://api.nexa.bg/invoices/" + id, {
        withCredentials: true
      })
      .subscribe(response => {
        this.invoice = response;
        let items = [];
        let products_split = this.invoice.products.split(";");
        for(var pi = 0; pi < products_split.length - 1; pi++) {
          let product_split = products_split[pi].split("|");
          items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2], "quantity":product_split[3], "vat":parseInt(product_split[4]) })
        }
        this.invoice.items = items;
        if(this.invoice.bank_id != -1) {
          this.invoice.bank_details = this.system.ReturnBanks().filter(bank => bank.uid == this.invoice.bank_id)[0];
          this.bank_loaded = true;
        }
        this.invoice_loaded = true;
        this.http.get("https://api.nexa.bg/clients/" + this.invoice.client_id, {
          withCredentials: true
        })
        .subscribe(response_client => {
          this.client = response_client;
          console.log(this.client);
          this.client_loaded = true;
          this.PrintDocument(params['print']);
        });

        this.invoice.currency = response['currency'];
        let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.label == this.invoice.currency)[0];
        this.invoice.currency_name = my_default_currency.name;
        this.invoice.currency_label = my_default_currency.label;
        this.invoice.currency_position = my_default_currency.position;
        this.invoice.currency_spacing = my_default_currency.spacing;

      },(error) => {                              //error() callback
        console.log(error)
     });
    });
  }

  PrintDocument(print) {
    let template = "https://crm.nexa.bg/templates/invoices/"+ this.system.ReturnProfile().template +".html";
    if(this.languageService.ReturnCurrentLang() == 'en') { template = "https://crm.nexa.bg/templates/invoices/"+ this.system.ReturnProfile().template +"-en.html"; }
    this.http.get(template, {responseType: 'text'})
    .subscribe(response => {

      this.getBase64ImageFromURL('https://crm.nexa.bg/logo/'+ this.system.ReturnProfile().logo).then((data)=>{
        response = response.replace("{{invoice.num}}", this.invoice.num);
        response = response.replace("{{invoice.logo}}", "" + data);
        response = response.replace("{{invoice.copy}}", this.languageService.ReturnTranslatedLabel('label_original'));
        response = response.replace("{{invoice.total_without_vat}}", this.returnPriceWithCurrency(this.returnStandardTotal()));
        response = response.replace("{{invoice.total_vat}}", this.returnPriceWithCurrency(this.returnVatTotal()));
        response = response.replace("{{invoice.total}}", this.returnPriceWithCurrency(this.returnTotal()));
        response = response.replace("{{invoice.type_payment}}", this.returnPaymentTypeString());
        response = response.replace("{{invoice.date_added}}", this.returnFormattedDate(this.invoice.date_added));
        response = response.replace("{{invoice.date_added}}", this.returnFormattedDate(this.invoice.date_added));
        response = response.replace("{{invoice.date_due}}", this.returnFormattedDate(this.invoice.date_due));
        response = response.replace("{{invoice.created_by}}",  this.system.ReturnMe().first_name + " " + this.system.ReturnMe().last_name);
        response = response.replace("{{cipher}}", this.system.ReturnMe().code);
        response = response.replace("{{invoice.type}}", this.ReturnInvoiceType());
        response = response.replace("{{invoice.class}}", this.invoice.subtype);
        if(this.invoice.status == 0) {
          response = response.replace("{{ annulled }}", '<div class="annulled">АНУЛИРАНА</div>');
        } else {
          response = response.replace("{{ annulled }}", '');
        }
        let bank_details = "";
        if(this.invoice.type_payment == '1') {
          bank_details += "<span>" + this.languageService.ReturnTranslatedLabel('label_bank_name') + " " + this.invoice.bank_details.bank +"</span>";
          bank_details += "<span>BIC: "+ this.invoice.bank_details.bic +"</span>";
          bank_details += "<span>IBAN: "+ this.invoice.bank_details.iban +"</span>";
        }
        response = response.replace("{{payment_details}}", bank_details);
  
        response = response.replaceAll("{{company.name}}", this.system.ReturnProfile().company_name);
        response = response.replace("{{company.eik}}", this.system.ReturnProfile().company_gov_num);
        response = response.replace("{{company.bulstat}}", this.system.ReturnProfile().company_vat);
        response = response.replace("{{company.address}}", this.system.ReturnProfile().company_address);
        response = response.replace("{{company.city}}", this.system.ReturnProfile().company_city);
        response = response.replaceAll("{{company.owner}}", this.system.ReturnProfile().company_owner);
        response = response.replaceAll("{{primary_color}}", this.system.ReturnProfile().primary_color);
        response = response.replaceAll("{{accent_color}}", this.system.ReturnProfile().accent_color);
        //#ffc808
        //#363a44
        response = response.replaceAll("{{client.name}}", this.client.name);
        response = response.replace("{{client.eik}}", this.client.bulstat);
        response = response.replace("{{client.bulstat}}", this.client.registered_vat);
        response = response.replace("{{client.address}}", this.client.address);
        response = response.replace("{{client.city}}", this.client.city);
        response = response.replaceAll("{{client.owner}}", this.client.mol);
  
        let rows = "";
        for(let i = 0; i < this.invoice.items.length; i++) {
          if(this.invoice.items[i].price > 0 || this.invoice.items[i].name != '') {
            rows += "<tr>";
            rows += "<td>" + this.invoice.items[i].name + "</td>";
            rows += "<td>" + this.returnPriceWithCurrency(this.invoice.items[i].price) + "</td>";
            rows += "<td>" + this.invoice.items[i].quantity + "</td>";
            rows += "<td>" + this.invoice.items[i].unit + "</td>";
            // rows += "<td>0%</td>";
            rows += "<td>" + this.invoice.items[i].vat + "</td>";
            //(1 + (this.invoice.items[i].vat / 100)
            let total = this.returnFixed((this.invoice.items[i].price * this.invoice.items[i].quantity)) + "" + this.invoice.currency;
            rows += "<td>" + total + "</td>"
            rows += "</tr>";
          }
        }
        response = response.replace("{{rows}}", rows);
        console.log(response);
        this.pdfResponse = response;
        this.printing = true;
        let safe_html = this.sanitizer.bypassSecurityTrustHtml(response);
        this.print_template = safe_html;
        // const elem = document.getElementById("print-window").innerHTML;
        var opt = {
          margin: 0,
          filename: 'output.pdf',
          image: { type:'jpeg', quality:0.95 },
          html2canvas: { scale:4, dpi: 192, letterRendering: true},
          // html2canvas: { scale:4, dpi:192},
          jsPDF: { unit:'mm', format:'a4', orientation: 'portrait' },
          // pagebreak: { mode: 'avoid-all', before: '#pdfTable' }
        }  
        html2pdf().from(response).set(opt).toPdf().output('datauristring').then( (data: Blob) => {
          console.log(data);
          this.pdfSrc = data
          this.printing = true;
          this.ngZone.run(()=>{});
          setTimeout(()=>{
            if(print) {
              this.PrintPDF();
              setTimeout(()=>{
                this.goBack();
              }, 250);
            }
          }, 1000);

        })
      
      });
      // const pdfTable = this.pdfTable.nativeElement;
      // const pdfTable = document.getElementById('pdfTable');
      // setTimeout(()=>{
      //   window.print();
      //   response = response.replace("ОРИГИНАЛ", "КОПИЕ");
      //   let safe_html = this.sanitizer.bypassSecurityTrustHtml(response);
      //   this.print_template = safe_html;
      //   setTimeout(()=>{
      //     window.print();
      //   }, 250)
      // }, 250)
    });
    // window.print();
    // this.top_text = 'Копие';
    // setTimeout(()=>{
    //   window.print();
    //   this.top_text = 'Оригинал';
    // }, 250);

  }



  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

  goBack() {
    this.location.back()
  }

  
  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(cntx == 'send-invoice') {
      if(obj.contacts.length > 0) {
        this.modal.obj.selected_contact = obj.contacts[0].uid;
      }
    }
    if(cntx == 'create-external-invoice') {
      if(this.system.ReturnProfile().company_name_en != '' && this.system.ReturnProfile().company_address_en != '' && this.system.ReturnProfile().company_owner_en != '') {
        this.http.get("https://api.vatcomply.com/rates")
        .subscribe(response_currencies => {
          this.modal.obj.rates = response_currencies['rates'];
          if(this.modal.obj.currency == "EUR") {
            this.modal.obj.current_rate = this.modal.obj.rates['BGN'].toFixed(4);
          } else {
            this.modal.obj.current_rate = (1.9558 / this.modal.obj.rates[this.modal.obj.currency]).toFixed(4);
          }
        });
      } else {
        this.openModal('missing-external-invoice-settings', {})
      }

    }
  }
  closeModal() { this.modal.open = false; }

  SwitchRate() {
    if(this.modal.obj.currency == "EUR") {
      this.modal.obj.current_rate = this.modal.obj.rates['BGN'].toFixed(4);
    } else {
      this.modal.obj.current_rate = (1.9558 / this.modal.obj.rates[this.modal.obj.currency]).toFixed(4);
    }
  }

  GenerateInvoice() {
    this.router.navigateByUrl(`/generate-external-invoice/${this.id}/${this.modal.obj.currency}/${this.modal.obj.current_rate}`);
    // this.http.put("https://api.nexa.bg/invoices/" + this.id, { 'invoice':{ external_invoice:1, external_rate:this.modal.obj.current_rate, external_currency:this.modal.obj.currency }}, {
    //   withCredentials: true
    // })
    // .subscribe(response => {
    //   this.closeModal();
    //   this.ReloadInvoice();
    // });
  }

  ViewInvoice() {
    if(this.system.ReturnProfile().company_name_en != '' && this.system.ReturnProfile().company_address_en != '' && this.system.ReturnProfile().company_owner_en != '') {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'id': this.invoice.uid },
      };
      
      // Navigate to the login page with extras
      this.router.navigate(['/print-invoice-external'], navigationExtras);
    } else {
      this.openModal('missing-external-invoice-settings', {});
    }
  }

  DownloadPDF() {
    var opt = {
      margin: 0,
      filename: this.invoice['client_name'] + "-" + this.invoice['num'] + "-" + this.invoice['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf',
      image: { type:'jpeg', quality:1 },
      html2canvas: { scale:4, dpi:192 },
      jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
    }  
    html2pdf().from(this.pdfResponse).set(opt).toPdf().save();
  }

  SendDocument() {
    var opt = {
      margin: 0,
      filename: this.invoice['client_name'] + "-" + this.invoice['num'] + "-" + this.invoice['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf',
      image: { type:'jpeg', quality:1 },
      html2canvas: { scale:4, dpi:192 },
      jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
    }  

    let sendInvoiceTemplate = this.system.ReturnTemplates()['invoices'];
    if(this.modal.obj.edit_template) { sendInvoiceTemplate = this.modal.obj.template; }


    html2pdf().from(this.pdfResponse).set(opt).output('datauristring').then( (data: Blob) => {
      this.http.post("https://api.nexa.bg/send-mail", { 'recipients':[this.modal.obj.contacts.filter(contact => contact.uid == this.modal.obj.selected_contact)[0]['mail']], 'subject':this.system.ReturnTemplates().title_invoices, "text":"", "html":sendInvoiceTemplate, "attachment":{ 'filename':this.invoice['client_name'] + "-" + this.invoice['num'] + "-" + this.invoice['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf', "path":data  } }, {
        withCredentials: true
      })
      .subscribe(response_mail => {
        this.showDone = true;
      });
    })
    if(this.modal.obj.copy) {
      html2pdf().from(this.pdfResponse).set(opt).output('datauristring').then( (data: Blob) => {
        this.http.post("https://api.nexa.bg/send-mail", { 'recipients':[this.system.ReturnMe().username], 'subject':this.system.ReturnTemplates().title_invoices, "text":"", "html":sendInvoiceTemplate, "attachment":{ 'filename':this.invoice['client_name'] + "-" + this.invoice['num'] + "-" + this.invoice['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf', "path":data  } }, {
          withCredentials: true
        })
        .subscribe(response_mail => {
          this.showDone = true;
        });
      })
    }
    setTimeout(()=>{
      this.closeModal();
      this.showDone = true;
      setTimeout(()=>{
        this.showDone = false;
      }, 2000);
    }, 700);
  }

  PrintPDF() {
    document.getElementsByTagName('pdf-print')[0].getElementsByTagName('button')[0].click();
    // var opt = {
    //   margin: 0,
    //   filename: 'output.pdf',
    //   image: { type:'jpeg', quality:0.98 },
    //   html2canvas: { scale:1 },
    //   jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
    // }  
    // html2pdf().from(this.pdfResponse).set(opt).print();
  }

  ReturnShadowCopy(obj) { return JSON.parse(JSON.stringify(obj)); }

  returnFixed(num) { return num.toFixed(2); }
  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.invoice.items.length; i++) {
      total += this.invoice.items[i].price * this.invoice.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.invoice.items.length; i++) {
      total += (this.invoice.items[i].price * this.invoice.items[i].quantity) * ( this.invoice.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal())).toFixed(2);
  }

  returnPriceWithCurrency(price) {
    let str = '';
    if(this.invoice.currency_position == 'front') {
      str += this.invoice.currency_label;
      if(this.invoice.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(this.invoice.currency_spacing == 1) { str += ' ' }
      str += this.invoice.currency_label;
    }
    return str;
  }

  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }

  returnPaymentTypeString() {
    if(this.invoice.type_payment == '1') { return this.languageService.ReturnTranslatedLabel('label_payment_bank'); }
    else if(this.invoice.type_payment == '2') { return this.languageService.ReturnTranslatedLabel('label_payment_post'); }
    else if(this.invoice.type_payment == '3') { return this.languageService.ReturnTranslatedLabel('label_payment_cash'); }
  }

  ReturnInvoiceType() {
    if(this.invoice.subtype == 'invoice') { return this.languageService.ReturnTranslatedLabel('label_invoice'); }
    else { return this.languageService.ReturnTranslatedLabel('label_proforma'); }
  }
  returnDateForSend(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "" + date_reorder[1] + "" + date_reorder[0];
  }
}
