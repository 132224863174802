import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageServiceService } from './language-service.service';
import { Router } from '@angular/router';

declare var sha1;
@Injectable({
  providedIn: 'root'
})
export class SystemService {
  currentInvoicePage:any = 1;
  currentClientsPage:any = 1;
  currentOffersPage:any = 1;
  currentOrdersPage:any = 1;
  currentInvoiceAddonsPage:any = 1;
  currentClientGroupsPage:any = 1;
  currentServicesPage:any = 1;
  currentExpensesPage:any = 1;
  currentContactsPage:any = 1;
  currentAutomaticInvoicePage:any = 1;
  currentStorehousesPage:any = 1;
  currentVirtualItemsPage:any = 1;
  currentStorehouseCategoriesPage:any = 1;
  currencies:any = [];
  metrics:any = [];

  totalInvoicePage:any = 1;
  totalOffersPage:any = 1;
  totalOrdersPage:any = 1;
  totalClientsPage:any = 1;
  totalInvoiceAddonsPage:any = 1;
  totalClientGroupsPage:any = 1;
  totalServicesPage:any = 1;
  totalExpensesPage:any = 1;
  totalContactsPage:any = 1;
  totalAutomaticInvoicePage:any = 1;
  totalStorehousesPage:any = 1;
  totalVirtualItemsPage:any = 1;
  totalStorehouseCategoriesPage:any = 1;

  invoices:any = [];
  automatic_invoices:any = [];
  invoiceAddons:any = [];
  clients:any = [];
  offers:any = [];
  orders:any = [];
  clientGroups:any = [];
  services:any = [];
  expenses:any = [];
  contacts:any = [];
  vats:any = [];
  storehouses:any = [];
  virtual_items:any = [];
  storehouse_categories:any = [];

  totalInvoices:any = 1;
  totalOffers:any = 1;
  totalOrders:any = 1;
  totalInvoiceAddons:any = 1;
  totalClients:any = 1;
  totalClientGroups:any = 1;
  totalServices:any = 1;
  totalExpenses:any = 1;
  totalContacts:any = 1;
  totalAutomaticInvoices:any = 1;
  totalStorehouses:any = 1;
  totalVirtualItems:any = 1;
  totalStorehouseCategories:any = 1;

  logged:any = false;
  profile:any = {};
  company_client_groups:any = [];
  dashboard:any = {};
  me:any = {};
  banks:any = [];
  templates:any = {};
  users:any = [];
  kanbans:any = [];
  tasks:any = [];
  avatarUrl:any = "";
  integrations:any = [];
  push_notifications:any = [];


  constructor(private http:HttpClient, private ngZone:NgZone, private languageService:LanguageServiceService, public router:Router, public zone:NgZone) {
    this.GetInvoices('all', 1, 10, '`date_added` DESC, `num` DESC');
  }

  Login(email, password, remember, mdl) {
    if(remember) { localStorage.setItem('remember-me', 'true'); } else { localStorage.setItem('remember-me', 'false'); }
    if(remember) { localStorage.setItem('email', email); }
    if(remember) { localStorage.setItem('password', password); }
    this.http.post("https://api.nexa.bg/login", { 'username':email, 'password':sha1(password) }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = response['login'];
      this.GetMe();
      this.GetProfile();
      this.GetUsers();
      this.GetDashboard();
      this.GetCurrencies();
      this.GetMetrics();
      this.GetBanks();
      this.GetContacts(1, 10);
      this.GetTemplates();
      this.GetVats();
      this.GetKanbans();
      this.GetTasks();
      this.router.navigate(["/home"]);
    }, err => {
      if(err.status == 401) {
        mdl.error = true;
      }
    });
  }

  Logout() {
    this.http.get("https://api.nexa.bg/logout", {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = false;
    });
  }

  GetInvoices(type, page, limit, order_by) {
    this.http.get("https://api.nexa.bg/invoices?type=invoices&subtype=" + type + "&page=" + page + "&limit=" + limit + "&order=" + order_by, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.invoices = response['invoices'];
      this.currentInvoicePage = page;
      this.totalInvoicePage = response['total_pages'];
      this.totalInvoices = response['total_count'];
      this.GetProfile();
      this.GetUsers();
      this.GetDashboard();
      this.GetMe();
      this.GetCurrencies();
      this.GetMetrics();
      this.GetBanks();
      this.GetContacts(1, 10);
      this.GetTemplates();
      this.GetVats();
      this.GetKanbans();
      this.GetTasks();
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  SearchInvoices(search, page) {
    this.http.post("https://api.nexa.bg/search/invoices", { 'limit':10, 'page':page, 'search':search }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.invoices = response['invoices'];
      this.currentInvoicePage = page;
      this.totalInvoicePage = response['total_pages'];
      this.totalInvoices = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  SearchClients(search, page) {
    this.http.post("https://api.nexa.bg/search/clients", { 'limit':10, 'page':page, 'search':search }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.clients = response['clients'];
      this.currentClientsPage = page;
      this.totalClientsPage = response['total_pages'];
      this.totalClients = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }


  GetInvoicesAddons(type, page, limit) {
    this.http.get("https://api.nexa.bg/invoices?type=invoices-addons&subtype=" + type + "&page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.invoiceAddons = response['invoices'];
      this.currentInvoiceAddonsPage = page;
      this.totalInvoiceAddonsPage = response['total_pages'];
      this.totalInvoiceAddons = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetAutomaticInvoices(page, limit) {
    this.http.get("https://api.nexa.bg/auto-invoices?page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.automatic_invoices = response['auto_invoices'];
      this.currentAutomaticInvoicePage = page;
      this.totalAutomaticInvoicePage = response['total_pages'];
      this.totalAutomaticInvoices = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetClients(type, page, limit) {
    this.http.get("https://api.nexa.bg/clients?type="+ type +"&page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.clients = response['clients'];
      this.currentClientsPage = page;
      this.totalClientsPage = response['total_pages'];
      this.totalClients = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetClientGroups(page, limit) {
    this.http.get("https://api.nexa.bg/client-groups?page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.clientGroups = response['client_groups'];
      this.currentClientGroupsPage = page;
      this.totalClientGroupsPage = response['total_pages'];
      this.totalClientGroups = response['total_count'];
      this.ngZone.run(()=>{});
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }
  RefreshSystemClientGroups() {
    this.http.get("https://api.nexa.bg/client-groups?page=1&limit=1000", {
      withCredentials: true
    })
    .subscribe(response => {
      this.company_client_groups = response['client_groups'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetOffers(status, page, limit) {
    this.http.get("https://api.nexa.bg/offers?status=" + status + "&page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.offers = response['offers'];
      this.currentOffersPage = page;
      this.totalOffersPage = response['total_pages'];
      this.totalOffers = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetOrders(status, page, limit) {
    this.http.get("https://api.nexa.bg/orders?status=" + status + "&page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.orders = response['orders'];
      this.currentOrdersPage = page;
      this.totalOrdersPage = response['total_pages'];
      this.totalOrders = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetExpenses(status, page, limit) {
    this.http.get("https://api.nexa.bg/expenses?status=" + status + "&page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.expenses = response['expenses'];
      this.currentExpensesPage = page;
      this.totalExpensesPage = response['total_pages'];
      this.totalExpenses = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetContacts(page, limit) {
    this.http.get("https://api.nexa.bg/contacts?page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.contacts = response['contacts'];
      this.currentContactsPage = page;
      this.totalContactsPage = response['total_pages'];
      this.totalContacts = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetVats() {
    this.http.get("https://api.nexa.bg/vat-presets?page=1&limit=1000", {
      withCredentials: true
    })
    .subscribe(response => {
      this.vats = response['vat_presets'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
    
  }

  GetUsers() {
    this.http.get("https://api.nexa.bg/me/users", {
      withCredentials: true
    })
    .subscribe(response => {
      this.users = response;
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
    
  }

  GetKanbans() {
    this.http.get("https://api.nexa.bg/kanbans", {
      withCredentials: true
    })
    .subscribe(response => {
      this.kanbans = response['kanbans'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetTasks() {
    this.http.get("https://api.nexa.bg/tasks", {
      withCredentials: true
    })
    .subscribe(response => {
      this.tasks = response['tasks'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetStorehouses(page, limit) {
    this.http.get("https://api.nexa.bg/storages?page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.storehouses = response['storages'];
      this.currentStorehousesPage = page;
      this.totalStorehousesPage = response['total_pages'];
      this.totalStorehouses = response['total_count'];
      console.log(this.storehouses);
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetStorehouseCategories(page, limit) {
    this.http.get("https://api.nexa.bg/storage-categories?page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.storehouse_categories = response['storage_categories'];
      this.currentStorehouseCategoriesPage = page;
      this.totalStorehouseCategoriesPage = response['total_pages'];
      this.totalStorehouseCategories = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }


  GetVirtualItems(page, limit) {
    this.http.get("https://api.nexa.bg/storage-items?page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.logged = true;
      this.virtual_items = response['storage_items'];
      for(var vi = 0; vi < this.virtual_items.length; vi++) {
        this.virtual_items[vi].additional_images = JSON.parse(this.virtual_items[vi].additional_images);
      }
      this.currentVirtualItemsPage = page;
      this.totalVirtualItemsPage = response['total_pages'];
      this.totalVirtualItems = response['total_count'];
      console.log(response);
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  AddTask(name, description, dt, members, project, kanban, created_by, client_id, client_name, mdl) {
    let dt_reformed = this.returnPaddedZero(dt.getDate(), 2) + "." + this.returnPaddedZero(dt.getMonth() + 1, 2) + "." + dt.getFullYear();
    this.http.post("https://api.nexa.bg/tasks", { "task": { 'name':name, 'description': description, 'date_added': dt_reformed, 'date_ended':-1, 'deadline':'', 'status':0, 'members':members, 'project':project, 'kanban':kanban, 'created_by':created_by, 'client_id':client_id, 'client_name':client_name } }, {
      withCredentials: true
    })
    .subscribe(response => {
      mdl.closeSideModal();
      this.GetTasks();
      // setTimeout(() => {  mdl.AddedBank(); }, 250);
    });
  }

  SaveSettings(new_settings, mdl) {
    this.http.put("https://api.nexa.bg/settings", { 'settings':new_settings }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetProfile();
      setTimeout(() => { mdl.GetSettings(); mdl.ShowDone(); }, 250);
    });
  }

  AddBank(bank, mdl) {
    this.http.post("https://api.nexa.bg/bank-accounts", { 'bank_account':bank }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetBanks();
      setTimeout(() => {  mdl.AddedBank(); }, 250);
    });
  }

  RemoveBank(uid, mdl) {
    this.http.delete("https://api.nexa.bg/bank-accounts/" + uid, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetBanks();
      setTimeout(() => {  mdl.RemovedBank(); }, 250);
    });
  }

  AddCurrency(currency, mdl) {
    this.http.post("https://api.nexa.bg/currencies", { 'currency':currency }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetCurrencies();
      setTimeout(() => {  mdl.AddedBank(); }, 250);
    });
  }

  RemoveCurrency(uid, mdl) {
    this.http.delete("https://api.nexa.bg/currencies/" + uid, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetCurrencies();
      setTimeout(() => {  mdl.RemovedBank(); }, 250);
    });
  }

  AddMetric(metric, mdl) {
    this.http.post("https://api.nexa.bg/metrics", { 'metric':metric }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetMetrics();
      setTimeout(() => {  mdl.AddedBank(); }, 250);
    });
  }

  RemoveMetric(uid, mdl) {
    this.http.delete("https://api.nexa.bg/metrics/" + uid, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetMetrics();
      setTimeout(() => {  mdl.RemovedBank(); }, 250);
    });
  }

  AddVat(vat, mdl) {
    this.http.post("https://api.nexa.bg/vat-presets", { 'vat_preset':vat }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetVats();
      setTimeout(() => {  mdl.AddedVat(); }, 250);
    });
  }
  
  RemoveVat(uid, mdl) {
    this.http.delete("https://api.nexa.bg/vat-presets/" + uid, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetVats();
      setTimeout(() => {  mdl.RemovedVat(); }, 250);
    });
  }

  GetProfile() {
    this.http.get("https://api.nexa.bg/settings", {
      withCredentials: true
    })
    .subscribe(response => {
      this.profile = response;
      console.log(this.profile);
      this.languageService.LoadLanguage(response['language'].toLowerCase());
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
    this.http.get("https://api.nexa.bg/client-groups?page=1&limit=1000", {
      withCredentials: true
    })
    .subscribe(response => {
      this.company_client_groups = response['client_groups'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetMe() {
    this.http.get("https://api.nexa.bg/users/me", {
      withCredentials: true
    })
    .subscribe(response => {
      this.me = response;
      this.ngZone.run(()=>{});
      this.LoadAvatar(this.me.uid);
      console.log(this.me);
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetIntegrations() {
    this.http.get("https://api.nexa.bg/integrations", {
      withCredentials: true
    })
    .subscribe(response => {
      this.integrations = response['integrations'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetCurrencies() {
    this.http.get("https://api.nexa.bg/currencies?limit=1000&page=1", {
      withCredentials: true
    })
    .subscribe(response => {
      this.currencies = response['currencies'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }
  
  
  GetMetrics() {
    this.http.get("https://api.nexa.bg/metrics?limit=1000&page=1", {
      withCredentials: true
    })
    .subscribe(response => {
      this.metrics = response['metrics'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetBanks() {
    this.http.get("https://api.nexa.bg/bank-accounts?limit=1000&page=1", {
      withCredentials: true
    })
    .subscribe(response => {
      this.banks = response['bank_accounts'];
      this.zone.run(()=>{});
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetDashboard() {
    this.http.get("https://api.nexa.bg/dashboard", {
      withCredentials: true
    })
    .subscribe(response => {
      this.dashboard = response;
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetServices(page, limit) {
    this.http.get("https://api.nexa.bg/services?page=" + page + "&limit=" + limit, {
      withCredentials: true
    })
    .subscribe(response => {
      this.services = response['services'];
      this.currentServicesPage = page;
      this.totalServicesPage = response['total_pages'];
      this.totalServices = response['total_count'];
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  SaveProfile(profile) {
    this.http.put("https://api.nexa.bg/users/me", { 'user':profile }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetMe();
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  GetTemplates() {
    this.http.get("https://api.nexa.bg/templates", {
      withCredentials: true
    })
    .subscribe(response => {
      this.templates = { 'title_invoices':response['title_invoices'], 'title_offers':response['title_offers'], 'title_orders':response['title_orders'], 'offers':response['offers'], 'invoices':response['invoices'], 'orders':response['orders'] }
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  SetTemplates(template) {
    this.http.put("https://api.nexa.bg/templates", { 'templates':{'uid':1, invoices:template.invoices, offers:template.offers, orders:template.orders, title_invoices:template.title_invoices, title_offers:template.title_offers, title_orders:template.title_orders } } , {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetTemplates();
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  AddKanban(idx) {
    this.http.post("https://api.nexa.bg/kanbans", { "kanban": { 'num':idx + 1, 'label':'Нов списък', 'color':'' } }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetKanbans();
      // setTimeout(() => {  mdl.AddedBank(); }, 250);
    });
  }

  RemoveKanban(uid) {
    this.http.delete("https://api.nexa.bg/kanbans/" + uid, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetKanbans();
    });
  }

  EditTask(task) {
    this.http.put("https://api.nexa.bg/tasks/" + task.uid, { 'task':task } , {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetTasks();
    },(error) => {                              //error() callback
      if(error.status == 401) {
        this.logged = false;
      }
    });
  }

  async LoadAvatar(uid) {
    const resp = await fetch("https://crm.nexa.bg/avatars/"+ sha1(uid.toString()));
    const blob = await resp.blob();
    const reader = new FileReader();
    
    reader.onloadend = () => {
        const base64String = reader.result;
        this.avatarUrl = base64String;
    };
    
    reader.readAsDataURL(blob);
  }

  // *** UTILITY FUNCTIONS *** //
  AddNotification(message, icon, timeout) {
    let guid = this.ReturnGUID();
    this.push_notifications.push({ 'guid':guid, 'message':message, 'icon':icon });
    setTimeout(()=>{
      for(var pi = 0; pi < this.push_notifications.length; pi++) {
        if(this.push_notifications[pi].guid == guid) {
          this.push_notifications.splice(pi, 1);
        }
      }
    }, 1000 * timeout);
  }

  ReturnGUID() {
    return 'xxxxxxxxxxxxxxxx'.replace(/x/g, function() {
      return (Math.random() * 16 | 0).toString(16);
    });
  }
  // *** END UTILITY FUNCTIONS *** //



  ReturnAvatar() { return this.avatarUrl; }

  ReturnClientContacts(client_id) {
    return this.contacts.filter(contact => contact.client == client_id);
  }

  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }


  ReturnMe() { return this.me; }
  

  ReturnAvatarShaByUid(uid) {
    return sha1(uid);
  }


  ReturnAvailableCurrencies() { return this.currencies; }
  ReturnAvailableUnits() { return this.metrics; }
  ReturnClients() { return this.clients; }
  ReturnTotalClientPages() { return this.totalClientsPage; }
  ReturnCurrentClientPages() { return this.currentClientsPage; }
  ReturnTotalClients() { return this.totalClients; }

  ReturnInvoices() { return this.invoices; }
  ReturnTotalInvoicePages() { return this.totalInvoicePage; }
  ReturnCurrentInvoicePages() { return this.currentInvoicePage; }
  ReturnTotalInvoices() { return this.totalInvoices; }

  ReturnAutomaticInvoices() { return this.automatic_invoices; }
  ReturnTotalAutomaticInvoicePages() { return this.totalAutomaticInvoicePage; }
  ReturnCurrentAutomaticInvoicePages() { return this.currentAutomaticInvoicePage; }
  ReturnTotalAutomaticInvoices() { return this.totalAutomaticInvoices; }

  ReturnInvoiceAddons() { return this.invoiceAddons; }
  ReturnTotalInvoiceAddonsPages() { return this.totalInvoiceAddonsPage; }
  ReturnCurrentInvoiceAddonsPages() { return this.currentInvoiceAddonsPage; }
  ReturnTotalAddonsInvoices() { return this.totalInvoiceAddons; }

  ReturnOffers() { return this.offers; }
  ReturnTotalOffersPages() { return this.totalOffersPage; }
  ReturnCurrentOffersPages() { return this.currentOffersPage; }
  ReturnTotalOffers() { return this.totalOffers; }


  ReturnOrders() { return this.orders; }
  ReturnTotalOrdersPages() { return this.totalOrdersPage; }
  ReturnCurrentOrdersPages() { return this.currentOrdersPage; }
  ReturnTotalOrders() { return this.totalOrders; }

  ReturnClientGroups() { return this.clientGroups; }
  ReturnTotalClientGroupsPages() { return this.totalClientGroupsPage; }
  ReturnCurrentClientGroupsPages() { return this.currentClientGroupsPage; }
  ReturnTotalClientGroups() { return this.totalClientGroups; }

  ReturnServices() { return this.services; }
  ReturnTotalServicesPages() { return this.totalServicesPage; }
  ReturnCurrentServicesPages() { return this.currentServicesPage; }
  ReturnTotalServices() { return this.totalServices; }

  ReturnExpenses() { return this.expenses; }
  ReturnTotalExpensesPage() { return this.totalExpensesPage; }
  ReturnCurrentExpensesPage() { return this.currentExpensesPage; }
  ReturnTotalExpenses() { return this.totalExpenses; }

  ReturnContacts() { return this.contacts; }
  ReturnTotalContactsPage() { return this.totalContactsPage; }
  ReturnCurrentContactsPage() { return this.currentContactsPage; }
  ReturnTotalContacts() { return this.totalContacts; }

  ReturnStorehouses() { return this.storehouses; }
  ReturnTotalStorehousesPage() { return this.totalStorehousesPage; }
  ReturnCurrentStourehousesPage() { return this.currentStorehousesPage; }
  ReturnTotalStorehouses() { return this.totalStorehouses; }

  ReturnStorehouseCategories() { return this.storehouse_categories; }
  ReturnTotalStorehouseCategoriesPage() { return this.totalStorehouseCategoriesPage; }
  ReturnCurrentStourehouseCategoriesPage() { return this.currentStorehouseCategoriesPage; }
  ReturnTotalStorehouseCategories() { return this.totalStorehouseCategories; }

  ReturnVirtualitems() { return this.virtual_items; }
  ReturnTotalVirtualItemsPage() { return this.totalVirtualItemsPage; }
  ReturnCurrentVirtualItemsPage() { return this.currentVirtualItemsPage; }
  ReturnTotalVirtualItems() { return this.totalVirtualItems; }

  ReturnLogged() { return this.logged; }
  ReturnProfile() { return this.profile; }
  ReturnTemplates() { return this.templates; }
  ReturnVats() { return this.vats; }
  ReturnCompanyClientGroups() { return this.company_client_groups; }
  ReturnClientGroup(group_id) { return this.company_client_groups.filter(client_group => client_group.uid == group_id)[0]; }
  ReturnBanks() { return this.banks; }
  ReturnDashboard() { return this.dashboard; }
  ReturnIntegrations() { return this.integrations; }
  ReturnProductsFromItems(items) {
    let products = [];
    for(let i = 0; i < items.length; i++) {
      if(items[i] != '') {
        let product_split = items[i].split("|");
        products.push(new Product(i, product_split[0],product_split[3], product_split[4], product_split[2], parseFloat(product_split[1])));
      } else {
        products.push(new Product(1, "", 1, 20, this.ReturnAvailableUnits()[0].name, 0.00));
      }
      //items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2],  "quantity":product_split[3], "vat":parseInt(product_split[4]) })
    }
    return products;
  }
  ReturnUsers() { return this.users; }
  ReturnUser(uid) { 
    let users = JSON.parse(JSON.stringify(this.users));
    users.push(this.ReturnMe());
    return users.filter(user => user.uid == uid)[0]; 
  }
  ReturnKanbans() { return this.kanbans; }
  ReturnTasks() { return this.tasks; }
  ReturnPushNotifications() { return this.push_notifications; }
}

export class Product {
  id:any = 1;
  name:any = "";
  quantity:any = 1;
  vat:any = 0;
  unit:any = "бр.";
  price = 0.00;
  image:any = "";

  constructor(id, name, quantity, vat, unit, price, image?:String) {
    this.id = id;
    this.name = name;
    this.quantity = quantity;
    this.vat = vat;
    this.unit = unit;
    this.price = price;
    if(image !== undefined) {
      this.image = image;
    }
  }
}