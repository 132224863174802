import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.css']
})
export class AddServiceComponent {
  service:any = { 'name':'', 'description':'', 'metric':'', 'price':0.00, 'vat':20, 'barcode':'', 'product_code':'' }

  constructor(public languageService:LanguageServiceService,public http:HttpClient, public router:Router) {  }

  AddService() {
    this.http.post("https://api.nexa.bg/services", { 'service':{ name:this.service.name, 'type':'', 'description':this.service.description, 'metric':this.service.metric, 'price':this.service.price, 'vat':this.service.vat, 'barcode':this.service.barcode, 'product_code':this.service.product_code }}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.router.navigate(['/services']);
    });
  }

  escape(s) {
    return ('' + s)
        .replace(/\\/g, '\\\\')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\u00A0/g, '\\u00A0')
        .replace(/&/g, '\\x26')
        .replace(/'/g, '\\x27')
        .replace(/"/g, '\\x22')
        .replace(/</g, '\\x3C')
        .replace(/>/g, '\\x3E');
}

unescape(s) {
    s = ('' + s)
       .replace(/\\x3E/g, '>')
       .replace(/\\x3C/g, '<')
       .replace(/\\x22/g, '"')
       .replace(/\\x27/g, "'")
       .replace(/\\x26/g, '&')
       .replace(/\\u00A0/g, '\u00A0')
       .replace(/\\n/g, '\n')
       .replace(/\\t/g, '\t');

    return s.replace(/\\\\/g, '\\');
}
}
