
<div class="side-modal" *ngIf="sideModal.open">
    <div class="backdrop"></div>
    <div class="modal" [ngClass]="{ 'active':sideModal.open && sideModal.cntx == 'add-task' }">
        <div class="header bg-positive text-white">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-8 h-8 mt-2 float-left ml-4">
                <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
            </svg>
              
            <span class="title float-left ml-2 font-bold">{{ languageService.ReturnTranslatedLabel('label_add_task') }}</span>

            <svg xmlns="http://www.w3.org/2000/svg" (click)="sideModal.open = false" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-right mt-3 mr-2">
                <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
            </svg>
        </div>
        <div class="content flex flex-col w-full">
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Задача <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <input [(ngModel)]="sideModal.obj.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text">
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Описание <span class="text-red-500">*</span></label>
                <div class="flex w-full">
                    <textarea style="height:100px;resize:none;" [(ngModel)]="sideModal.obj.description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text"></textarea>
                </div>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Членове <span class="text-red-500">*</span></label>
                <div class="flex w-full" style="width: calc(100% - 30px);">
                    <div class="flex w-full" style="border: 1px solid #cfcfcf;
                    border-radius: 10px;
                    min-height: 40px;
                    height: auto;display:block;">
                        <div class="selected-member" *ngFor="let member of sideModal.obj.members; let mi = index">
                            <div class="avatar" [ngStyle]="{ 'background-image':'url(https://crm.nexa.bg/avatars/'+ ReturnAvatarSha(member) +')' }"></div>
                            <span>{{ member.first_name }} {{ member.last_name }}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" *ngIf="mi != 0" (click)="RemoveMemberFromTask(member, mi)" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>                              
                        </div>
                        <div class="add-member" (click)="OpenAddMember()">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                            <span>Добави член</span>                              
                        </div>
                        <div class="member-suggest" *ngIf="sideModal.obj.member_selecting" (clickOutside)="CloseAddMember()">
                            <input type='text' [(ngModel)]="sideModal.obj.add_member" />
                            <div class="search-ill" *ngIf="sideModal.obj.add_member.length == 0 || ReturnSearchMembers().length == 0">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                                <span>Въведете поне 1 символ за да намерите потребител</span>
                            </div>
                            <div class="suggest" *ngFor="let member of ReturnSearchMembers()" (click)="AddMemberToMembers(member)">
                                <div class="avatar" [ngStyle]="{ 'background-image':'url(https://crm.nexa.bg/avatars/'+ ReturnAvatarSha(member) +')' }"></div>
                                <div class="name">{{ member.first_name }} {{ member.last_name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="flex flex-col w-full mt-4">
                    <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 mr-2" >Добави член <span class="text-red-500">*</span></label>
                    <div class="flex w-full">
                        <input [(ngModel)]="sideModal.obj.add_member" (focus)="sideModal.obj.member_selecting = true" (focusout)="sideModal.obj.member_selecting = false" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full mr-8" id="inline-full-name" type="text"/>
                        <div class="member-suggest" *ngIf="sideModal.obj.member_selecting">
                            <div class="suggest" *ngFor="let member of ReturnSearchMembers()">
                                <div class="avatar" [ngStyle]="{ 'background-image':'url(https://crm.nexa.bg/avatars/'+ ReturnAvatarSha(member) +')' }"></div>
                                <div class="name">{{ member.first_name }} {{ member.last_name }}</div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">{{ languageService.ReturnTranslatedLabel('label_client') }}</span>
				<span class="text-xs text-gray-600">{{ languageService.ReturnTranslatedLabel('label_enter_name_or_social_hint') }}</span>
				<ng-select style="width:94%;" class="mb-1 bg-gray-100 border-2 border-white rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500" 
							(change)="selectedClient()"
							[items]="available_clients" 
							bindLabel="name" 
							bindValue="uid" 
							(clear)="sideModal.obj.client_id = -1"
							[(ngModel)]="sideModal.obj.client_id">
				</ng-select>
            </div>
            <div class="mb-2 mt-2 w-full flex flex-col ml-4">
                <span class="block uppercase tracking-wide text-grey-darker text-xs font-bold">Списък</span>
                <select [(ngModel)]="sideModal.obj.kanban" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2  ng-pristine ng-valid ng-touched w-full">
                    <option *ngFor="let kanban of system.ReturnKanbans()" value="{{ kanban.uid }}" >{{ kanban.label }}</option>
                </select> 
            </div>


            <button (click)="AddTask()" class="flex items-center justify-center w-full ml-4 mr-4 mt-4 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>                      

                <span>{{ languageService.ReturnTranslatedLabel('label_add_task') }}</span>
            </button>
        </div>
    </div>
</div>

<section class="min-h-screen custom-min-h-screen bg-nexa bg-nexa text-gray-800">
    <div class="flex flex-col p-4" style="padding-top:0px;padding-bottom:0px">
        <div class="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 mt-2">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="border border-gray-200  md:rounded-lg bg-white" style="min-height: calc(100vh - 120px);">
                    <div class="w-full flex flex-row p-4 bg-white">
                        <div class="flex w-3/4">
                            <h3>Задачи</h3>
                        </div>
                        <div class="flex flex-row w-2/4">
                            <div class="search-box ml-4">
                                <input type="text" style="width:300px;" class="p-2 bg-white border-2 mr-2 text-sm rounded-3xl ml-4" placeholder="Търсене" />
                            </div>
                            <button class="float-right flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" (click)="openSideModal('add-task', { 'name':'', description:'', members:[], add_member:'' })">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>                      
                
                                <span>Добави задача</span>
                            </button>
                        </div>
                    </div>
                    <div class="w-full flex  bg-white">
                        <div class="tab-selector">
                            <div class="tab">Списък</div>
                            <div class="tab selected">Планер</div>
                            <div class="tab">Календар</div>
                        </div>
                    </div>
                    <div class="w-full flex task-window bg-white p-4">
                        <div class="flex flex-col list-tree" id="tree-{{kanban.uid}}" (drop)="drop($event)" (dragover)="allowDrop($event)" *ngFor="let kanban of system.ReturnKanbans() | orderBy: 'num';let i = index">
                            <div class="list-tree-title text-sm font-semibold w-full flex">{{ kanban.label }}                           
                                <svg xmlns="http://www.w3.org/2000/svg" (click)="system.AddKanban(kanban.num)" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:text-indigo-600">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:text-indigo-600 hideable">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                </svg> 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" (click)="system.RemoveKanban(kanban.uid)" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 hover:text-indigo-600 hideable">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>                                         
                              </div>
                            <div class="card bg-white rounded-xl mt-4 rounded-lg p-4" id="tree-{{kanban.uid}}-card-{{task.uid}}" draggable="true" (dragstart)="drag($event, task)" *ngFor="let task of ReturnTaskByKanban(kanban); let ti = index">
                                <div class="card-title">
                                    <span class="card-title-text">{{ task.name }}</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                        <path fill-rule="evenodd" d="M10.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm0 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" clip-rule="evenodd" />
                                    </svg>                                      
                                </div>
                                <div class="card-content">
                                    <div class="client-info" *ngIf="task.client_id != null && task.client_id != -1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clip-rule="evenodd" />
                                        </svg>                                          
                                        <span>Аксион Солюшънс ЕООД</span>
                                    </div>
                                    <div class="times" *ngIf="task.deadline != '' && task.deadline != null">
                                        <div class="deadline">Краен срок {{ task.deadline }}</div>
                                    </div>
                                    <div class="members">
                                        <div class="main-member" [ngStyle]="{ 'background-image':'url(https://crm.nexa.bg/avatars/'+ ReturnAvatarShaByUid(task.created_by) +')' }"></div>
                                        <svg *ngIf="task.members != '' && task.members != null" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z" clip-rule="evenodd" />
                                        </svg>                                          
                                        <div class="sub-member" *ngFor="let member of ReturnMembers(task.members)" [ngStyle]="{ 'background-image':'url(https://crm.nexa.bg/avatars/'+ ReturnAvatarShaByUid(member) +')' }"></div>
                                        <!-- <div class="sub-member"></div>
                                        <div class="sub-member"></div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <table class="min-w-full divide-y divide-gray-200 ">
                        <thead class="bg-white">
                            <tr class="text-sm font-bold text-black">
                                <th scope="col" class="py-3.5 px-4 text-left rtl:text-right" (click)="SwitchOrder('num')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_document') }}</span>

                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'num'"></div>
                                    </button>
                                </th>


                                <th scope="col" class="px-12 py-3.5 text-left rtl:text-right" (click)="SwitchOrder('client_name')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_client') }}</span>
                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'client_name'"></div>
                                    </button>
                                </th>

                                <th scope="col" class="px-12 py-3.5 text-left rtl:text-right" (click)="SwitchOrder('status')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        {{ languageService.ReturnTranslatedLabel('label_status') }}

                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'status'"></div>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right" (click)="SwitchOrder('date_added')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        {{ languageService.ReturnTranslatedLabel('label_information') }}
                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'date_added'"></div>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    <button class="flex items-center gap-x-3 focus:outline-none" (click)="SwitchOrder('type_payment')"> 
                                        {{ languageService.ReturnTranslatedLabel('label_payment_method') }}
                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'type_payment'"></div>
                                    </button>
                                </th>
                                    

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right" (click)="SwitchOrder('total_value')">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        {{ languageService.ReturnTranslatedLabel('label_price') }}
                                        <div class="sort-icon" [ngClass]="{ 'sort-up':direction == 'ASC', 'sort-down':direction == 'DESC' }" *ngIf="GetOrder() == 'total_value'"></div>
                                    </button>
                                </th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200  ">
                            <tr *ngIf="system.ReturnInvoices().length == 0">
                                <td class="text-center p-4 bg-gray-100" colspan="6">{{ languageService.ReturnTranslatedLabel('label_no_invoices') }}</td>
                            </tr>
                            <tr *ngFor="let invoice of system.ReturnInvoices()">
                                <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                    <div class="flex flex-row">
                                        <div>
                                            <h2 class="font-medium text-gray-800  " *ngIf="invoice.subtype == 'invoice'">{{ languageService.ReturnTranslatedLabel('label_invoice') }}</h2>
                                            <h2 class="font-medium text-gray-800  " *ngIf="invoice.subtype == 'proforma'">{{ languageService.ReturnTranslatedLabel('label_proforma') }}</h2>
                                            <p class="text-sm font-normal text-gray-600 ">#{{ invoice.num }}</p>
                                        </div>
                                        <div *ngIf="invoice.external_invoice == 1" (click)="ViewExternalInvoice(invoice)">
                                            <svg style="margin-left: 10px;margin-top: 8px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                                <path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
                                                <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clip-rule="evenodd" />
                                              </svg>
                                        </div>
                                    </div>
                           
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700 ">{{ invoice.client_name }}</h4>
                                    </div>
                                </td>
                                <td class="px-12 py-2 text-sm font-medium whitespace-nowrap" style="position:relative;">
                                    <div style="text-transform: uppercase;font-size: 10px;" (click)="openStatus(invoice)" class="inline-flex px-3 py-1 text-sm font-bold rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 " *ngIf="invoice.status == '2'">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_paid') }}</span>
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-emerald-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                          </button>
                                    </div>
                                    <div style="text-transform: uppercase;font-size: 10px;" (click)="openStatus(invoice)" class="inline-flex px-3 py-1 text-sm font-bold rounded-full text-orange-500 gap-x-2 bg-orange-100 " *ngIf="invoice.status == '1'">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}</span>
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-orange-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                          </button>                                      
                                    </div>
                                    <div style="text-transform: uppercase;font-size: 10px;" (click)="openStatus(invoice)" class="inline-flex px-3 py-1 text-sm font-bold rounded-full text-red-500 gap-x-2 bg-red-100/60 " *ngIf="invoice.status == '0'">
                                        <span>{{ languageService.ReturnTranslatedLabel('label_anulled') }}</span>
                                        <button type="button" class="inline-flex items-center p-1 text-sm bg-transparent rounded-sm text-red-500" data-dismiss-target="#badge-dismiss-default" aria-label="Remove">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>                                              
                                            <span class="sr-only">Remove badge</span>
                                          </button>
                                    </div>
                                    <div class="status-dropdown" *ngIf="invoice.open_status" (clickOutside)="closeOpenStatus(invoice, $event)">
                                        <div class="status-dropdown-item" (click)="changeInvoiceStatus(invoice, 1)">{{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}</div>
                                        <div class="status-dropdown-item" (click)="changeInvoiceStatus(invoice, 2)">{{ languageService.ReturnTranslatedLabel('label_paid') }}</div>
                                        <div class="status-dropdown-item" (click)="changeInvoiceStatus(invoice, 0)">{{ languageService.ReturnTranslatedLabel('label_anulled') }}</div>
                                    </div>
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div>
                                        <p class="text-gray-500 ">{{ languageService.ReturnTranslatedLabel('label_created_at_date') }} {{ returnFormattedDate(invoice.date_added) }}</p>
                                        <p class="text-gray-500 ">{{ languageService.ReturnTranslatedLabel('label_due_date') }} {{ returnFormattedDate(invoice.date_due) }}</p>
                                    </div>
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
                                    <div class="flex items-center" *ngIf="invoice.type_payment == '3'">{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</div>
                                </td>


                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div class="flex item-center">
                                        {{ invoice.total_value }} {{ invoice.currency }}
                                    </div>
                                </td>

                                <td class="px-4 py-2 text-sm whitespace-nowrap text-right">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2" routerLink="/print-invoice" [queryParams]="{id: invoice.uid }">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
                                            <path fill-rule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clip-rule="evenodd" />
                                        </svg>                                          
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2" routerLink="/edit-invoice/{{ invoice.uid }}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                                            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                                        </svg>                                          
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2" (click)="openModal('send-invoice', { 'contacts':system.ReturnClientContacts(invoice.client_id), 'invoice':invoice, template:ReturnShadowCopy(this.system.ReturnTemplates())['invoices'], 'selected_contact':'' })">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                                            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                                          </svg>                                          
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2" (click)="DownloadInvoice(invoice)">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M19.5 21a3 3 0 003-3V9a3 3 0 00-3-3h-5.379a.75.75 0 01-.53-.22L11.47 3.66A2.25 2.25 0 009.879 3H4.5a3 3 0 00-3 3v12a3 3 0 003 3h15zm-6.75-10.5a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V10.5z" clip-rule="evenodd" />
                                        </svg>                                        
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2"  routerLink="/add-invoice/invoice/{{invoice.uid}}">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z" />
                                            <path d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z" />
                                          </svg>
                                                                           
                                    </button>
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100 ml-2 mr-2" routerLink="/print-invoice" [queryParams]="{id: invoice.uid, 'print':true }">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                            <path fill-rule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z" clip-rule="evenodd" />
                                          </svg>                                                                            
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="sm:flex sm:items-center sm:justify-between w-full p-4">
        <div>
            <div class="flex items-center gap-x-3">
                <h2 class="text-lg font-medium text-gray-800">Задачи</h2>

                <span class="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full">24 активни задачи</span>
            </div>
        </div>

        <div class="flex items-center mt-4 gap-x-3">
            <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <span>Добавяне на задача</span>
            </button>
        </div>
    </div>

    <div class="mt-2 md:flex md:items-center md:justify-between p-4 pt-2 ">
        <div class="inline-flex overflow-hidden bg-white border divide-x rounded-lg rtl:flex-row-reverse ">
            <button class="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 bg-gray-100 sm:text-sm">
                Виж всички
            </button>

            <button class="px-5 py-2 text-xs font-medium text-gray-600 transition-colors duration-200 sm:text-sm hover:bg-gray-100">
                Мой задачи
            </button>
        </div>

        <div class="relative flex items-center mt-2 md:mt-0">
            <span class="absolute">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mx-3 text-gray-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
            </span>

            <input type="text" placeholder="Search" class="block w-full py-1.5 pr-5 text-gray-700 bg-white border border-gray-200 rounded-lg md:w-80 placeholder-gray-400/70 pl-11 rtl:pr-11 rtl:pl-5 focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40">
        </div>
    </div>

    <div class="flex flex-col mt-2 p-4">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden border border-gray-200 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col" class="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>Задача</span>

                                        <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                        </svg>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                   Активност
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">Създаден</th>

                                <th scope="col" class="px-6 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    Статус
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">Клиент</th>

                                <th scope="col" class="px-6 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">
                                    Проект
                                </th>




                                <th scope="col" class="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500">Назначени</th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr>
                                <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                    <div>
                                        <h2 class="font-medium text-gray-800 ">Визия за PANORMA PARK</h2>
                                        <p class="text-sm font-normal text-gray-600">Трябва да се изкарат 2 варианта на 4 визии на месец</p>
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700">Последна активност - 03.05.2023г.</h4>
                                        <div class="relative float-left font-normal mt-2 px-3 py-1 text-sm rounded-full text-gray-700 gap-x-2 bg-gray-200">
                                            Няма краен срок
                                        </div>
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700">Георги Петков</h4>
                                        <p class="text-sm font-normal text-gray-600 ">Създаден на - 03.05.2023г.</p>
                                    </div>
                                </td>

                                <td class="px-6 py-4 text-sm font-medium whitespace-nowrap">
                                    <div class="inline px-3 py-1 text-sm font-medium rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60">
                                        Изпълнен
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <p class="text-sm font-medium text-gray-600">Аксион Солюшънс ЕООД</p>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="bg-indigo-400 h-max w-max rounded-full text-white font-bold hover:bg-indigo-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-10 h-10 p-2 text-white">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                                          </svg>                                           
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="flex items-center">
                                        <img class="object-cover w-10 h-10 -mx-1 border-2 border-white rounded-full shrink-0" src="https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=256&q=80" alt="">
                                        <span class="text-gray-600 font-small ml-2">Пламен Петров</span>
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>

                            <tr>
                                <td class="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                    <div>
                                        <h2 class="font-medium text-gray-800 ">Визия за Аксион</h2>
                                        <p class="text-sm font-normal text-gray-600">Трябва да се изкарат 4 варианта на 14 визии на месец</p>
                                    </div>
                                </td>
                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700">Последна активност - 03.05.2023г.</h4>
                                        <div class="relative float-left font-normal mt-2 px-3 py-1 text-sm rounded-full text-gray-700 gap-x-2 bg-gray-200">
                                            Няма краен срок
                                        </div>
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div>
                                        <h4 class="text-gray-700">Георги Петков</h4>
                                        <p class="text-sm font-normal text-gray-600">Създаден на - 03.05.2023г.</p>
                                    </div>
                                </td>

                                <td class="px-6 py-4 text-sm font-medium whitespace-nowrap">
                                    <div class="inline px-3 py-1 text-sm font-medium rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60">
                                        Изпълнен
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="bg-indigo-400 h-max w-max rounded-full text-white font-bold hover:bg-indigo-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 p-2 text-white">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                                        </svg>                                      
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="bg-indigo-400 h-max w-max rounded-full text-white font-bold hover:bg-indigo-600">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"  class="w-10 h-10 p-2 text-white">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 10.5v6m3-3H9m4.06-7.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z" />
                                          </svg>                                   
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <div class="flex items-center">
                                        <img class="object-cover w-10 h-10 -mx-1 border-2 border-white rounded-full shrink-0" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=256&q=80" alt="">
                                        <img class="object-cover w-10 h-10 -mx-1 border-2 border-white rounded-full shrink-0" src="https://images.unsplash.com/photo-1560250097-0b93528c311a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=256&q=80" alt="">
                                        <p class="flex items-center justify-center w-6 h-6 -mx-1 text-xs text-blue-600 bg-blue-100 border-2 border-white rounded-full">+4</p>
                                    </div>
                                </td>

                                <td class="px-4 py-4 text-sm whitespace-nowrap">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg hover:bg-gray-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section> -->