
<div style="width:100%;height:100%;float:left;" *ngIf="system.ReturnLogged()">
  <div id="notification-container" class="fixed top-4 right-4 space-y-4 z-50">
    <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-lg shadow-md flex items-center transition-opacity duration-500" *ngFor="let notification of system.ReturnPushNotifications()">
      <svg class="w-6 h-6 mr-2 fill-current text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7 12l2 2m4-4l2 2" />
      </svg>
      <span>Test notification</span>
    </div>
  </div>


    <nav class="border-b-1 border-gray-300 print:hidden">
    <div class="mx-auto max-w-8xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-20 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button type="button" class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <!--
              Icon when menu is closed.

              Menu open: "hidden", Menu closed: "block"
            -->
            <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <!--
              Icon when menu is open.

              Menu open: "block", Menu closed: "hidden"
            -->
            <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <div class="flex flex-shrink-0 items-center">
            <img class="block h-6 w-auto lg:hidden" src="logo/{{ system.ReturnProfile().logo }}" alt="Your Company">
            <img class="hidden h-6 w-auto lg:block" src="logo/{{ system.ReturnProfile().logo }}" alt="Your Company">
          </div>
          <div class="hidden sm:ml-6 sm:block">
            <div class="flex space-x-4" style="margin-top:3px;">
              <a class="text-black-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium" routerLink="/home" routerLinkActive="bg-positive text-white" aria-current="page">{{ languageService.ReturnTranslatedLabel('menu_home') }}</a>
              
              <a class="relative" *ngIf="system.ReturnMe().layout == 'ONLINE-STORE'">
                <button routerLink="/orders" type="button" class="inline-flex items-center gap-x-1 ttext-black-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"  routerLinkActive="bg-positive text-white">
                  <span>{{ languageService.ReturnTranslatedLabel('menu_orders') }}</span>
                </button>
              </a>

              <a class="relative">
                <button type="button" (click)="switchClientsOn()" class="inline-flex items-center gap-x-1 ttext-black-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium" [ngClass]="{ 'bg-positive':clientsOpen || returnLocationIsClient(), 'text-white':clientsOpen || returnLocationIsClient() }"  routerLinkActive="active">
                  <span>{{ languageService.ReturnTranslatedLabel('menu_clients') }}</span>
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                </button>

                <div class="absolute left-1/2 left-custom z-10 mt-4 flex w-screen max-w-max -translate-x-1/2 px-4" (clickOutside)="onClickedOutside($event)" *ngIf="clientsOpen">
                  <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/10">
                    <div class="p-4">
                      <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/clients"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                          </svg>                          
                        </div>
                        <div>
                          <a routerLink="/clients"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_clients') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_clients_hint') }}</p>
                        </div>
                      </div>
              
                      <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/client-groups"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
                          </svg>
                        </div>
                        <div>
                          <a routerLink="/client-groups"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_clients_group') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_client_groups_hint') }}</p>
                        </div>
                      </div>

                      <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/contacts"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0l-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                          </svg>                        
                        </div>
                        <div>
                          <a routerLink="/contacts"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_contacts') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_contacts_hint') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>            

              <a class="relative">
                <button type="button" (click)="switchSalesOn()" class="inline-flex items-center gap-x-1 ttext-black-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium" [ngClass]="{ 'bg-positive':salesOpen || returnLocationIsFinancial(), 'text-white':salesOpen || returnLocationIsFinancial() }" aria-expanded="false">
                  <span>{{ languageService.ReturnTranslatedLabel('menu_financial') }}</span>
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                </button>

                <div class="absolute left-1/2 left-custom z-10 mt-4 flex w-screen max-w-max -translate-x-1/2 px-4" (clickOutside)="onClickedOutside($event)" *ngIf="salesOpen">
                  <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/10">
                    <div class="p-4">
                      <div class="group relative flex mt-2 gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/offers"  routerLinkActive="active"  *ngIf="system.ReturnMe().layout != 'ONLINE-STORE'">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" routerLinkActive="text-indigo-600">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V13.5zm0 2.25h.008v.008H8.25v-.008zm0 2.25h.008v.008H8.25V18zm2.498-6.75h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V13.5zm0 2.25h.007v.008h-.007v-.008zm0 2.25h.007v.008h-.007V18zm2.504-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zm0 2.25h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V18zm2.498-6.75h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V13.5zM8.25 6h7.5v2.25h-7.5V6zM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 002.25 2.25h10.5a2.25 2.25 0 002.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0012 2.25z" />
                          </svg>
                        </div>
                        <div>
                          <a routerLink="/offers" (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_offers') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_offers_hint') }}</p>
                        </div>
                      </div>

                      <div class="group relative flex mt-2 gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/orders"  routerLinkActive="active" *ngIf="system.ReturnMe().layout != 'ONLINE-STORE'">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5"  routerLink="/orders" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                          </svg>
                        </div>
                        <div>
                          <a routerLink="/orders" (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_orders') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text" routerLink="/orders">{{ languageService.ReturnTranslatedLabel('menu_orders_hint') }}</p>
                        </div>
                      </div>
              
                      <div class="group relative flex mt-2 gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/invoices"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" routerLinkActive="text-indigo-600">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                          </svg>
                        </div>    
                        <div>
                          <a routerLink="/invoices"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_invoices') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_invoice_outgoing_hint') }}</p>
                        </div>
                      </div>
              
                      <!-- <div class="group relative flex mt-2 gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/invoice-addons"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" routerLinkActive="text-indigo-600">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
                          </svg>
                        </div>
                        <div>
                          <a href="#" routerLink="/invoice-addons" (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_addons') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_notice_hint') }}</p>
                        </div>
                      </div> -->

                      <div class="group relative flex mt-2 gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/automatic-invoices"  routerLinkActive="active" *ngIf="system.ReturnMe().layout != 'ONLINE-STORE'">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg  class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 64 64" stroke-width="1.5" stroke="currentColor" aria-hidden="true" routerLinkActive="text-indigo-600">
                            <g><path d="M50.321 4c-5.745.002-30.422-.001-36.642 0a3.897 3.897 0 0 0-3.893 3.893v48.214A3.897 3.897 0 0 0 13.68 60H50.32a3.897 3.897 0 0 0 3.893-3.893V7.893A3.897 3.897 0 0 0 50.32 4Zm-7.678 3.414 8.157 8.157h-6.264a1.895 1.895 0 0 1-1.893-1.892Zm9.57.479v6.264L44.058 6h6.264a1.894 1.894 0 0 1 1.893 1.893ZM50.322 58H13.68a1.894 1.894 0 0 1-1.893-1.893V7.893A1.894 1.894 0 0 1 13.68 6h26.964v7.679a3.898 3.898 0 0 0 3.893 3.892h7.678v38.536A1.894 1.894 0 0 1 50.32 58Z" opacity="1" data-original="#000000" class=""></path><path d="M22.388 30.243a1 1 0 0 0 .96-.724c1.771-6.638 10.688-8.76 15.242-3.638l-.53-.066a1 1 0 0 0-.244 1.985l2.808.345a1.016 1.016 0 0 0 1.037-.625c.088.028.393-2.96.422-3.053a1 1 0 0 0-.87-1.114 1.058 1.058 0 0 0-1.152 1.175 10.987 10.987 0 0 0-18.634 4.44 1.004 1.004 0 0 0 .96 1.275ZM41.888 33.796a.996.996 0 0 0-1.237.685c-1.77 6.639-10.686 8.76-15.24 3.639l.53.065a1 1 0 0 0 .242-1.985l-2.808-.344a1.011 1.011 0 0 0-1.027.608c-.107.028-.396 2.936-.431 3.07a1.002 1.002 0 0 0 .993 1.121 1.083 1.083 0 0 0 1.029-1.182 10.915 10.915 0 0 0 5.029 3.1 11.03 11.03 0 0 0 13.605-7.54 1 1 0 0 0-.685-1.237Z"  opacity="1" data-original="#000000" class=""></path></g>
                          </svg>
                        </div>
                        <div>
                          <a href="#" routerLink="/automatic-invoices" (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_automatic_invoices') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_automatic_invoices_hint') }}</p>
                        </div>
                      </div>
                      <!-- <div class="group relative flex mt-2 gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/expenses"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" routerLinkActive="text-indigo-600">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                          </svg>
                          
                        </div>
                        <div>
                          <a href="#" routerLink="/expenses" (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            {{ languageService.ReturnTranslatedLabel('menu_expenses') }}
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_expenses_hint') }}</p>
                        </div>
                      </div>
              -->
              
                      <!-- <div class="group relative flex mt-2 gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                          </svg>
                        </div>
                        <div>
                          <a href="#"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            Протоколи
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">Издаване на протоколи и бланки</p>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </a>

              <a class="relative">
                <button type="button" (click)="switchProductsOn()" class="inline-flex items-center gap-x-1 ttext-black-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"  routerLinkActive="active">
                  <span>Артикули</span>
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                </button>

                <div class="absolute left-1/2 left-custom z-10 mt-4 flex w-screen max-w-max -translate-x-1/2 px-4" (clickOutside)="onClickedOutside($event)" *ngIf="productsOpen">
                  <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/10">
                    <div class="p-4">
                      <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/storehouses"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon"> 
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                          </svg>                                               
                        </div>
                        <div>
                          <a routerLink="/storehouses"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            Складове
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_clients_hint') }}</p>
                        </div>
                      </div>
              
                      <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/product-categories"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.568 3H5.25A2.25 2.25 0 0 0 3 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 0 0 5.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 0 0 9.568 3Z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6Z" />
                          </svg>                          
                        </div>
                        <div>
                          <a routerLink="/storehouse_categories"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            Продуктови категории
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_client_groups_hint') }}</p>
                        </div>
                      </div>

                      <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100 nav-btn-group" routerLink="/virtual-items"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
                          </svg>                                           
                        </div>
                        <div>
                          <a routerLink="/virtual-items"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            Продукти
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600 nav-btn-text">{{ languageService.ReturnTranslatedLabel('menu_contacts_hint') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>      

              <!-- <a class="relative" *ngIf="system.ReturnMe().first_name == 'Пламен' && system.ReturnMe().last_name == 'Петров'">
                <button type="button" (click)="switchOrganizationOn()" class="inline-flex items-center gap-x-1 ttext-black-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium" [ngClass]="{ 'bg-gray-700':organizationOpen, 'text-white':organizationOpen }" aria-expanded="false">
                  <span>Организация</span>
                  <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                  </svg>
                </button>

                <div class="absolute left-1/2 left-custom z-10 mt-4 flex w-screen max-w-max -translate-x-1/2 px-4" (clickOutside)="onClickedOutside($event)" *ngIf="organizationOpen">
                  <div class="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/10">
                    <div class="p-4">
                      <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100" routerLink="/tasks"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon">
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"  routerLinkActive="text-indigo-600">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M7.875 14.25l1.214 1.942a2.25 2.25 0 001.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 011.872 1.002l.164.246a2.25 2.25 0 001.872 1.002h2.092a2.25 2.25 0 001.872-1.002l.164-.246A2.25 2.25 0 0116.954 9h4.636M2.41 9a2.25 2.25 0 00-.16.832V12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 01.382-.632l3.285-3.832a2.25 2.25 0 011.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0021.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 002.25 2.25z" />                 
                          </svg>
                        </div>
                        <div>
                          <a routerLink="/tasks"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            Задачи
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600">Запишете и разпределете задачи на вашия екип</p>
                        </div>
                      </div>
              
                      <div class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-100" routerLink="/projects"  routerLinkActive="active">
                        <div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-100 group-hover:bg-white nav-btn-icon" >
                          <svg class="h-6 w-6 text-gray-600 group-hover:text-indigo-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"  routerLinkActive="text-indigo-600">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" />
                          </svg>
                        </div>
                        <div>
                          <a routerLink="/projects"  (click)="clearWindow()" class="font-semibold text-gray-900 nav-btn-text">
                            Проекти
                            <span class="absolute inset-0"></span>
                          </a>
                          <p class="mt-1 text-gray-600">Опишете и следете прогреса на вашите проекти</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a> -->
              <a class="relative" *ngIf="system.ReturnMe().layout == 'CRM'">
                <button routerLink="/services" type="button" class="inline-flex items-center gap-x-1 ttext-black-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium"  routerLinkActive="bg-positive text-white">
                  <span>{{ languageService.ReturnTranslatedLabel('menu_services') }}</span>
                </button>
              </a>
              <!-- <a href="#" class="text-black-300 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium">Склад</a> -->
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <!-- Profile dropdown -->
          <div class="relative ml-3">
            <div class="main-time">{{ ReturnTime() }}</div>

            <div class="user-holder" (click)="switchProfileOn()">
              <button type="button" style="margin: 0px;height: 36px;width: 36px;" class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <img height="36px" width="36px" [src]="system.ReturnAvatar()" style="width:100%;height:100%;object-fit: cover;" class="rounded-full" alt="">
              </button>
              <span>{{ system.ReturnMe().first_name }} {{ system.ReturnMe().last_name }}</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-2" style="margin-top:10px;">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
              
            </div>
            <div [ngClass]="{ 'hidden':!profileOpen }"  (clickOutside)="onProfileOutside($event)" style="    top: 32px;
            left: 0px;" class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
              <a routerLink="/profile" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" role="menuitem" tabindex="-1" id="user-menu-item-0">{{ languageService.ReturnTranslatedLabel('label_profile') }}</a>
              <a routerLink="/settings" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" role="menuitem" tabindex="-1" id="user-menu-item-1" *ngIf="system.ReturnMe().main_user == '-1'">{{ languageService.ReturnTranslatedLabel('label_settings') }}</a>
              <span (click)="system.Logout()" class="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100" role="menuitem" tabindex="-1" id="user-menu-item-2">{{ languageService.ReturnTranslatedLabel('label_logout') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <!-- <div class="sm:hidden" id="mobile-menu">
      <div class="space-y-1 px-2 pt-2 pb-3">
        <a href="#" class="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium" aria-current="page">Dashboard</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Team</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Projects</a>

        <a href="#" class="text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium">Calendar</a>
      </div>
    </div> -->
  </nav>
  <router-outlet></router-outlet>
</div>
<!-- <div style="width:99px;height:100%;float:left;position:fixed;right:0px;" class="bg-nexa toolbox print:hidden" *ngIf="system.ReturnLogged()">
  <div class="toolbox-circle quick-add-btn">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>    
  </div>
  <div class="toolbox-circle toolbox-btn">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
      <path fill-rule="evenodd" d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z" clip-rule="evenodd" />
    </svg>    
  </div>
  <div class="toolbox-circle toolbox-btn">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
      <path fill-rule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clip-rule="evenodd" />
    </svg>    
  </div>
  <div class="toolbox-splitter"></div>
  <div class="toolbox-avatar" [ngStyle]="{ 'background-image':'url('+ system.ReturnAvatar() +')' }" *ngFor="let user of system.ReturnUsers()"></div>
</div> -->
<div class="login" *ngIf="!system.ReturnLogged()">
  <div class="login-form">
    <div class="login-logo"></div>
    <div class="error" *ngIf="error">
      <span>Грешно въведен е-мейл или парола</span>
    </div>
    <div class="login-row">
      <span>Е-мейл</span>
      <input (keyup.enter)="system.Login(loginEmail, loginPassword, loginRemember, this)" [(ngModel)]="loginEmail" type="text" type="text" placeholder="Въведете е-мейл" />
    </div>
    <div class="login-row">
      <span>Парола</span>
      <input (keyup.enter)="system.Login(loginEmail, loginPassword, loginRemember, this)" [(ngModel)]="loginPassword" type="{{ loginShowPassword }}" placeholder="Въведете парола" />
      <i (click)="switchShowPassword()" [ngClass]="{ 'show-password':loginShowPassword == 'password', 'hide-password':loginShowPassword == 'text' }" class="icon"></i>
    </div>
    <div class="addon-line">
      <div class="remember-div"> <input type="checkbox" [(ngModel)]="loginRemember" /> Запомни ме</div>
      <div class="forgot-password">Забравена парола ?</div>
    </div>
    <div class="login-btn"  (click)="system.Login(loginEmail, loginPassword, loginRemember, this)">
      Вход
    </div>
    <div class="register-line">
      Още нямате акаунт? <span class="registration-link">Регистрайте се</span>
    </div>
  </div>
</div>