import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

@Component({
  selector: 'app-add-client-group',
  templateUrl: './add-client-group.component.html',
  styleUrls: ['./add-client-group.component.css']
})
export class AddClientGroupComponent {
  client_group:any = { 'name':"", 'discount':0 };

  constructor(public languageService:LanguageServiceService,public system:SystemService, public http:HttpClient, public router:Router) {
    
  }

  AddClientGroup() {
    this.http.post("https://api.nexa.bg/client-groups", { 'client_group':this.client_group}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.router.navigate(['/client-groups']);
    });
  }

  ReturnIsAllowedToAddClientGroup(){ 
    if(this.client_group.name.length < 2) {
      return false;
    } else {
      return true;
    }
  }
}
