
<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'send-invoice'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Изпращане на оферта</h2>

            <div class="flex" *ngIf="modal.obj.contacts.length == 0">
                <span>Няма въведени контакти за този клиент</span>
            </div>

            <div class="flex" *ngIf="modal.obj.contacts.length > 0">
                <select class="w-full py-2 border">
                    <option *ngFor="let contact of modal.obj.contacts" value="{{ contact.uid }}">{{ contact.first_name }} {{ contact.last_name }} ({{ contact.position }})</option>
                </select>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Назад
                </button>	
                <button *ngIf="modal.obj.contacts.length > 0" (click)="SendDocument()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Изпрати документ
                </button>	
                <button *ngIf="modal.obj.contacts.length == 0" routerLink="/contacts" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Добави контакт
                </button>	
            </div>
        </div>

    </div>
</div>
<!-- /Modal -->

<div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md fixed right-4 top-4" *ngIf="showDone" role="alert">
    <div class="flex">
        <div class="py-1">
			<svg _ngcontent-dyl-c76="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="fill-current bg-emerald-400 text-white p-2 h-8 w-8 text-teal-500 mr-4" style="
			border-radius: 100%;">
		  		<path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clip-rule="evenodd"></path>
			</svg>
        </div>
      <div>
        <p class="font-bold">Успешно изпратена фактура</p>
        <p class="text-sm">Вашия клиент получи успешно своята фактура</p>
      </div>
    </div>
</div>


<div id="print-window" class="print-window hidden" [innerHTML]="print_template">
</div>

<div class="flex flex-col custom-min-h-screen bg-nexa print:hidden">
	<div id="title-invoice" class="max-w-full py-4 max-md">   
		<p class="text-xl font-bold mt-3 mb-5 float-left">Оферта #{{invoice.uid}}</p>
		<button type="button" id="btn-invoice" (click)="PrintPDF()" class="mt-2 text-xs float-right py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
			<path fill-rule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z" clip-rule="evenodd" />
		  </svg>
		   Разпечатване</button>
		<button type="button" id="btn-invoice" (click)="DownloadPDF()" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
			<path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
			<path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
		  </svg>
		   Свали PDF</button>
		<button type="button" id="btn-invoice"  (click)="openModal('send-invoice', { 'contacts':system.ReturnClientContacts(client.uid), 'invoice':invoice, 'selected_contact':'' })" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
			<path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
			<path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
		  </svg>
		   Изпрати</button>
		<button type="button" id="btn-invoice" routerLink="/edit-offer/{{id}}" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
			<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
			<path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
		  </svg>
		   Редкатирай</button>
		<button type="button" id="btn-invoice" (click)="goBack()" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-gray-700 hover:text-white focus:outline-none focus:ring-0 mr-2">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="ltr:mr-2 rtl:ml-2 inline-block bi bi-printer mr-2" stroke="currentColor" height="16" width="16">
				<path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
			  </svg>			  
			Назад</button>
	</div>
	<ngx-extended-pdf-viewer class="max-w-full max-md"    [textLayer]="true"
		[showOpenFileButton]="false"  [zoom]="'page-fit'"
		[showRotateButton]="false"
		[showHandToolButton]="false"
		[showPropertiesButton]="false"   [showPrintButton]="false"  [showDownloadButton]="false"   [showDrawEditor]="false"
		[showPresentationModeButton]="false" [showPagingButtons]="false" [showSecondaryToolbarButton]="false" [showSidebarButton]="false"  [sidebarVisible]="false" *ngIf="printing" src="{{ pdfSrc }}"></ngx-extended-pdf-viewer>

	<!-- <pdf-viewer *ngIf="printing" [src]="pdfSrc"
		[render-text]="true"
		[original-size]="true"
		class="max-w-full max-md"
		style="height:600px"
	></pdf-viewer> -->
	 <!-- <div class="flex-shrink max-w-full px-6 max-md mb-6">
	  <div class="p-6 bg-white rounded-lg shadow-lg">
		<div class="flex justify-between items-center pb-4 border-b border-gray-200  mb-3">
		  <div class="flex flex-col">
			<div class="text-3xl font-bold mb-1">
			  <img class="inline-block w-12 h-auto ltr:mr-2 rtl:ml-2" src="logo/{{ system.ReturnProfile().logo }}"> {{ system.ReturnProfile().company_name }}
			</div>
			<p class="text-sm">{{ system.ReturnProfile().company_address }}</p>
		  </div>
		  <div class="text-4xl uppercase font-bold">Фактура</div>
		</div>
		<div class="flex flex-row justify-between py-3">
		  <div class="flex-1" *ngIf="client_loaded">
			<p><strong>Получател/възложител:</strong><br>
				{{ client.name }}<br>
				{{ client.address }}<br>
				{{ client.mol }}<br>
			<span *ngIf="client.registered_vat != ''">{{ client.registered_vat }}</span>
			<span *ngIf="client.registered_vat == ''">{{ client.bulstat }}</span><br>
			</p>
		  </div>
		  <div class="flex-1" *ngIf="invoice_loaded">
			<div class="flex justify-between mb-2">
			  <div class="flex-1 font-semibold">Фактура №:</div><div class="flex-1 ltr:text-right rtl:text-left">{{ invoice.num }}</div>
			</div>
			<div class="flex justify-between mb-2">
			  <div class="flex-1 font-semibold">Дата на създаване:</div><div class="flex-1 ltr:text-right rtl:text-left">{{ returnFormattedDate(invoice.date_added) }}</div>
			</div>
			<div class="flex justify-between mb-2">
			  <div class="flex-1 font-semibold">Срок за плащане:</div><div class="flex-1 ltr:text-right rtl:text-left">{{ returnFormattedDate(invoice.date_added) }}</div>
			</div>
			<div class="flex justify-between mb-2">
			  <div class="flex-1 font-semibold">Статус:</div><div class="flex-1 ltr:text-right rtl:text-left">
				<div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 " *ngIf="invoice.status == '2'">
					{{ languageService.ReturnTranslatedLabel('label_paid') }}
				</div>
				<div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-orange-500 gap-x-2 bg-orange-100 " *ngIf="invoice.status == '1'">
					{{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}
				</div>
			  </div>
			</div>
			<div class="flex justify-between mb-2">
			  <div class="flex-1 font-semibold">Тип на плащане:</div><div class="flex-1 ltr:text-right rtl:text-left">
				<div class="flex items-center" *ngIf="invoice.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
				<div class="flex items-center" *ngIf="invoice.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
				<div class="flex items-center" *ngIf="invoice.type_payment == '3'">{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</div>
			  </div>
			</div>
			<div class="flex justify-between mb-2" *ngIf="invoice.bank_id != -1 && invoice.type_payment == '1' && bank_loaded">
				<div class="flex-1 font-semibold">Банка:</div><div class="flex-1 ltr:text-right rtl:text-left">
				  <div class="flex items-center">{{ invoice.bank_details.bank }}</div>
				</div>
			</div>
			<div class="flex justify-between mb-2" *ngIf="invoice.bank_id != -1 && invoice.type_payment == '1' && bank_loaded">
				<div class="flex-1 font-semibold">BIC:</div><div class="flex-1 ltr:text-right rtl:text-left">
				  <div class="flex items-center">{{ invoice.bank_details.bic }}</div>
				</div>
			</div>
			<div class="flex justify-between mb-2" *ngIf="invoice.bank_id != -1 && invoice.type_payment == '1' && bank_loaded">
				<div class="flex-1 font-semibold">IBAN:</div><div class="flex-1 ltr:text-right rtl:text-left">
				  <div class="flex items-center">{{ invoice.bank_details.iban }}</div>
				</div>
			</div>
		  </div>
		</div>
		<div class="py-4">
		  <table class="table-bordered w-full ltr:text-left rtl:text-right text-gray-600">
			<thead class="border-b">
			  <tr class="bg-gray-100">
				<th class="py-2">Продукт</th>
				<th class="text-center py-2">Мярка</th>
				<th class="text-center py-2">К-во</th>
				<th class="text-center py-2">Ед.цена</th>
				<th class="text-center py-2">Стойност</th>
			  </tr>
			</thead>
			<tbody>
			  <tr class="border-b border-gray-200" *ngFor="let item of invoice.items;let idx = index">
				<td>
				  <div class="flex flex-wrap flex-row items-center">
					<div class="self-center">
						<div class="question-mark">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 float-left mr-2">
								<path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
							  </svg>							  
						</div>
					</div>
					<div class="leading-5 flex-1 ltr:ml-2 rtl:mr-2 mb-1 mt-2">  
					  {{ item.name }}
					</div>
				  </div>
				</td>
				<td class="text-center">{{ item.unit }}</td>
				<td class="text-center">{{ item.quantity }}</td>
				<td class="text-center">{{ item.price }} {{ invoice.currency }}</td>
				<td class="text-center">{{ returnPriceWithCurrency(returnFixed((item.price * item.quantity) * (1 + (item.vat / 100)))) }}</td>
			  </tr>
			</tbody>
			<tfoot>
			  <tr class="border-b border-gray-200 py-2">
				<td colspan="3"></td>
				<td class="text-center py-2"><b>Основа</b></td>
				<td class="text-center">{{ returnPriceWithCurrency(returnStandardTotal()) }}</td>
			  </tr>
			  <tr class="border-b border-gray-200 py-2">
				<td colspan="3"></td>
				<td class="text-center py-2"><b>ДДС</b></td>
				<td class="text-center">{{ returnPriceWithCurrency(returnVatTotal()) }}</td>
			  </tr>
			  <tr class="border-b border-gray-200 py-2">
				<td colspan="3"></td>
				<td class="text-center py-2"><b>Общо с ДДС</b></td>
				<td class="text-center font-bold">{{ returnPriceWithCurrency(returnTotal()) }}</td>
			  </tr>
			</tfoot>
		  </table>
		</div>
	  </div>
	</div> -->
  </div>