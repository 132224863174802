
<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'send-invoice'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Изпращане на фактура</h2>

            <div class="flex" *ngIf="modal.obj.contacts.length == 0">
                <span>Няма въведени контакти за този клиент</span>
            </div>

            <div class="flex mb-4" *ngIf="modal.obj.contacts.length > 0">
                <select class="w-full py-2 border">
                    <option *ngFor="let contact of modal.obj.contacts" value="{{ contact.uid }}">{{ contact.first_name }} {{ contact.last_name }} ({{ contact.position }})</option>
                </select>
            </div>

			<div class="flex items-center mb-4">
                <input [(ngModel)]="modal.obj.copy" type="checkbox" id="copy" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="copy" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Копие за изпращача</label>
            </div>

			<div class="flex items-center mb-4">
                <input [(ngModel)]="modal.obj.edit_template" type="checkbox" id="edit_template" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="edit_template" class="ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">Редактиране на шаблон преди изпращане</label>
            </div>

            <angular-editor [(ngModel)]="modal.obj.template" *ngIf="modal.obj.edit_template"></angular-editor>


            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Назад
                </button>	
                <button *ngIf="modal.obj.contacts.length > 0" (click)="SendDocument()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Изпрати документ
                </button>	
                <button *ngIf="modal.obj.contacts.length == 0" routerLink="/contacts" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Добави контакт
                </button>	
            </div>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'missing-external-invoice-settings'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Липсващи настройки</h2>

            <div class="flex">
                <span>Трябва да попълните липсващите чуждестранни настройки за вашата компания, преди да може да генерирате Invoice! </span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Назад
                </button>	
                <button routerLink="/settings" [queryParams]="{'settings_go_to': '1,2' }" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Към настройки
                </button>	
            </div>
        </div>

		<div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'create-external-invoice'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Издаване на чуждестранен invoice</h2>

			<div class="flex items-center mb-4 flex-col">
				<label for="copy" class="w-full ml-2 text-sm font-medium text-gray-400 dark:text-gray-500">{{ languageService.ReturnTranslatedLabel('label_currency') }}</label>
                <select [(ngModel)]="modal.obj.currency" (change)="SwitchRate()" class="w-full py-2 border">
					<option value="{{ currency.name }}" *ngFor="let currency of system.ReturnAvailableCurrencies()">{{ currency.name }} ({{currency.label}})</option>
				</select>
				<span class="w-full text-xs text-gray-600">Курсът на деня към тази валута е - {{ this.modal.obj.current_rate }}</span>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Назад
                </button>	
                <button (click)="GenerateInvoice()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Създай
                </button>	
            </div>
        </div>

    </div>
</div>
<!-- /Modal -->

<div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md fixed right-4 top-4" *ngIf="showDone" role="alert">
    <div class="flex">
        <div class="py-1">
			<svg _ngcontent-dyl-c76="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="fill-current bg-emerald-400 text-white p-2 h-8 w-8 text-teal-500 mr-4" style="
			border-radius: 100%;">
		  		<path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clip-rule="evenodd"></path>
			</svg>
        </div>
      <div>
        <p class="font-bold">Успешно изпратена фактура</p>
        <p class="text-sm">Вашия клиент получи успешно своята фактура</p>
      </div>
    </div>
</div>

<div id="print-window" class="print-window hidden" [innerHTML]="print_template">
</div>

<div class="flex flex-col custom-min-h-screen bg-nexa print:hidden">
	<div id="title-invoice" class="max-w-full py-4 max-md">   
		<p class="text-xl font-bold mt-3 mb-5 float-left">{{ReturnInvoiceType()}} #{{invoice.num}}</p>
		<button type="button" id="btn-invoice" (click)="PrintPDF()" class="mt-2 text-xs float-right py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
			<path fill-rule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z" clip-rule="evenodd" />
		  </svg>
		   Разпечатване</button>
		<button type="button" id="btn-invoice" (click)="DownloadPDF()" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
			<path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
			<path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
		  </svg>
		   Свали PDF</button>
		<button type="button" id="btn-invoice" (click)="openModal('send-invoice', { 'contacts':system.ReturnClientContacts(client.uid), 'invoice':invoice, template:ReturnShadowCopy(this.system.ReturnTemplates())['invoices'], 'selected_contact':'' })" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
			<path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
			<path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
		  </svg>
		   Изпрати</button>
		   <button type="button" id="btn-invoice" *ngIf="invoice.external_invoice == 0" style="background: #4cc64c;border-color: #4cc64c;" (click)="openModal('create-external-invoice', { currency:system.ReturnAvailableCurrencies()[0]['name'] })" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
				<path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
				<path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clip-rule="evenodd" />
			  </svg>			  
		   Издай INVOICE</button>
		   <button type="button" id="btn-invoice" *ngIf="invoice.external_invoice == 1" (click)="ViewInvoice()"  class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
				<path d="M10.464 8.746c.227-.18.497-.311.786-.394v2.795a2.252 2.252 0 01-.786-.393c-.394-.313-.546-.681-.546-1.004 0-.323.152-.691.546-1.004zM12.75 15.662v-2.824c.347.085.664.228.921.421.427.32.579.686.579.991 0 .305-.152.671-.579.991a2.534 2.534 0 01-.921.42z" />
				<path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v.816a3.836 3.836 0 00-1.72.756c-.712.566-1.112 1.35-1.112 2.178 0 .829.4 1.612 1.113 2.178.502.4 1.102.647 1.719.756v2.978a2.536 2.536 0 01-.921-.421l-.879-.66a.75.75 0 00-.9 1.2l.879.66c.533.4 1.169.645 1.821.75V18a.75.75 0 001.5 0v-.81a4.124 4.124 0 001.821-.749c.745-.559 1.179-1.344 1.179-2.191 0-.847-.434-1.632-1.179-2.191a4.122 4.122 0 00-1.821-.75V8.354c.29.082.559.213.786.393l.415.33a.75.75 0 00.933-1.175l-.415-.33a3.836 3.836 0 00-1.719-.755V6z" clip-rule="evenodd" />
			  </svg>			  
		   Преглед INVOICE</button>
		<button type="button" id="btn-invoice" routerLink="/edit-invoice/{{id}}" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
			<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
			<path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
		  </svg>
		   Редкатирай</button>
		<button type="button" id="btn-invoice" routerLink="/invoices" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-gray-700 hover:text-white focus:outline-none focus:ring-0 mr-2">
			<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="ltr:mr-2 rtl:ml-2 inline-block bi bi-printer mr-2" stroke="currentColor" height="16" width="16">
				<path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
			  </svg>			  
			Назад</button>
	</div>
	<ngx-extended-pdf-viewer class="max-w-full max-md"    [textLayer]="true"
		[showOpenFileButton]="false"  [zoom]="'page-fit'"
		[showRotateButton]="false"
		[showHandToolButton]="false"
		[showPropertiesButton]="false"   [showPrintButton]="false"  [showDownloadButton]="false"   [showDrawEditor]="false"
		[showPresentationModeButton]="false" [showPagingButtons]="false" [showSecondaryToolbarButton]="false" [showSidebarButton]="false"  [sidebarVisible]="false" *ngIf="printing" src="{{ pdfSrc }}"></ngx-extended-pdf-viewer>
  </div>