
<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'send-invoice'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Изпращане на оферта</h2>

            <div class="flex" *ngIf="modal.obj.contacts.length == 0">
                <span>Няма въведени контакти за този клиент</span>
            </div>

            <div class="flex" *ngIf="modal.obj.contacts.length > 0">
                <select class="w-full py-2 border">
                    <option *ngFor="let contact of modal.obj.contacts" value="{{ contact.uid }}">{{ contact.first_name }} {{ contact.last_name }} ({{ contact.position }})</option>
                </select>
            </div>

            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Назад
                </button>	
                <button *ngIf="modal.obj.contacts.length > 0" (click)="SendDocument()" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Изпрати документ
                </button>	
                <button *ngIf="modal.obj.contacts.length == 0" routerLink="/contacts" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Добави контакт
                </button>	
            </div>
        </div>

    </div>
</div>
<!-- /Modal -->

<section class="min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div id="title-invoice" class="max-w-full py-4 max-md">   
        <button type="button" id="btn-invoice" routerLink="/add-invoice/order/{{ order.uid }}" class="mt-2 text-xs float-right py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
            <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM12.75 12a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V12z" clip-rule="evenodd" />
            <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
          </svg>
           Издай фактура</button>
        <button type="button" (click)="PrintPDF()" id="btn-invoice"  class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
            <path fill-rule="evenodd" d="M7.875 1.5C6.839 1.5 6 2.34 6 3.375v2.99c-.426.053-.851.11-1.274.174-1.454.218-2.476 1.483-2.476 2.917v6.294a3 3 0 003 3h.27l-.155 1.705A1.875 1.875 0 007.232 22.5h9.536a1.875 1.875 0 001.867-2.045l-.155-1.705h.27a3 3 0 003-3V9.456c0-1.434-1.022-2.7-2.476-2.917A48.716 48.716 0 0018 6.366V3.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM16.5 6.205v-2.83A.375.375 0 0016.125 3h-8.25a.375.375 0 00-.375.375v2.83a49.353 49.353 0 019 0zm-.217 8.265c.178.018.317.16.333.337l.526 5.784a.375.375 0 01-.374.409H7.232a.375.375 0 01-.374-.409l.526-5.784a.373.373 0 01.333-.337 41.741 41.741 0 018.566 0zm.967-3.97a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H18a.75.75 0 01-.75-.75V10.5zM15 9.75a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V10.5a.75.75 0 00-.75-.75H15z" clip-rule="evenodd" />
          </svg>
           Разпечатване</button>
        <button type="button" (click)="DownloadPDF()" id="btn-invoice" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
            <path d="M5.625 1.5c-1.036 0-1.875.84-1.875 1.875v17.25c0 1.035.84 1.875 1.875 1.875h12.75c1.035 0 1.875-.84 1.875-1.875V12.75A3.75 3.75 0 0016.5 9h-1.875a1.875 1.875 0 01-1.875-1.875V5.25A3.75 3.75 0 009 1.5H5.625z" />
            <path d="M12.971 1.816A5.23 5.23 0 0114.25 5.25v1.875c0 .207.168.375.375.375H16.5a5.23 5.23 0 013.434 1.279 9.768 9.768 0 00-6.963-6.963z" />
          </svg>
           Свали PDF</button>
        <button type="button" id="btn-invoice" (click)="openModal('send-invoice', { 'contacts':system.ReturnClientContacts(client.uid), 'order':order, 'selected_contact':'' })" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
          </svg>
           Изпрати</button>
        <button type="button" id="btn-invoice" routerLink="/edit-order/{{id}}" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-indigo-500 border border-indigo-500 hover:text-white hover:bg-indigo-600 hover:ring-0 hover:border-indigo-600 focus:bg-indigo-600 focus:border-indigo-600 focus:outline-none focus:ring-0"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-5 h-5 float-left mr-2">
            <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
            <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
          </svg>
           Редкатирай</button>
        <button type="button" id="btn-invoice" routerLink="/orders" class="mt-2 text-xs float-right mr-2 py-2 px-4 inline-block text-center mb-3 rounded leading-5 text-gray-100 bg-gray-700 hover:text-white focus:outline-none focus:ring-0 mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" class="ltr:mr-2 rtl:ml-2 inline-block bi bi-printer mr-2" stroke="currentColor" height="16" width="16">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
              </svg>			  
            Назад</button>
    </div>
    <div class="sm:flex sm:items-center sm:justify-between w-full p-4">
        <div class="bg-white shadow-md rounded mx-auto px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
            <div class="flex justify-between w-full">
                <h2 class="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">ПРЕГЛЕД НА ПОРЪЧКА</h2>
            </div>
            <div class="-mx-3 md:flex mb-6">
                <ol class="flex flex-row divide-x divide-gray-100 overflow-hidden rounded-lg border border-gray-100 text-sm text-gray-500 w-full">
                    <li class="flex items-center justify-center gap-4 p-8 bg-positive text-white w-1/4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 flex-shrink-0">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z" />
                        </svg>
                        <p class="leading-none">
                        <strong class="block font-medium">Входиране</strong>
                        <small class="mt-1">Поръчката е входирана</small>
                        </p>
                    </li>
                    <li class="relative flex items-center justify-center gap-4 p-8  w-1/4" [ngClass]="{ 'active-tab':order.status == 0, 'text-white':order.status >= 0, 'bg-positive':order.status >= 1 }">
                        <span class="absolute -left-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border border-gray-100 ltr:border-b-0 ltr:border-l-0 ltr:bg-white rtl:border-r-0 rtl:border-t-0 rtl:bg-gray-50 sm:block"></span>
                        <span class="absolute -right-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border border-gray-100 ltr:border-b-0 ltr:border-l-0 ltr:bg-gray-50 rtl:border-r-0 rtl:border-t-0 rtl:bg-white sm:block"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 flex-shrink-0">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                        <p class="leading-none">
                        <strong class="block font-medium">Изготвя се</strong>
                        <small class="mt-1">Поръчката се приготвя</small>
                        </p>
                    </li>
                    <li class="flex items-center justify-center gap-4 p-8  w-1/4" [ngClass]="{ 'active-tab':order.status == 1, 'text-white':order.status >= 1, 'bg-positive':order.status >= 2 }">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 flex-shrink-0">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>                                  
                        <p class="leading-none">
                        <strong class="block font-medium"> Приключена </strong>
                        <small class="mt-1">Поръчката е приключена</small>
                        </p>
                    </li>
                    <li class="relative flex items-center justify-center gap-4 p-8  w-1/4" [ngClass]="{ 'active-tab':order.status == 2, 'text-white':order.status >= 2, 'bg-positive':order.status == 2 }">
                        <span class="absolute -left-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border border-gray-100 ltr:border-b-0 ltr:border-l-0 ltr:bg-white rtl:border-r-0 rtl:border-t-0 rtl:bg-gray-50 sm:block"></span>
                        <span class="absolute -right-2 top-1/2 hidden h-4 w-4 -translate-y-1/2 rotate-45 border border-gray-100 ltr:border-b-0 ltr:border-l-0 ltr:bg-gray-50 rtl:border-r-0 rtl:border-t-0 rtl:bg-white sm:block"></span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                        </svg>                          
                        <p class="leading-none">
                        <strong class="block font-medium">Изпратена</strong>
                        <small class="mt-1">Поръчката е изпратена</small>
                        </p>
                    </li>
                </ol>
            </div>
            <div class="flex mb-8 justify-between">
                <div class="w-1/3 ml-2">
                    <span class="float-left w-full font-semibold text-gray-500">Дата на поръчка</span>
                    <span class="float-left w-full text-gray-600 mt-2">{{ order.date_added }}</span>
                </div>
                <div class="w-1/3 ml-2">
                    <span class="float-left w-full font-semibold text-gray-500">Въведена от</span>
                    <span class="float-left w-full text-gray-600 mt-2">{{ order.added_by }}</span>
                </div>
                <div class="w-1/3 ml-2">
                    <span class="float-left w-full font-semibold text-gray-500">Клиент</span>
                    <span class="float-left w-full text-gray-600 mt-2">{{ order.client_name }}</span>
                </div>
            </div>
            <div class="flex mb-8 justify-between">
                <div class="w-full ml-2">
                    <span class="float-left w-full font-semibold text-gray-500">Артикули</span>
                </div>
            </div>
            <div class="flex -mx-1 border-b py-2 items-start">
                <div class="w-20 px-1">
                    <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">#</p>
                </div>
    
                <div class="flex-1">
                    <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">Описание</p>
                </div>
    
                <div class="px-1 w-20 text-left">
                    <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">К-тво</p>
                </div>
    
    
                <div class="px-1 w-20 text-left">
                    <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">Мярка</p>
                </div>
    
                <div class="px-1 w-20 text-left">
                    <p class="text-gray-800 uppercase tracking-wide text-sm font-bold">ДДС(%)</p>
                </div>
    
    
                <div class="px-1 w-32 text-left">
                    <p class="leading-none">
                        <span class="block uppercase tracking-wide text-sm font-bold text-gray-800">Ед.цена</span>
                        <span class="font-medium text-xs text-gray-500">(Без ДДС)</span>
                    </p>
                </div>
    
                <div class="px-1 w-32 text-right">
                    <p class="leading-none">
                        <span class="block uppercase tracking-wide text-sm font-bold text-gray-800">Стойност</span>
                        <span class="font-medium text-xs text-gray-500">(С ДДС)</span>
                    </p>
                </div>
            </div>
            <div  :key="invoice.id" *ngFor="let item of order.items; let idx = index">
                <div class="flex -mx-1 py-2 border-b">
                    <div class="w-20 px-1">
                        <p class="text-gray-800 uppercase tracking-wide text-sm font-bold" style="line-height:30px;">{{ idx + 1 }}</p>
                    </div>
    
                    <div class="flex-1">
                      <p class="text-gray-800 uppercase tracking-wide text-sm font-medium" style="line-height:30px;">{{ item.name }}</p>
                    </div>
    
                    <div class="px-1 w-20 text-right">
                      <p class="text-gray-800 uppercase tracking-wide text-sm font-medium" style="line-height:30px;">{{ item.quantity }}</p>
                    </div>
    
                    <div class="px-1 w-20 text-right">
                      <p class="text-gray-800 uppercase tracking-wide text-sm font-medium" style="line-height:30px;">{{ item.unit }}</p>
                    </div>
    
                    <div class="px-1 w-20 text-right">
                      <p class="text-gray-800 uppercase tracking-wide text-sm font-medium" style="line-height:30px;"> {{ item.vat }}%</p>
                    </div>
    
                    <div class="px-1 w-32 text-right">
                      <p class="text-gray-800 uppercase tracking-wide text-sm font-medium" style="line-height:30px;">{{ item.price }} лв.</p>
                    </div>
    
                    <div class="px-1 w-32 text-right">
                      <p class="text-gray-800 uppercase tracking-wide text-sm font-medium" style="line-height:30px;">{{ returnFixed((item.price * item.quantity) * (1 + (item.vat / 100))) }} лв.</p>
                    </div>
                </div>
            </div>
            <div class="py-2 ml-auto mt-5 w-full sm:w-2/4 lg:w-2/4">
                <div class="flex justify-between mb-3">
                    <div class="text-gray-800 text-right flex-1">Основа</div>
                    <div class="text-right w-40">
                        <div class="text-gray-800 font-medium" x-html="netTotal">{{ returnStandardTotal() }} лв.</div>
                    </div>
                </div>
                <div class="flex justify-between mb-4">
                    <div class="text-sm text-gray-600 text-right flex-1">ДДС</div>
                    <div class="text-right w-40">
                        <div class="text-sm text-gray-600" x-html="totalGST">{{ returnVatTotal() }} лв.</div>
                    </div>
                </div>
            
                <div class="py-2 border-t border-b">
                    <div class="flex justify-between">
                        <div class="text-xl text-gray-600 text-right flex-1">Общо с ДДС</div>
                        <div class="text-right w-40">
                            <div class="text-xl text-gray-800 font-bold" x-html="netTotal">{{ returnTotal() }} лв.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ngx-extended-pdf-viewer style="display:none;" class="max-w-full max-md"    [textLayer]="true"
[showOpenFileButton]="false"  [zoom]="'page-fit'"
[showRotateButton]="false"
[showHandToolButton]="false"
[showPropertiesButton]="false"   [showPrintButton]="false"  [showDownloadButton]="false"   [showDrawEditor]="false"
[showPresentationModeButton]="false" [showPagingButtons]="false" [showSecondaryToolbarButton]="false" [showSidebarButton]="false"  [sidebarVisible]="false" src="{{ pdfSrc }}"></ngx-extended-pdf-viewer>
