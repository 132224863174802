import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

import * as html2pdf from 'html2pdf.js';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
declare var $;
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  type:any = "all";
  invoices:any = [];
  page:any = 1;
  limit:any = "10";
  searchTimeout:any;
  search:any = "";
  showDone:any = false;
  stats_rows:any = "";

  modal:any = { open:false, 'cntx':'', obj:{} }

  latest_invoice_num:any = -1;
  latest_proforma_num:any = -1;
  order_by:any = "`date_added` DESC, `num` DESC";
  direction:any = "DESC";

  sideModal:any = { open:false, 'cntx':'', obj:{} }

  @ViewChild('date_start') date_start: ElementRef;
  @ViewChild('date_end') date_end: ElementRef;

  available_clients:any = [];


  constructor(public languageService:LanguageServiceService,public router:Router, public route:ActivatedRoute,public system:SystemService, public http:HttpClient){
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
      this.available_clients.unshift({ 'name':'Няма назначен клиент', uid:-1 })
    });

  }
  
  switchType() { 
    this.system.GetInvoices(this.type, this.page, this.limit, this.order_by);
  }

  switchLimit() {
    this.system.GetInvoices(this.type, this.page, this.limit,  this.order_by);
  }

  SearchInvoices() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(()=>{
      this.system.SearchInvoices(this.search, 1);
    }, 500);
  }

  RemoveInvoice() {
    this.http.delete("https://api.nexa.bg/invoices/" + this.modal.obj.uid, {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetInvoices(this.type, this.page, this.limit, this.order_by);this.GetLatestNums(); this.closeModal(); });
  }

  AnullInvoice() {
    this.http.put("https://api.nexa.bg/invoices/" + this.modal.obj.uid, { 'invoice': this.modal.obj }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.system.GetInvoices(this.type, this.page, this.limit, this.order_by);this.GetLatestNums(); this.closeModal();
    });
  }
  
  ngOnInit() {
    this.system.GetInvoices(this.type, this.page, this.limit, this.order_by);
    this.GetLatestNums();
  }

  SendInvoice() {
    if(this.modal.obj.bank_id != -1) {
      this.modal.obj.bank_details = this.system.ReturnBanks().filter(bank => bank.uid == this.modal.obj.invoice.bank_id)[0];
    }
    this.http.get("https://api.nexa.bg/clients/" + this.modal.obj.invoice.client_id, {
      withCredentials: true
    }).subscribe(response_client => {
      this.http.get("https://crm.nexa.bg/templates/invoices/"+ this.system.ReturnProfile().template +".html", {responseType: 'text'})
      .subscribe(response => {

        let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.label == this.modal.obj.invoice.currency)[0];
        this.modal.obj.invoice.currency_name = my_default_currency.name;
        this.modal.obj.invoice.currency_label = my_default_currency.label;
        this.modal.obj.invoice.currency_position = my_default_currency.position;
        this.modal.obj.invoice.currency_spacing = my_default_currency.spacing;

        let items = [];
        let products_split = this.modal.obj.invoice.products.split(";");
        for(var pi = 0; pi < products_split.length - 1; pi++) {
          let product_split = products_split[pi].split("|");
          items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2], "quantity":product_split[3], "vat":parseInt(product_split[4]) })
        }
        this.modal.obj.invoice.items = items;
  
        this.getBase64ImageFromURL('https://crm.nexa.bg/logo/'+ this.system.ReturnProfile().logo).then((data)=>{
          response = response.replace("{{invoice.num}}", this.modal.obj.invoice.num);
          response = response.replace("{{invoice.logo}}", "" + data);
          response = response.replace("{{invoice.copy}}", 'ОРИГИНАЛ');
          response = response.replace("{{invoice.total_without_vat}}", this.returnPriceWithCurrency(this.modal.obj.invoice,this.returnStandardTotal(this.modal.obj.invoice)));
          response = response.replace("{{invoice.total_vat}}", this.returnPriceWithCurrency(this.modal.obj.invoice,this.returnVatTotal(this.modal.obj.invoice)));
          response = response.replace("{{invoice.total}}", this.returnPriceWithCurrency(this.modal.obj.invoice,this.returnTotal(this.modal.obj.invoice)));
          response = response.replace("{{invoice.type_payment}}", this.returnPaymentTypeString(this.modal.obj.invoice));
          response = response.replace("{{invoice.date_added}}", this.returnFormattedDate(this.modal.obj.invoice.date_added));
          response = response.replace("{{invoice.date_added}}", this.returnFormattedDate(this.modal.obj.invoice.date_added));
          response = response.replace("{{invoice.date_due}}", this.returnFormattedDate(this.modal.obj.invoice.date_due));
          response = response.replace("{{invoice.created_by}}", this.system.ReturnMe().first_name + " " + this.system.ReturnMe().last_name);
          response = response.replace("{{cipher}}", this.system.ReturnMe().code);
          response = response.replace("{{invoice.type}}", this.ReturnInvoiceType(this.modal.obj.invoice));
          response = response.replace("{{invoice.class}}", this.modal.obj.invoice.subtype);
          if(this.modal.obj.status == 0) {
            response = response.replace("{{ annulled }}", '<div class="annulled">АНУЛИРАНА</div>');
          } else {
            response = response.replace("{{ annulled }}", '');
          }
          this.modal.obj.bank_details = this.system.ReturnBanks().filter(bank => bank.uid == this.modal.obj.bank_id)[0];
          let bank_details = "";
          if(this.modal.obj.type_payment == '1') {
            bank_details += "<span>Банкови реквизити: "+ this.modal.obj.bank_details.bank +"</span>";
            bank_details += "<span>BIC: "+ this.modal.obj.bank_details.bic +"</span>";
            bank_details += "<span>IBAN: "+ this.modal.obj.bank_details.iban +"</span>";
          }
          response = response.replace("{{payment_details}}", bank_details);
    
          response = response.replaceAll("{{company.name}}", this.system.ReturnProfile().company_name);
          response = response.replace("{{company.eik}}", this.system.ReturnProfile().company_gov_num);
          response = response.replace("{{company.bulstat}}", this.system.ReturnProfile().company_vat);
          response = response.replace("{{company.address}}", this.system.ReturnProfile().company_address);
          response = response.replace("{{company.city}}", this.system.ReturnProfile().company_city);
          response = response.replaceAll("{{company.owner}}", this.system.ReturnProfile().company_owner);
          response = response.replaceAll("{{primary_color}}", this.system.ReturnProfile().primary_color);
          response = response.replaceAll("{{accent_color}}", this.system.ReturnProfile().accent_color);
          //#ffc808
          //#363a44
          response = response.replaceAll("{{client.name}}", response_client['name']);
          response = response.replace("{{client.eik}}", response_client['bulstat']);
          response = response.replace("{{client.bulstat}}", response_client['registered_vat']);
          response = response.replace("{{client.address}}", response_client['address']);
          response = response.replace("{{client.city}}", response_client['city']);
          response = response.replaceAll("{{client.owner}}", response_client['mol']);
    
          let rows = "";
          for(let i = 0; i < items.length; i++) {
            rows += "<tr>";
            rows += "<td>" + items[i].name + "</td>";
            rows += "<td>" + this.returnPriceWithCurrency(this.modal.obj.invoice,items[i].price) + "</td>";
            rows += "<td>" + items[i].quantity + "</td>";
            rows += "<td>" + items[i].unit + "</td>";
            // rows += "<td>0%</td>";
            rows += "<td>" + items[i].vat + "</td>";
            let total = this.returnFixed((items[i].price * items[i].quantity)) + "" + this.modal.obj.invoice.currency;
            rows += "<td>" + total + "</td>"
            rows += "</tr>";
          }
          response = response.replace("{{rows}}", rows);
          let sendInvoiceTemplate = this.system.ReturnTemplates()['invoices'];
          if(this.modal.obj.edit_template) { sendInvoiceTemplate = this.modal.obj.template; }

          var opt = {
            margin: 0,
            filename: this.modal.obj.invoice['client_name'] + "-" + this.modal.obj.invoice['num'] + "-" + this.modal.obj.invoice['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf',
            image: { type:'jpeg', quality:1 },
            html2canvas: { scale:4, dpi:192 },
            jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
          }  
          let recipient = "";
          if(this.modal.obj.contacts.length > 0) {
            recipient = this.modal.obj.contacts.filter(contact => contact.uid == this.modal.obj.selected_contact)[0]['mail'];
          } else {
            recipient = this.modal.obj.standard_email;
          }
          html2pdf().from(response).set(opt).output('datauristring').then( (data: Blob) => {
            this.http.post("https://api.nexa.bg/send-mail", { 'recipients':this.modal.obj.selected_recepients, 'subject':this.system.ReturnTemplates().title_invoices, "text":"", "html":sendInvoiceTemplate, "attachment":{ 'filename':this.modal.obj.invoice['client_name'] + "-" + this.modal.obj.invoice['num'] + "-" + this.modal.obj.invoice['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf', "path":data  } }, {
              withCredentials: true
            })
            .subscribe(response_mail => {
              this.showDone = true;
            });
          })
          if(this.modal.obj.copy) {
            html2pdf().from(response).set(opt).output('datauristring').then( (data: Blob) => {
              this.http.post("https://api.nexa.bg/send-mail", { 'recipients':this.modal.obj.selected_recepients, 'subject':this.system.ReturnTemplates().title_invoices, "text":"", "html":sendInvoiceTemplate, "attachment":{ 'filename':this.modal.obj.invoice['client_name'] + "-" + this.modal.obj.invoice['num'] + "-" + this.modal.obj.invoice['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf', "path":data  } }, {
                withCredentials: true
              })
              .subscribe(response_mail => {
                this.showDone = true;
              });
            })
          }
          setTimeout(()=>{
            this.closeModal();
            this.showDone = true;
            setTimeout(()=>{
              this.showDone = false;
            }, 2000);
          }, 700);
        });
      });
    });
  }

  DownloadInvoice(invoice) {

    if(invoice.bank_id != -1) {
      console.log(this.system.ReturnBanks());
      this.modal.obj.bank_details = this.system.ReturnBanks().filter(bank => bank.uid == invoice.bank_id)[0];
    }
    this.http.get("https://api.nexa.bg/clients/" + invoice.client_id, {
      withCredentials: true
    }).subscribe(response_client => {
      this.http.get("https://crm.nexa.bg/templates/invoices/"+ this.system.ReturnProfile().template +".html", {responseType: 'text'})
      .subscribe(response => {

        let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.label == invoice.currency)[0];
        invoice.currency_name = my_default_currency.name;
        invoice.currency_label = my_default_currency.label;
        invoice.currency_position = my_default_currency.position;
        invoice.currency_spacing = my_default_currency.spacing;

        let items = [];
        let products_split = invoice.products.split(";");
        for(var pi = 0; pi < products_split.length - 1; pi++) {
          let product_split = products_split[pi].split("|");
          items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), "unit":product_split[2], "quantity":product_split[3], "vat":parseInt(product_split[4]) })
        }
        invoice.items = items;
  
        this.getBase64ImageFromURL('https://crm.nexa.bg/logo/'+ this.system.ReturnProfile().logo).then((data)=>{
          response = response.replace("{{invoice.num}}", invoice.num);
          response = response.replace("{{invoice.logo}}", "" + data);
          response = response.replace("{{invoice.copy}}", 'ОРИГИНАЛ');
          response = response.replace("{{invoice.total_without_vat}}", this.returnPriceWithCurrency(invoice,this.returnStandardTotal(invoice)));
          response = response.replace("{{invoice.total_vat}}", this.returnPriceWithCurrency(invoice,this.returnVatTotal(invoice)));
          response = response.replace("{{invoice.total}}", this.returnPriceWithCurrency(invoice,this.returnTotal(invoice)));
          response = response.replace("{{invoice.type_payment}}", this.returnPaymentTypeString(invoice));
          response = response.replace("{{invoice.date_added}}", this.returnFormattedDate(invoice.date_added));
          response = response.replace("{{invoice.date_added}}", this.returnFormattedDate(invoice.date_added));
          response = response.replace("{{invoice.date_due}}", this.returnFormattedDate(invoice.date_due));
          response = response.replace("{{invoice.created_by}}",  this.system.ReturnMe().first_name + " " + this.system.ReturnMe().last_name);
          response = response.replace("{{cipher}}", this.system.ReturnMe().code);
          response = response.replace("{{invoice.type}}", this.ReturnInvoiceType(invoice));
          response = response.replace("{{invoice.class}}", invoice.subtype);
          if(invoice.status == 0) {
            response = response.replace("{{ annulled }}", '<div class="annulled">АНУЛИРАНА</div>');
          } else {
            response = response.replace("{{ annulled }}", '');
          }
          console.log(this.modal.obj.bank_details);
          let bank_details = "";
          if(invoice.type_payment == '1') {
            bank_details += "<span>Банкови реквизити: "+ this.modal.obj.bank_details.bank +"</span>";
            bank_details += "<span>BIC: "+ this.modal.obj.bank_details.bic +"</span>";
            bank_details += "<span>IBAN: "+ this.modal.obj.bank_details.iban +"</span>";
          }
          response = response.replace("{{payment_details}}", bank_details);
    
          response = response.replaceAll("{{company.name}}", this.system.ReturnProfile().company_name);
          response = response.replace("{{company.eik}}", this.system.ReturnProfile().company_gov_num);
          response = response.replace("{{company.bulstat}}", this.system.ReturnProfile().company_vat);
          response = response.replace("{{company.address}}", this.system.ReturnProfile().company_address);
          response = response.replace("{{company.city}}", this.system.ReturnProfile().company_city);
          response = response.replaceAll("{{company.owner}}", this.system.ReturnProfile().company_owner);
          response = response.replaceAll("{{primary_color}}", this.system.ReturnProfile().primary_color);
          response = response.replaceAll("{{accent_color}}", this.system.ReturnProfile().accent_color);
          //#ffc808
          //#363a44
          response = response.replaceAll("{{client.name}}", response_client['name']);
          response = response.replace("{{client.eik}}", response_client['bulstat']);
          response = response.replace("{{client.bulstat}}", response_client['registered_vat']);
          response = response.replace("{{client.address}}", response_client['address']);
          response = response.replace("{{client.city}}", response_client['city']);
          response = response.replaceAll("{{client.owner}}", response_client['mol']);
    
          let rows = "";
          for(let i = 0; i < items.length; i++) {
            rows += "<tr>";
            rows += "<td>" + items[i].name + "</td>";
            rows += "<td>" + this.returnPriceWithCurrency(invoice,items[i].price) + "</td>";
            rows += "<td>" + items[i].quantity + "</td>";
            rows += "<td>" + items[i].unit + "</td>";
            // rows += "<td>0%</td>";
            rows += "<td>" + items[i].vat + "</td>";
            //* (1 + (items[i].vat / 100))
            let total = this.returnFixed((items[i].price * items[i].quantity)) + "" + invoice.currency;
            rows += "<td>" + total + "</td>"
            rows += "</tr>";
          }
          response = response.replace("{{rows}}", rows);
          var opt = {
            margin: 0,
            filename: invoice['client_name'] + "-" + invoice['num'] + "-" + invoice['date_added'] + "-" + new Date().getHours() + "-" + new Date().getMinutes() + "-" + '.pdf',
            image: { type:'jpeg', quality:1 },
            html2canvas: { scale:4, dpi:192 },
            jsPDF: { unit:'in', format:'letter', orientation: 'portrait' }
          }  
          html2pdf().from(response).set(opt).toPdf().save();
        });
      });
    });
  }

  DownloadStats() {
    this.stats_rows = "";
    const date_st = this.date_start.nativeElement as HTMLInputElement;
    const date_en = this.date_end.nativeElement as HTMLInputElement;
    let split_date_start = date_st.value.split(".");
    // let split_date_start = this.sideModal.obj.date_start.split(".");
    let split_date_end = date_en.value.split(".");
    // let split_date_end = this.sideModal.obj.date_end.split(".");
    // let split_date_end = document.getElementById('date_end').getAttribute("value");
    let new_date_start = split_date_start[2] + "-" + split_date_start[1] + "-" + split_date_start[0];
    let new_date_end = split_date_end[2] + "-" + split_date_end[1] + "-" + split_date_end[0];
    this.http.get("https://api.nexa.bg/invoices?type=invoices&subtype=all&page=1&limit=5000&begin=" + new_date_start + "&end=" + new_date_end, {
      withCredentials: true
    })
    .subscribe(resp_invoices => {
      console.log(resp_invoices);
      let rows = "";
      this.http.get("https://crm.nexa.bg/templates/statistics/documents.html", {responseType: 'text'})
      .subscribe(response => {
        console.log(response);
        let row = "<tr>";
        for(var i = 0; i < resp_invoices['invoices'].length; i++) {
          this.LoadRowClient(resp_invoices, i, response, date_st.value, date_en.value);
        }
      });
    },(error) => { });

  }

  LoadRowClient(resp_invoices, i, doc, start, end) {
    this.http.get("https://api.nexa.bg/clients/" + resp_invoices['invoices'][i].client_id, {
      withCredentials: true
    }).subscribe(response_client => {
      let row = "";
      if(resp_invoices['invoices'][i].subtype == 'invoice') { row += "<td>Фактура</td>" } else if(resp_invoices['invoices'][i].subtype == 'proforma') { row += "<td>Проформа фактура</td>"  }
      row += "<td>"+ resp_invoices['invoices'][i]['num'] +"</td>";
      let dt_split =  resp_invoices['invoices'][i]['date_added'].split("-");
      let new_dt = dt_split[2] + "." + dt_split[1] + "." + dt_split[0] + "г.";
      row += "<td>"+ new_dt +"</td>";
      row += "<td>"+ new_dt +"</td>";
      row += "<td>"+ resp_invoices['invoices'][i]['client_name'] +"</td>";
      if(resp_invoices['invoices'][i]['type_payment'] == '1') { row += "<td>По банков път</td>"; }
      else if(resp_invoices['invoices'][i]['type_payment'] == '2') { row += "<td>Пощенски паричен превод</td>"; }
      else if(resp_invoices['invoices'][i]['type_payment'] == '3') { row += "<td>В брой</td>"; }
      if(response_client['registered_vat'] != '') {
        row += "<td>" + (parseFloat(resp_invoices['invoices'][i]['total_value']) / 1.2).toFixed(2) + " лв.</td>";
      } else {
        row += "<td>" + resp_invoices['invoices'][i]['total_value'] + "</td>";
      }
      if(response_client['registered_vat'] != '') {
        row += "<td>" + (parseFloat(resp_invoices['invoices'][i]['total_value']) * 0.2).toFixed(2) + " лв.</td>";
      } else {
        row += "<td>0</td>";
      }
      row += "<td>" + resp_invoices['invoices'][i]['total_value'] + "</td></tr>";
      this.stats_rows += row;
      if(i == resp_invoices['invoices'].length - 1) {
        doc = doc.replace("{{rows}}", this.stats_rows);
        doc = doc.replace("{{company}}", this.system.ReturnProfile().company_name);
        doc = doc.replace("{{date_start}}", start);
        doc = doc.replace("{{date_end}}", end);
        var opt = {
          margin: 0,
          filename: "Справка"+start+"-"+end+".pdf",
          image: { type:'jpeg', quality:1 },
          html2canvas: { scale:4, dpi:192 },
          jsPDF: { unit:'in', format:'a4', orientation: 'landscape' }
        }  
        html2pdf().from(doc).set(opt).toPdf().save();
        this.closeSideModal();
      }
    });
  }

  goToPage(page) { this.page = page; this.system.GetInvoices(this.type, this.page, this.limit, this.order_by); }
  NextPage() { this.page += 1; this.system.GetInvoices(this.type, this.page, this.limit, this.order_by); }
  PrevPage() { this.page -= 1; this.system.GetInvoices(this.type, this.page, this.limit, this.order_by); }
  StartPage() { this.page = 1; this.system.GetInvoices(this.type, this.page, this.limit, this.order_by); }
  LastPage() { this.page = this.system.ReturnTotalInvoicePages(); this.system.GetInvoices(this.type, this.page, this.limit, this.order_by); }

  GetLatestNums() {
    this.http.get("https://api.nexa.bg/invoices/max-num?type=invoice", {
      withCredentials: true
    })
    .subscribe(response => { this.latest_invoice_num = parseInt(response['max_num']) - 1; });

    this.http.get("https://api.nexa.bg/invoices/max-num?type=proforma", {
      withCredentials: true
    })
    .subscribe(response => { this.latest_proforma_num = parseInt(response['max_num']) - 1; });
  }

  MarkInvoicePaid(invoice) {
    invoice.status = 2;
    this.http.put("https://api.nexa.bg/invoices/" + invoice.uid, { 'invoice': invoice } , {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetInvoices(this.type, this.page, this.limit, this.order_by) });
  }

  changeInvoiceStatus(invoice, status) {
    invoice.status = status;
    this.http.put("https://api.nexa.bg/invoices/" + invoice.uid, { 'invoice': invoice } , {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetInvoices(this.type, this.page, this.limit, this.order_by) });
  }

  closeOpenStatus(invoice,ev) {
    if(invoice.allowed_close) { invoice.open_status = false; }
  }

  ViewExternalInvoice(invoice) {
    if(this.system.ReturnProfile().company_name_en != '' && this.system.ReturnProfile().company_address_en != '' && this.system.ReturnProfile().company_owner_en != '') {
      let navigationExtras: NavigationExtras = {
        queryParams: { 'id': invoice.uid },
      };
      
      // Navigate to the login page with extras
      this.router.navigate(['/print-invoice-external'], navigationExtras);
    } else {
      this.openModal('missing-external-invoice-settings', {});
    }
  }

  openStatus(invoice) {
    invoice.allowed_close = false;
    invoice.open_status = true;
    setTimeout(()=>{
      invoice.allowed_close = true;
    }, 250);
  }

  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(cntx == 'send-invoice') {
      this.http.get("https://api.nexa.bg/clients/" + obj.invoice.client_id, {
        withCredentials: true
      })
      .subscribe(resp_client => {
        if(resp_client['email'] != null && resp_client['email'] != '') {
          this.modal.obj.has_email = true;
          this.modal.obj.standard_email = resp_client['email'];
        }
        console.log(resp_client);
      },(error) => { });
      if(obj.contacts.length > 0) {
        this.modal.obj.selected_contact = obj.contacts[0].uid;
      }
    }
  }
  closeModal() { this.modal.open = false; }

  openInvoiceDropdown(invoice) {
    invoice.show = true;
    invoice.allowedClose = false;
    setTimeout(()=> { invoice.allowedClose = true; }, 250);
  }
  onInvoiceDropDownClose(ev, invoice) {
    if(invoice.allowedClose) {
      invoice.show = false;
    }
  }
  returnDateForSend(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "" + date_reorder[1] + "" + date_reorder[0];
  }
  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }
  returnIsRemoveAllowed(invoice) {
    if(invoice.type == 'proforma') {
      if(invoice.num == this.latest_proforma_num) { return true; } else { return false; }
    } else {
      if(invoice.num == this.latest_invoice_num) { return true; } else { return false; }
    }
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj };
    if(cntx == 'add-contact') {
      this.modal.open = false;
    }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
      if(cntx == 'stats') {
          $( "#date_start" ).datepicker({ dateFormat: 'dd.mm.yy' });
          $( "#date_end" ).datepicker({ dateFormat: 'dd.mm.yy' });
      }
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }

  SwitchOrder(type) {
    if(this.order_by.indexOf(type) > -1) {
      console.log("NEW ORDER");
      if(this.direction == 'DESC') { this.direction = 'ASC'; console.log("ASC"); }
      else { this.direction = 'DESC'; console.log("DESC"); }
    }
    if(type == 'num') { this.order_by = '`date_added` '+ this.direction +', `num` ' + this.direction; }
    else if(type == 'total_value') {
      this.order_by = 'CONVERT(float, total_value) '+ this.direction;; 
    }
    else {
      this.order_by = '`'+type+'` ' + this.direction; 
    }
    this.system.GetInvoices(this.type, this.page, this.limit, this.order_by);
  }

  GetOrder() {
    if(this.order_by.indexOf('num') > -1) { return "num"; }
    else if(this.order_by.indexOf('client_name') > -1) { return "client_name"; }
    else if(this.order_by.indexOf('status') > -1) { return "status"; }
    else if(this.order_by.indexOf('total_value') > -1) { return "total_value"; }
    else if(this.order_by.indexOf('date_added') > -1) { return "date_added"; }
    else if(this.order_by.indexOf('type_payment') > -1) { return "type_payment"; }
    else { return "num"; }
  }

  AddContact() {
    this.http.post("https://api.nexa.bg/contacts", { 'contact':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.closeSideModal();
      this.system.GetContacts(this.page, this.limit);
      setTimeout(()=>{
        this.openModal('send-invoice', this.sideModal.obj.old_obj);
        this.modal.obj.contacts = this.system.ReturnClientContacts(this.modal.obj.invoice.client_id);
      }, 250);
    });
  }

  SwitchSelectedRecepient(email) {
    if(this.modal.obj.selected_recepients.filter(recepient => recepient == email).length > 0) { 
      for(let ri = this.modal.obj.selected_recepients.length; ri > -1; ri--) {
        if(this.modal.obj.selected_recepients[ri] == email) {
          this.modal.obj.selected_recepients.splice(ri, 1);
        }
      }
    } else { 
      this.modal.obj.selected_recepients.push(email);
    }
  }

  returnPriceWithCurrency(invoice,price) {
    let str = '';
    if(invoice.currency_position == 'front') {
      str += invoice.currency_label;
      if(invoice.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(invoice.currency_spacing == 1) { str += ' ' }
      str += invoice.currency_label;
    }
    return str;
  }

  ReturnPriceWithCurrencyGeneralList(invoice) {
    let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.label == invoice.currency)[0];
    invoice.currency_name = my_default_currency.name;
    invoice.currency_label = my_default_currency.label;
    invoice.currency_position = my_default_currency.position;
    invoice.currency_spacing = my_default_currency.spacing;

    let str = '';
    if(invoice.currency_position == 'front') {
      str += invoice.currency_label;
      if(invoice.currency_spacing == 1) { str += ' '; }
      str += this.returnTotal(invoice);
    } else {
      str += this.returnTotal(invoice);
      if(invoice.currency_spacing == 1) { str += ' ' }
      str += invoice.currency_label;
    }
    return str;
  }

  returnStandardTotal(invoice) {
    let total = 0.00;
    for(let i = 0; i < invoice.items.length; i++) {
      total += invoice.items[i].price * invoice.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal(invoice) {
    let total = 0.00;
    for(let i = 0; i < invoice.items.length; i++) {
      total += (invoice.items[i].price * invoice.items[i].quantity) * ( invoice.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal(invoice) {
    return (parseFloat(this.returnStandardTotal(invoice)) + parseFloat(this.returnVatTotal(invoice))).toFixed(2);
  }

  returnPaymentTypeString(invoice) {
    if(invoice.type_payment == '1') { return this.languageService.ReturnTranslatedLabel('label_payment_bank'); }
    else if(invoice.type_payment == '2') { return this.languageService.ReturnTranslatedLabel('label_payment_post'); }
    else if(invoice.type_payment == '3') { return this.languageService.ReturnTranslatedLabel('label_payment_cash'); }
  }

  ReturnInvoiceType(invoice) {
    if(invoice.subtype == 'invoice') { return this.languageService.ReturnTranslatedLabel('label_invoice'); }
    else { return this.languageService.ReturnTranslatedLabel('label_proforma'); }
  }

  ReturnShadowCopy(obj) { return JSON.parse(JSON.stringify(obj)); }

  returnFixed(num) { return num.toFixed(2); }

  ReturnIsContactAddable() {
    if(this.sideModal.obj.first_name != '' && this.sideModal.obj.last_name != '') {
      return false;
    } else {
      return true;
    }
  }
  ReturnFirstLetters(first_name, last_name) {
    let new_str = "";
    new_str += first_name.charAt(0) + "" + last_name.charAt(0);
    return new_str;
  }
  ReturnIsMarkedAsRecepient(email) {
    if(this.modal.obj.selected_recepients.filter(recepient => recepient == email).length > 0) { return true; } else { return false; }
  }
}
