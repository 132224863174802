import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';

@Component({
  selector: 'app-add-invoice-addon',
  templateUrl: './add-invoice-addon.component.html',
  styleUrls: ['./add-invoice-addon.component.css']
})
export class AddInvoiceAddonComponent {
  modal:any = { open:false, cntx:'', obj:{} }
  invoice:any = { invoice_num:3000000001, attached_invoice:30000000001, type:'invoice', payment_type:'2', date_added:'00.00.0000', items:[    { id:1, name:"", quantity:0, vat:20, unit:'бр.', price:0.00 }], client_id:-1, client_name:"", client_mol:"", client_address:""  }
  available_clients:any = [
    { client_id:1, client_name:"Аксион Солюшънс ЕООД", client_mol:"Тодор Тодоров", client_address:"Бул.Освобождение 93, блок 193, вх.В, ет.2, офис 4"  },
    { client_id:2, client_name:"Темпекс ЕООД", client_mol:"Атанас Лозанов", client_address:"улица Георги Данчов 16"  }
  ]

  constructor(public languageService:LanguageServiceService,private http:HttpClient, public router:Router) {
    let dt = new Date();
    this.invoice.date_added = this.returnPaddedZero(dt.getDate(), 2) + "." + this.returnPaddedZero(dt.getMonth() + 1, 2) + "." + dt.getFullYear();
  
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      console.log(response);
      this.available_clients = response['clients'];
    });

    this.http.get("https://api.nexa.bg/invoices/max-num?subtype=invoice", {
      withCredentials: true
    })
    .subscribe(response => {
      this.invoice.invoice_num = response['max_num'];
      this.invoice.attached_invoice = response['max_num'] - 1;
    });
  }

  OpenModal(cntx, obj) {
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }
  }

  changedInvoiceType() {
    this.http.get("https://api.nexa.bg/invoices/max-num?subtype=" + this.invoice.type, {
      withCredentials: true
    })
    .subscribe(response => {
      this.invoice.invoice_num = response['max_num'];
    });
  }

  changedRow(idx) {
    if(idx == this.invoice.items.length - 1) {
      if(this.invoice.items[idx].name.length != 0 || this.invoice.items[idx].quantity != 0 || this.invoice.items[idx].price != 0) {
        this.invoice.items.push({ id:1, name:"", quantity:0, vat:20, unit:'бр.', price:0.00 });
      } 
    }
  }

  AddInvoice() {
    let products = "";
    for(var pi = 0; pi < this.invoice.items.length; pi++) {
      products += this.invoice.items[pi].name + "|" + this.invoice.items[pi].price + "|" + this.invoice.items[pi].quantity + "|" + this.invoice.items[pi].vat + ";";
    }
    console.log(products);
    let split_date_add = this.invoice.date_added.split(".");
    let dt_added = new Date(split_date_add[2] + "-" + split_date_add[1] + "-" + split_date_add[0]);
    let reformed_dt_added = dt_added.getFullYear() + "-" + this.returnPaddedZero(dt_added.getMonth() + 1, 2) + "-" + this.returnPaddedZero(dt_added.getDate(), 2);
    this.http.post("https://api.nexa.bg/invoices", { 'invoice':{ num:this.invoice.invoice_num,'type':'invoices', 'subtype':this.invoice.type, 'date_added':reformed_dt_added, 'date_paid':'', 
    'date_due':'', due_days:'7', 'client_id':this.invoice.client_id, "client_name":this.invoice.client_name, total_value:this.returnTotal(), type_payment:this.invoice.payment_type, bank_id:1, 
    status:1, 'products':products, "automatic_template":1, 'alert_closing':0, 'alert_late':0, 'added_by':2, 'comment':'', 'currency':'лв.'}}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.router.navigate(['/invoices']);
    });
  }

  AddItemToInvoice() {
    this.invoice.items.push({ 'name':this.modal.obj.name, 'type':this.modal.obj.type, 'quantity':this.modal.obj.quantity, 'price':this.modal.obj.price, 'vat':this.modal.obj.vat });
    this.modal.open = false;
  }
  removeItemFromInvoice(idx) {
    this.invoice.items.splice(idx, 1);
  }

  selectedClient() {
    let client = this.available_clients.filter(client => client.uid == this.invoice.client_id)[0];
    this.invoice.client_mol = client.mol;
    this.invoice.client_address = client.address;
    this.invoice.client_name = client.name;
  }

  returnFixed(num) { return num.toFixed(2); }
  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.invoice.items.length; i++) {
      total += this.invoice.items[i].price * this.invoice.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.invoice.items.length; i++) {
      total += (this.invoice.items[i].price * this.invoice.items[i].quantity) * ( this.invoice.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal())).toFixed(2);
  }
}
