<section class="min-h-screen custom-min-h-screen bg-nexa bg-nexa text-gray-800">
    <div class="flex flex-col p-4" style="padding-top:0px;padding-bottom:0px">
        <div class="float-left w-full p-2 mt-4">
            <div class="relative float-left font-bold text-black" style="font-size:24px;">{{ languageService.ReturnTranslatedLabel('menu_expenses') }}</div>

            <div class="flex items-center float-right gap-x-3">

                <button class="flex items-center justify-center w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-positive rounded-lg shrink-0 sm:w-auto gap-x-2 hover:bg-blue-600" routerLink="/add-service">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>                      
    
                    <span>{{ languageService.ReturnTranslatedLabel('add_expense') }}</span>
                </button>
            </div>
        </div>
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-2">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="p-3 bg-white md:rounded-lg h-14 border border-gray-200">
                    <div class="float-left" style="width:240px;">
                        <input type="text" class="h-8 p-2 font-semibold text-xs text-black rounded-lg search-field" placeholder="Търсене..." />
                        <div class="btn float-right rounded-lg bg-positive text-white text-center search-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>                              
                        </div>
                    </div>
                    <span class="float-right">
                        <div class="float-left ml-2">
                            <span class="mr-2 text-xs font-bold text-gray-600">{{ languageService.ReturnTranslatedLabel('label_results_per_page') }}</span>
                            <select (change)="switchLimit()" [(ngModel)]="limit" class="h-8 p-2 font-semibold text-xs text-black rounded-lg">
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select>
                        </div>
                        <span class="page-btn" (click)="StartPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentExpensesPage() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5" />
                            </svg>                                                      
                        </span>
                        <span class="page-btn" (click)="PrevPage()"  [ngClass]="{ 'disabled':system.ReturnCurrentExpensesPage() < 2 }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                              </svg>                              
                        </span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnCurrentExpensesPage() > 1" (click)="goToPage( system.ReturnCurrentExpensesPage() - 1)">{{ system.ReturnCurrentExpensesPage() - 1 }}</span>
                        <span class="page-btn bg-positive text-white hover:bg-positive hover:text-white" (click)="goToPage( system.ReturnCurrentExpensesPage())">{{ system.ReturnCurrentExpensesPage() }}</span>
                        <span class="page-btn hover:bg-positive hover:text-white" *ngIf="system.ReturnTotalInvoicePages() > system.ReturnCurrentExpensesPage()" (click)="goToPage( system.ReturnCurrentExpensesPage() + 1)">{{ system.ReturnTotalExpensesPage() + 1 }}</span>
                        <span class="page-btn" (click)="NextPage()" [ngClass]="{ 'disabled':system.ReturnCurrentExpensesPage() == system.ReturnTotalExpensesPage() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                        <span class="page-btn" (click)="LastPage()" [ngClass]="{ 'disabled':system.ReturnCurrentExpensesPage() == system.ReturnTotalExpensesPage() }">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>                              
                        </span>
                    </span>
                </div>
            </div>
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mt-2" style="padding-top:0px;padding-bottom:0px">
                <div class="overflow-hidden border border-gray-200  md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200 ">
                        <thead class="bg-white">
                            <tr class="text-sm font-bold text-black">
                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    <button class="flex items-center gap-x-3 focus:outline-none">
                                        <span>Описание</span>

                                        <svg class="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" stroke-width="0.1" />
                                            <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" stroke-width="0.3" />
                                        </svg>
                                    </button>
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    Доставчик
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">Информация</th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    Плащане
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    {{ languageService.ReturnTranslatedLabel('label_price') }}
                                </th>

                                <th scope="col" class="px-4 py-3.5 text-left rtl:text-right">
                                    Състояние
                                </th>

                                <th scope="col" class="relative py-3.5 px-4">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200  ">
                            <tr *ngIf="system.ReturnExpenses().length == 0">
                                <td class="text-center p-4 bg-gray-100" colspan="6">Няма въведени разходи</td>
                            </tr>
                            <tr *ngFor="let expense of system.ReturnExpenses()">
                                <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                    {{ expense.description }}
                                </td>

                                <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                    {{ expense.supplier_name }}
                                </td>
                                <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                    <div>
                                        <h2 class="font-medium text-gray-800  ">Прието на - {{ expense.date_added }}</h2>
                                        <p class="text-sm font-normal text-gray-600 ">Срок за плащане - {{ expense.date_due }}</p>
                                    </div>
                                </td>
                                <td class="px-4 py-2 text-sm font-medium whitespace-nowrap">
                                    <div class="flex items-center" *ngIf="expense.type_payment == '1'">{{ languageService.ReturnTranslatedLabel('label_payment_bank') }}</div>
                                    <div class="flex items-center" *ngIf="expense.type_payment == '2'">{{ languageService.ReturnTranslatedLabel('label_payment_post') }}</div>
                                    <div class="flex items-center" *ngIf="expense.type_payment == '3'">{{ languageService.ReturnTranslatedLabel('label_payment_cash') }}</div>
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    {{ expense.price }} {{ expense.currency }}
                                </td>
                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-emerald-500 gap-x-2 bg-emerald-100/60 " *ngIf="expense.status == '2'">
                                        {{ languageService.ReturnTranslatedLabel('label_paid') }}
                                    </div>
                                    <div style="text-transform: uppercase;font-size: 10px;" class="inline px-3 py-1 text-sm font-bold rounded-full text-orange-500 gap-x-2 bg-orange-100 " *ngIf="expense.status == '1'">
                                        {{ languageService.ReturnTranslatedLabel('label_waiting_payment') }}
                                    </div>
                                </td>


                                <td class="px-4 py-2 text-sm whitespace-nowrap">
                                    <button class="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg  hover:bg-gray-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>