<!-- Modal -->
<div *ngIf="modal.open"  style=" background-color: rgba(0, 0, 0, 0.8)" class="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" x-show.transition.opacity="openModal">
    <div class="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
        <div class="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer"
            (click)="modal.open = false">
            <svg class="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
        </div>

        <div class="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8" *ngIf="modal.cntx == 'change-password'">
            
            <h2 class="font-bold text-2xl mb-6 text-gray-800 border-b pb-2">Смяна на парола</h2>

            <div class="flex flex-col mb-4 password-row">
                <span class="text-sm mb-2 font-semibold">Стара парола</span>
                <input class="w-full border p-2" (focusout)="CheckPasswordCorrect()"  type="{{modal.obj.old_password_show}}" [(ngModel)]="modal.obj.old_password" />
                <i (click)="switchOldShowPassword()" [ngClass]="{ 'show-password':modal.obj.old_password_show == 'password', 'hide-password':modal.obj.old_password_show == 'text' }" class="icon"></i>
                <div class="input-error" *ngIf="modal.obj.show_old_password_error">Въведената парола е грешна</div>
            </div>

            <div class="flex flex-col mb-4 password-row">
                <span class="text-sm mb-2 font-semibold">Нова парола</span>
                <input class="w-full border p-2" type="{{modal.obj.new_password_show}}" [(ngModel)]="modal.obj.new_password" />
                <i (click)="switchNewShowPassword()" [ngClass]="{ 'show-password':modal.obj.new_password_show == 'password', 'hide-password':modal.obj.new_password_show == 'text' }" class="icon"></i>
            </div>


            <div class="mt-8 text-right">
                <button type="button" (click)="modal.open = false" class="bg-white hover:bg-gray-100 text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow-sm mr-2">
                    Отказ
                </button>	
                <button (click)="SaveNewPassword()" [ngClass]="{ 'disabled':modal.obj.old_password.length < 3 || modal.obj.new_password.length < 3 || !ReturnOldPasswordIsCorrect() }" type="button" class="bg-gray-800 hover:bg-gray-700 text-white font-semibold py-2 px-4 border border-gray-700 rounded shadow-sm">
                    Потвърди
                </button>	
            </div>
        </div>
    </div>
</div>
<!-- /Modal -->


<div class="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md fixed right-4 top-4" *ngIf="showDone" role="alert">
    <div class="flex">
        <div class="py-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="fill-current h-8 w-8 text-teal-500 mr-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
        </div>
      <div>
        <p class="font-bold">{{ languageService.ReturnTranslatedLabel('label_settings_success') }}</p>
        <p class="text-sm">{{ languageService.ReturnTranslatedLabel('label_settings_success_text') }}</p>
      </div>
    </div>
</div>

<div class="flex min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="profile-window">
        <div class="main-information-holder">
            <div class="avatar">
                <span>ПТ</span>
                <img [src]="avatar" (error)="ReturnDefaultAvatar()" (click)="fileUpload.click()" />
                <div class="small-btn"  (click)="fileUpload.click()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                        <path fill-rule="evenodd" d="M20.599 1.5c-.376 0-.743.111-1.055.32l-5.08 3.385a18.747 18.747 0 00-3.471 2.987 10.04 10.04 0 014.815 4.815 18.748 18.748 0 002.987-3.472l3.386-5.079A1.902 1.902 0 0020.599 1.5zm-8.3 14.025a18.76 18.76 0 001.896-1.207 8.026 8.026 0 00-4.513-4.513A18.75 18.75 0 008.475 11.7l-.278.5a5.26 5.26 0 013.601 3.602l.502-.278zM6.75 13.5A3.75 3.75 0 003 17.25a1.5 1.5 0 01-1.601 1.497.75.75 0 00-.7 1.123 5.25 5.25 0 009.8-2.62 3.75 3.75 0 00-3.75-3.75z" clip-rule="evenodd" />
                    </svg>                      
                </div>
                <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
            </div>
            <div class="avatar-info">
                <div class="name">{{ originalProfile.first_name }} {{ originalProfile.last_name }}</div>
                <div class="role" *ngIf="originalProfile.role == 0">Администратор</div>
                <div class="role" *ngIf="originalProfile.role == 1">Потребител</div>
            </div>
        </div>
        <div class="main-information-fields flex flex-row">
            <div class="flex flex-col w-2/4 mr-4">
                <span class="w-full text-black text-sm font-semibold text-gray-600 mb-2">ИМЕ</span>
                <input class="border p-2 rounded-md" type="text" [(ngModel)]="currentProfile.first_name"  />
            </div>
            <div class="flex flex-col w-2/4">
                <span class="w-full text-black text-sm font-semibold text-gray-600 mb-2">ФАМИЛИЯ</span>
                <input class="border p-2 rounded-md" type="text" [(ngModel)]="currentProfile.last_name" />
            </div>
        </div>
        <div class="main-information-fields flex flex-row">
            <div class="flex flex-col w-2/4 mr-4">
                <span class="w-full text-black text-sm font-semibold text-gray-600 mb-2">Е-МЕЙЛ</span>
                <input class="border p-2 rounded-md" type="text" readonly disabled [(ngModel)]="currentProfile.username"  />
            </div>
            <div class="flex flex-col w-2/4">
                <span class="w-full text-black text-sm font-semibold text-gray-600 mb-2">ШИФЪР</span>
                <input class="border p-2 rounded-md" type="text" [(ngModel)]="currentProfile.code"  />
            </div>
        </div>
        <div class="save-btn"  [ngClass]="{ 'disabled':!ReturnIsProfileChanged() }" style="margin-left:29%!important;" (click)="SaveProfile()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-left">
            <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clip-rule="evenodd" />
          </svg>
          <span style="float: left;margin-left: 5px;margin-top: 2px;">Запази промени</span></div>
          <div class="save-btn" (click)="OpenModal('change-password', { old_password:'', new_password:'', old_password_show:'password', new_password_show:'password' })">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 float-left">
            <path fill-rule="evenodd" d="M15.75 1.5a6.75 6.75 0 00-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 00-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 00.75-.75v-1.5h1.5A.75.75 0 009 19.5V18h1.5a.75.75 0 00.53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1015.75 1.5zm0 3a.75.75 0 000 1.5A2.25 2.25 0 0118 8.25a.75.75 0 001.5 0 3.75 3.75 0 00-3.75-3.75z" clip-rule="evenodd" />
          </svg>
          <span style="float: left;margin-left: 5px;margin-top: 2px;">Смяна на парола</span></div>
    </div>
</div>