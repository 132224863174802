import { Component } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemService } from '../system.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-offer',
  templateUrl: './edit-offer.component.html',
  styleUrls: ['./edit-offer.component.css']
})
export class EditOfferComponent {
  id:any = -1;
  loaded:any = false;
  items:any = [];
  available_clients = [];
  results:any = [];
  offer:any = { client_id:-1, client_name:"", date_added:'00.00.0000', items:[{ id:1, name:"", quantity:1, vat:20, unit:'бр.', price:0.00 }], 'short_description':''};
  sideModal:any = { open:false, 'cntx':'', 'obj':{} };



  constructor(public languageService:LanguageServiceService, public location:Location, private http:HttpClient, public router:Router, public route:ActivatedRoute, public system:SystemService) {
    let dt = new Date();
    this.offer.date_added = this.returnPaddedZero(dt.getDate(), 2) + "." + this.returnPaddedZero(dt.getMonth() + 1, 2) + "." + dt.getFullYear();
  

    let id = this.route.snapshot.params['id'];
    this.id = id;
    if(id != -1) {
      this.http.get("https://api.nexa.bg/offers/" + id, {
        withCredentials: true
      })
      .subscribe(response => {
        this.loaded = true;
        let order = response;
        let items = [];
        let products_split = order['products'].split(";");
        for(var pi = 0; pi < products_split.length - 1; pi++) {
          let product_split = products_split[pi].split("|");
          items.push({ "name":product_split[0], "price":parseFloat(product_split[1]), 'unit':product_split[2], "quantity":product_split[3], "vat":parseInt(product_split[4]) })
        }
        this.offer.client_id = parseInt(order['client_id']);
        this.offer.client_name = order['client_name'];
        this.offer.items = items;
        this.offer.status = order['status'];
        this.offer.description = order['description'];
        this.offer.added_by = order['added_by'];
        this.offer.currency = order['currency'];
        this.selectedClient();

        let split_date_add = order['date_added'].split("T")[0].split("-");
        this.offer.date_added = split_date_add[2] + "." + split_date_add[1] + "." + split_date_add[0];

        let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.label == this.offer.currency)[0];
        this.offer.currency_name = my_default_currency.name;
        this.offer.currency_label = my_default_currency.label;
        this.offer.currency_position = my_default_currency.position;
        this.offer.currency_spacing = my_default_currency.spacing;
      });
    }
  
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });
  }

  changedRow(idx, item) {
    this.ChangedName(item);
    if(idx == this.offer.items.length - 1) {
      if(this.offer.items[idx].name.length != 0 || this.offer.items[idx].quantity != 0 || this.offer.items[idx].price != 0) {
        this.offer.items.push({ id:1, name:"", quantity:1, vat:20, unit:'бр.', price:0.00 });
      } 
    }
  }


  clearSelect(item) {
    setTimeout(() => {
      item.selecting = false;
    }, 250);
  }

  focusSelect(item) {
    item.selecting = true;
    this.results = [];
  }

  selectResult(item, result) {
    item.name = result.name;
    item.name += ' - ' + result.description;
    item.price = result.price;
    item.vat = result.vat;
    if(item.quantity == 0) { item.quantity = 1; }
    item.selecting = false;
  }


  selectedClient() {
    let client = this.available_clients.filter(client => client.uid == this.offer.client_id)[0];
    this.http.get("https://api.nexa.bg/clients/" + this.offer.client_id, {
      withCredentials: true
    })
    .subscribe(response => {
      this.offer.client_name = response['name'];
      this.offer.client_data = response;
      this.offer.going_to = response['mol'];
    });
  }

  EditOffer() {
    let split_date_add = this.offer.date_added.split(".");
    let dt_added = new Date(split_date_add[2] + "-" + split_date_add[1] + "-" + split_date_add[0]);
    let products = "";
    for(var pi = 0; pi < this.offer.items.length; pi++) {
      if(this.offer.items[pi].name.length > 0 || this.offer.items[pi].price != 0) {
        products += this.offer.items[pi].name + "|" + this.offer.items[pi].price  + "|" + this.offer.items[pi].unit + "|" + this.offer.items[pi].quantity + "|" + this.offer.items[pi].vat + ";";
      }
    }
    this.offer.total = this.returnTotal();
    this.offer.products = products;
    let reformed_dt_added = dt_added.getFullYear() + "-" + this.returnPaddedZero(dt_added.getMonth() + 1, 2) + "-" + this.returnPaddedZero(dt_added.getDate(), 2);
    this.offer.date_added = reformed_dt_added;
    this.http.put("https://api.nexa.bg/offers/" + this.id, { 'offer':this.offer}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.router.navigate(['/offers']);
    });
  }

  ChangedName(item) {
    this.http.post("https://api.nexa.bg/services/search/name", { 'name':item.name }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.results = response['services'];
    });
  }


  changedCurrency() {
    let selected_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.name == this.offer.currency_name)[0];
    this.offer.currency_label = selected_currency.label;
    this.offer.currency_position = selected_currency.position;
    this.offer.currency_spacing = selected_currency.spacing;
  }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }

  goBack() {
    this.location.back()
  }

  
  AddClient() {
    this.http.post("https://api.nexa.bg/clients", { 'client':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetAvailableClients();
      this.closeSideModal();
    });
  }

  GetAvailableClients() {
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });
  }




  removeItemFromOffer(idx) {
    this.offer.items.splice(idx, 1);
  }

    
  ReturnIsAllowedToAddClient(){ 
    if(this.sideModal.obj.type == 'company') {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    } else {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    }
  }



  returnFixed(num) { return num.toFixed(2); }
  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.offer.items.length; i++) {
      total += this.offer.items[i].price * this.offer.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.offer.items.length; i++) {
      total += (this.offer.items[i].price * this.offer.items[i].quantity) * ( this.offer.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal())).toFixed(2);
  }

  returnPriceWithCurrency(price) {
    let str = '';
    if(this.offer.currency_position == 'front') {
      str += this.offer.currency_label;
      if(this.offer.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(this.offer.currency_spacing == 1) { str += ' ' }
      str += this.offer.currency_label;
    }
    return str;
  }
}
