import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageServiceService {
  currentLang:any = 'bg';
  language:any = {};
  langObject = {};
  langEnglishObject = {};

  constructor(private http:HttpClient) { }

  LoadLanguage(lang) {
    this.currentLang = lang.toLowerCase();
    this.http.get("language/" + lang.toLowerCase() + ".json").subscribe(
    response => { this.langObject = response; },
    error => { console.log(error);  });

    this.http.get("language/en.json").subscribe(
      response => { this.langEnglishObject = response; },
      error => { console.log(error);  });
  }

  ReturnTranslatedLabel(label) { if(this.langObject[label] !== undefined) { return this.langObject[label] } else { return "Зареждане..."; } }
  ReturnEnglishLabel(label) { if(this.langEnglishObject[label] !== undefined) { return this.langEnglishObject[label] } else { return "Loading..."; } }
  ReturnCurrentLang() { return this.currentLang; }
}
