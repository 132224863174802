
<div class="flex min-h-screen custom-min-h-screen bg-nexa text-gray-800">
    <div class="p-4 w-full">
	<div class="container mx-auto py-6 px-4 bg-white">
		<div class="flex justify-between">
			<h2 class="text-2xl font-bold mb-6 pb-2 tracking-wider uppercase">{{ languageService.ReturnTranslatedLabel('title_add_service') }}</h2>
			<div>
				<div class="relative mr-4 inline-block">
					<div routerLink="/services" class="text-white cursor-pointer w-10 h-10 rounded-full bg-gray-400 hover:bg-gray-500 inline-flex items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
						  </svg>						  
					</div>
				</div>
				<div class="relative mr-4 inline-block">
					<div (click)="AddService()" class="text-white cursor-pointer w-10 h-10 rounded-full bg-green-400 hover:bg-green-500 inline-flex items-center justify-center">
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
							<rect x="0" y="0" width="24" height="24" stroke="none"></rect>
							<path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
						</svg>	  
					</div>
				</div>
			</div>
		</div>

		<div class="flex mb-8 justify-between">
            <div class="w-full ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_service_name') }} <span class="text-red-500">*</span></label>
					<input [(ngModel)]="service.name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 ng-pristine ng-valid ng-touched w-full" id="inline-full-name" placeholder="Въведете име на услуга" type="text">
				</div>
			</div>
        </div>
        <div class="flex mb-8 justify-between">
            <div class="w-full ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_service_description') }}</label>
                    <input [(ngModel)]="service.description" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" placeholder="Въведете описание на услуга" type="text">
				</div>
			</div>
		</div>
        <div class="flex mb-8 justify-between">
            <div class="w-1/5 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_metric_long') }}</label>
                    <input [(ngModel)]="service.metric" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>
            <div class="w-1/5 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_single_price') }}</label>
                    <input [(ngModel)]="service.price" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="number" step="0.01">
				</div>
			</div>
            <div class="w-1/5 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_vat') }} (%)</label>
                    <input [(ngModel)]="service.vat" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="number">
				</div>
			</div>
            <div class="w-1/5 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_barcode') }}</label>
                    <input [(ngModel)]="service.barcode" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>
            <div class="w-1/5 ml-4">
				<div class="mb-2 flex flex-col items-center">
					<label class="w-full text-gray-800 block font-bold text-sm uppercase tracking-wide">{{ languageService.ReturnTranslatedLabel('label_product_code') }}</label>
                    <input [(ngModel)]="service.product_code" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5  ng-pristine ng-valid ng-touched w-full" id="inline-full-name" type="text">
				</div>
			</div>
		</div>
	</div>
</div>

</div>