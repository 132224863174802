import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddClientGroupComponent } from './add-client-group/add-client-group.component';
import { AddClientComponent } from './add-client/add-client.component';
import { AddInvoiceAddonComponent } from './add-invoice-addon/add-invoice-addon.component';
import { AddInvoiceComponent } from './add-invoice/add-invoice.component';
import { AddOfferComponent } from './add-offer/add-offer.component';
import { AddOrderComponent } from './add-order/add-order.component';
import { AddServiceComponent } from './add-service/add-service.component';
import { ClientGroupsComponent } from './client-groups/client-groups.component';
import { ClientsComponent } from './clients/clients.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { HomeComponent } from './home/home.component';
import { InvoiceAddonsComponent } from './invoice-addons/invoice-addons.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { OffersComponent } from './offers/offers.component';
import { OrdersComponent } from './orders/orders.component';
import { PrintInvoiceComponent } from './print-invoice/print-invoice.component';
import { ProjectsComponent } from './projects/projects.component';
import { ServicesComponent } from './services/services.component';
import { SettingsComponent } from './settings/settings.component';
import { TasksComponent } from './tasks/tasks.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { EditOrderComponent } from './edit-order/edit-order.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ViewOfferComponent } from './view-offer/view-offer.component';
import { EditOfferComponent } from './edit-offer/edit-offer.component';
import { ProfileComponent } from './profile/profile.component';
import { PrintInvoiceExternalComponent } from './print-invoice-external/print-invoice-external.component';
import { GenerateExternalInvoiceComponent } from './generate-external-invoice/generate-external-invoice.component';
import { EditExternalInvoiceComponent } from './edit-external-invoice/edit-external-invoice.component';
import { AutomaticInvoicesComponent } from './automatic-invoices/automatic-invoices.component';
import { AddAutomaticInvoiceComponent } from './add-automatic-invoice/add-automatic-invoice.component';
import { EditAutomaticInvoiceComponent } from './edit-automatic-invoice/edit-automatic-invoice.component';
import { StorehousesComponent } from './storehouses/storehouses.component';
import { VirtualItemsComponent } from './virtual-items/virtual-items.component';
import { StorehouseCategoriesComponent } from './storehouse-categories/storehouse-categories.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'invoices', component: InvoicesComponent },
  { path: 'offers', component: OffersComponent },
  { path: 'orders', component: OrdersComponent },
  { path: 'clients', component: ClientsComponent },
  { path: 'invoice-addons', component: InvoiceAddonsComponent },
  { path: 'tasks', component: TasksComponent },
  { path: 'projects', component: ProjectsComponent },
  { path: 'expenses', component: ExpensesComponent },
  { path: 'add-invoice/:coming_from/:coming_from_id', component: AddInvoiceComponent },
  { path: 'add-invoice-addon', component: AddInvoiceAddonComponent },
  { path: 'add-offer', component: AddOfferComponent },
  { path: 'add-order', component: AddOrderComponent },
  { path: 'add-client', component: AddClientComponent },
  { path: 'add-automatic-invoice', component: AddAutomaticInvoiceComponent },
  { path: 'add-client-group', component: AddClientGroupComponent },
  { path: 'add-service', component: AddServiceComponent },
  { path: 'edit-invoice/:id', component: EditInvoiceComponent },
  { path: 'edit-order/:id', component: EditOrderComponent },
  { path: 'edit-offer/:id', component: EditOfferComponent },
  { path: 'edit-external-invoice/:id', component: EditExternalInvoiceComponent },
  { path: 'edit-automatic-invoice/:id', component: EditAutomaticInvoiceComponent },
  { path: 'generate-external-invoice/:id/:currency/:currency_rate', component: GenerateExternalInvoiceComponent },
  { path: 'print-invoice', component: PrintInvoiceComponent },
  { path: 'print-invoice-external', component: PrintInvoiceExternalComponent },
  { path: 'view-offer', component: ViewOfferComponent },
  { path: 'view-order', component: ViewOrderComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'client-groups', component: ClientGroupsComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'automatic-invoices', component: AutomaticInvoicesComponent },
  { path: 'storehouses', component: StorehousesComponent },
  { path: 'storehouse_categories', component: StorehouseCategoriesComponent },
  { path: 'virtual-items', component: VirtualItemsComponent },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
