import { Component, ViewChild, ElementRef } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';
import { HttpClient } from '@angular/common/http';

declare var sha1;
@Component({
  selector: 'app-virtual-items',
  templateUrl: './virtual-items.component.html',
  styleUrls: ['./virtual-items.component.css']
})
export class VirtualItemsComponent {
  @ViewChild('inputImage0', { static: false }) inputImage0: ElementRef;
  @ViewChild('inputImage1', { static: false }) inputImage1: ElementRef;
  @ViewChild('inputImage2', { static: false }) inputImage2: ElementRef;
  @ViewChild('inputImage3', { static: false }) inputImage3: ElementRef;
  @ViewChild('inputImage4', { static: false }) inputImage4: ElementRef;

  status:any = -1;
  offers:any = [];
  page:any = 1;
  limit:any = "10";
  available_storages:any = [];
  available_categories:any = [];
  filtered_available_categories:any = [];
  base64String:any = "";

  sideModal:any = { open:false, 'cntx':'', obj:{} }
  modal:any = { open:false, 'cntx':'', obj:{} }
  constructor(public languageService:LanguageServiceService, public system:SystemService, public http:HttpClient) {
    this.system.GetVirtualItems(1, 10);
    this.GetAvailableStorehouses();
    this.GetAvailableStorehousesCategories();
  }
  ngOnInit() { this.system.GetVirtualItems(this.page, this.limit); }

  
  // ********** MODAL FUNCTIONS ****************//
  openModal(cntx, obj) { this.modal = { open:true, 'cntx':cntx, 'obj':obj }; }
  closeModal() { this.modal.open = false; }
  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(this.sideModal.cntx == 'edit-storehouse-category') {
      this.sideModal.obj.image_file = {};
    } else if(this.sideModal.cntx == 'edit-product') {
      this.ChangedStorehouse();
      this.sideModal.obj['image_files'] = [{}, {}, {}, {}, {}];
      this.sideModal.obj['images'] = ['', '', '', '', ''];
      this.sideModal.obj['base'] = ['', '', '', '', ''];
    }
  }
  closeSideModal() { this.sideModal.open = false; }
  // *******************************************//

  // ********************** HTTP FUNCTIONS **********************//
  StartAddProduct() {
    this.sideModal.obj.image_upload_count = 0;
    this.sideModal.obj.total_image_upload_count = 0;
    for(var pi = 0; pi < this.sideModal.obj.base.length; pi++) {
      if(this.sideModal.obj.base[pi] != '') {
        this.sideModal.obj.total_image_upload_count += 1;
      }
    }
    for(var pi = 0; pi < this.sideModal.obj.total_image_upload_count; pi++) {
      this.UploadProductPhoto(pi);
    }
  }

  AddProduct() {
    let images_reformed = [];
    for(var pi = 1; pi < this.sideModal.obj.images.length; pi++) {
      if(this.sideModal.obj.images[pi] != '') {
        images_reformed.push(this.sideModal.obj.images[pi]);
      }
    }
    this.http.post("https://api.nexa.bg/storage-items", { 'storage_item': { 'name':this.sideModal.obj.name, 'description':this.sideModal.obj.description, 'image':this.sideModal.obj.images[0], 'additional_images':JSON.stringify(images_reformed), 'storage_id':this.sideModal.obj.storage_id, 'category_id':this.sideModal.obj.category_id, 'ean':this.sideModal.obj.ean, 'quantity':this.sideModal.obj.quantity, 'sku':this.sideModal.obj.sku, 'price':this.sideModal.obj.price, 'active':1 }}, { withCredentials: true })
    .subscribe(resp => {
      this.closeSideModal();
      this.system.GetVirtualItems(this.page, this.limit);
    });
  }

  UploadProductPhoto(photo_idx) {
    const formData = new FormData();
    formData.append('file', this.sideModal.obj.image_files[photo_idx]);  

    this.http.post('https://images.nexa.bg/upload_product_image.php?u=' + sha1(this.system.ReturnMe().uid.toString()) + "&p=" + this.generateSafeUUID() + "&ui=" + this.system.ReturnMe().uid.toString(), formData)
    .subscribe(response => {
      this.sideModal.obj.images[photo_idx] = 'https://images.nexa.bg/' + response['file'];
      console.log('File uploaded successfully:', response);
      this.sideModal.obj.image_upload_count += 1;
      if(this.sideModal.obj.image_upload_count == this.sideModal.obj.total_image_upload_count) {
        this.AddProduct();
      }

    });
  }

  StartEditProduct() {
    let images_reformed = [];
    for(var pi = 1; pi < this.sideModal.obj.images.length; pi++) {
      if(this.sideModal.obj.images[pi] != '') {
        images_reformed.push(this.sideModal.obj.images[pi]);
      }
    }
    if(images_reformed.length == 0) {
      this.http.put("https://api.nexa.bg/storage-items/" + this.sideModal.obj.uid, { 'storage_item': { 'name':this.sideModal.obj.name, 'description':this.sideModal.obj.description, 'image':this.sideModal.obj.image, 'additional_images':JSON.stringify(images_reformed), 'storage_id':this.sideModal.obj.storage_id, 'category_id':this.sideModal.obj.category_id, 'ean':this.sideModal.obj.ean, 'quantity':this.sideModal.obj.quantity, 'sku':this.sideModal.obj.sku, 'price':this.sideModal.obj.price, 'active':1 }}, { withCredentials: true })
      .subscribe(resp => {
        this.closeSideModal();
        this.system.GetVirtualItems(this.page, this.limit);
      });
    }
  }

  EditProduct() {

  }

  RemoveProduct() {
    if(this.modal.obj.additional_images != null) {
      for(var ai = 0; ai < this.modal.obj.additional_images.length; ai++) {
        this.http.post('https://images.nexa.bg/remove_image.php', { 'file_path':this.modal.obj.additional_images[ai] })
        .subscribe(response => {});
      }
    }
    if(this.modal.obj.image != '') {
      this.http.post('https://images.nexa.bg/remove_image.php', { 'file_path':this.modal.obj.image })
      .subscribe(response => {
        this.http.delete("https://api.nexa.bg/storage-items/" + this.modal.obj.uid, { withCredentials: true })
        .subscribe(resp => {
          this.closeModal();
          this.system.GetVirtualItems(this.page, this.limit);
        });
      });
    } else {
      this.http.delete("https://api.nexa.bg/storage-items/" + this.modal.obj.uid, { withCredentials: true })
      .subscribe(resp => {
        this.closeModal();
        this.system.GetVirtualItems(this.page, this.limit);
      });
    }
  }
  
  GetAvailableStorehouses() {
    this.http.get("https://api.nexa.bg/storages?page=1&limit=1000", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_storages = response['storages'];
    });
  }

  GetAvailableStorehousesCategories() {
    this.http.get("https://api.nexa.bg/storage-categories?page=1&limit=1000", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_categories = response['storage_categories'];
    });
  }

  UpdateQuantities() {
    for(var bi = 0; bi < this.sideModal.obj.items.length; bi++) {
      let filtered_item = this.system.ReturnVirtualitems().filter(item => item.ean == this.sideModal.obj.items[bi]['barcode']);
      if(filtered_item.length > 0) {
        filtered_item[0].quantity += this.sideModal.obj.items[bi]['quantity'];
        filtered_item[0].additional_images = JSON.stringify(filtered_item[0]['additional_images']);
        this.http.put("https://api.nexa.bg/storage-items/" + filtered_item[0].uid, { 'storage_item': filtered_item[0]}, { withCredentials: true })
        .subscribe(resp => {
          this.closeSideModal();
          this.system.GetVirtualItems(this.page, this.limit);
        });
      }
    }
  }

  // ************************************************************//

  // *************** IMAGE FUNCTIONS ****************//
  triggerFileUpload(num) {
    if(num == 0) { this.inputImage0.nativeElement.click(); }
    if(num == 1) { this.inputImage1.nativeElement.click(); }
    if(num == 2) { this.inputImage2.nativeElement.click(); }
    if(num == 3) { this.inputImage3.nativeElement.click(); }
    if(num == 4) { this.inputImage4.nativeElement.click(); }
  }

  onFileSelected(event: Event, num) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.sideModal.obj.image_files[num] = file;
      this.convertToBase64(file, num);
    }
  }

  convertToBase64(file: File, num) {
    const reader = new FileReader();
    reader.onload = () => {
      this.sideModal.obj.base[num] = (reader.result as string).split(',')[1];
    };
    reader.readAsDataURL(file);
  }
  // ************************************************//

  // ********************** UTILITY FUNCTIONS *******************//
  switchType() { this.system.GetVirtualItems(this.page, this.limit); }
  switchLimit() { this.system.GetVirtualItems(this.page, this.limit); }
  goToPage(page) { this.page = page; this.system.GetVirtualItems(this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetVirtualItems(this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetVirtualItems(this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetVirtualItems(this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalVirtualItemsPage(); this.system.GetVirtualItems(this.page, this.limit); }

  ChangedStorehouse() {
    this.filtered_available_categories = this.available_categories.filter(category => category.storage_id == this.sideModal.obj.storage_id);
  }

  generateSafeUUID() {
    let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let uuid = '';
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uuid += chars.charAt(randomIndex);
    }
    return uuid;
  }

  ChangedBarcodeField(barcode, i) {
    if(barcode.barcode.length > 0 || barcode.quantity.length > 0) {
      let counter = i + 1;
      if(counter == this.sideModal.obj.items.length) {
        this.sideModal.obj.items.push({barcode:"", quantity:0 });
      }
    }
  }
  // ************************************************************// 

  // ************** RETURN FUNCTIONS **********//
  ReturnStorageName(storage_id) {
    let filtered_storage = this.available_storages.filter(storage => storage.uid == storage_id);
    if(filtered_storage.length > 0) { return filtered_storage[0].name; }
    else { return "Неразпределен"; }
  }
  ReturnCategoryName(category_id) {
    let filtered_category = this.available_categories.filter(category => category.uid == category_id);
    if(filtered_category.length > 0) { return filtered_category[0].name; }
    else { return "Неразпределен"; }
  }
  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }
  ReturnShadowCopy(obj) { return JSON.parse(JSON.stringify(obj)); }
  // ******************************************//
}
