import { Component } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { SystemService } from './system.service';
import { Router, RouterStateSnapshot } from '@angular/router';
import { LanguageServiceService } from './language-service.service';
import { DomSanitizer } from '@angular/platform-browser';

declare var sha1;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'erp';
  error:any = false;

  salesOpen = false;
  allowSalesClose = false;

  organizationOpen = false;
  allowOrganizationClose = false;
  
  clientsOpen = false;
  allowClientsClose = false;

  profileOpen = false;
  allowProfileClose = false;

  productsOpen = false;
  allowProductsClose = false;

  loginPassword:any = "";
  loginEmail:any = "";
  loginShowPassword:any = "password";
  loginRemember:any = false;

  avatarUrl:any;

  time_now:any;

  constructor(public system:SystemService, public router:Router, public languageService:LanguageServiceService, public domSanitizer:DomSanitizer) {
    if(localStorage.getItem('remember-me') != null && localStorage.getItem('remember-me') != 'false') {
      this.loginRemember = localStorage.getItem('remember-me');
      this.loginEmail = localStorage.getItem('email');
      this.loginRemember = localStorage.getItem('password');
      this.system.Login(this.loginEmail, this.loginPassword, true, this);
    }
    let dt_now = new Date();
    this.time_now = this.returnPaddedZero(dt_now.getHours(), 2) + ":" + this.returnPaddedZero(dt_now.getMinutes(), 2);
    setInterval(()=>{
      let dt_now = new Date();
      this.time_now = this.returnPaddedZero(dt_now.getHours(), 2) + ":" + this.returnPaddedZero(dt_now.getMinutes(), 2);
    }, 5000);
  }

  onClickedOutside(ev) {
    if(this.allowSalesClose) { this.salesOpen = false; }
    if(this.allowOrganizationClose) { this.organizationOpen = false; }
    if(this.allowClientsClose) { this.clientsOpen = false; }
    if(this.allowProductsClose) { this.productsOpen = false; }
  }

  switchShowPassword() {
    if(this.loginShowPassword == 'text') { this.loginShowPassword = 'password'; }
    else { this.loginShowPassword = 'text'; }
  }

  switchSalesOn() {
    this.allowSalesClose = false;
    this.salesOpen = true;
    setTimeout(()=>{
      this.allowSalesClose = true;
    }, 250);
  }

  switchOrganizationOn() {
    this.allowOrganizationClose = false;
    this.organizationOpen = true;
    setTimeout(()=>{
      this.allowOrganizationClose = true;
    }, 250);
  }

  switchClientsOn() {
    this.allowClientsClose = false;
    this.clientsOpen = true;
    setTimeout(()=>{
      this.allowClientsClose = true;
    }, 250);
  }

  switchProfileOn() {
    this.allowProfileClose = false;
    this.profileOpen = true;
    setTimeout(()=>{
      this.allowProfileClose = true;
    }, 250);
  }

  switchProductsOn() {
    this.allowProductsClose = false;
    this.productsOpen = true;
    setTimeout(()=>{
      this.allowProductsClose = true;
    }, 250);
  }

  onProfileOutside(event) {
    if(this.allowProfileClose) { this.profileOpen = false; }
  }

  clearWindow() {
    if(this.allowSalesClose) { this.salesOpen = false; }
    if(this.allowOrganizationClose) { this.organizationOpen = false; }
    if(this.allowClientsClose) { this.clientsOpen = false; }
    if(this.allowProductsClose) { this.productsOpen = false; }
  }

  returnLocation() {
    const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
    return snapshot.url;
  }

  returnLocationIsFinancial() {
    const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
    let url = snapshot.url;
    if(url == '/orders' || url == '/invoices' || url == '/offers' || url == '/invoice-addons' || url == "/automatic-invoices") {
      if(url == '/orders' && this.system.ReturnMe().layout == 'ONLINE-STORE') { return false; }
      return true;
    } else {
      return false;
    }
  }

  returnLocationIsClient() {
    const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
    let url = snapshot.url;
    if(url == '/clients' || url == '/client-groups' || url == '/contacts') {
      return true;
    } else {
      return false;
    }
  }


  ReturnAvatar() {
    if(this.avatarUrl != this.returnSanitiziedAvatar("https://crm.nexa.bg/avatars/" + sha1(this.system.ReturnMe().uid.toString()))) {
      let avatar = "https://crm.nexa.bg/avatars/" + sha1(this.system.ReturnMe().uid.toString());
      this.avatarUrl = this.returnSanitiziedAvatar(avatar);
      return this.avatarUrl;  
    } else {
      return this.avatarUrl;
    }

  }

  ReturnDefaultAvatar() {
    let avatar = 'assets/placeholder.jpeg';
    return avatar;
  }

  returnSanitiziedAvatar(url) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  ReturnTime() {
    return this.time_now;
  }

  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  ReturnAvatarSha(user) {
    return sha1(user.uid.toString());
  }
}
