import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { LanguageServiceService } from '../language-service.service';
import { SystemService } from '../system.service';

import * as html2pdf from 'html2pdf.js';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
declare var $;
@Component({
  selector: 'app-automatic-invoices',
  templateUrl: './automatic-invoices.component.html',
  styleUrls: ['./automatic-invoices.component.css']
})
export class AutomaticInvoicesComponent implements OnInit {
  type:any = "all";
  invoices:any = [];
  page:any = 1;
  limit:any = "10";
  searchTimeout:any;
  search:any = "";
  showDone:any = false;
  stats_rows:any = "";

  modal:any = { open:false, 'cntx':'', obj:{} }

  latest_invoice_num:any = -1;
  latest_proforma_num:any = -1;
  order_by:any = "`date_added` DESC, `num` DESC";
  direction:any = "DESC";

  sideModal:any = { open:false, 'cntx':'', obj:{} }

  @ViewChild('date_start') date_start: ElementRef;
  @ViewChild('date_end') date_end: ElementRef;



  constructor(public languageService:LanguageServiceService,public router:Router, public route:ActivatedRoute,public system:SystemService, public http:HttpClient){

  }
  
  switchType() { 
    this.system.GetAutomaticInvoices(this.page, this.limit);
  }

  switchLimit() {
    this.system.GetAutomaticInvoices(this.page, this.limit);
  }

  SearchInvoices() {
    // clearTimeout(this.searchTimeout);
    // this.searchTimeout = setTimeout(()=>{
    //   this.system.SearchInvoices(this.search, 1);
    // }, 500);
  }

  RemoveAutomatic() {
    this.http.delete("https://api.nexa.bg/auto-invoices/" + this.modal.obj.uid, {
      withCredentials: true
    })
    .subscribe(response => { this.system.GetAutomaticInvoices(this.page, this.limit);this.closeModal(); });
  }

  AnullInvoice() {
    this.http.put("https://api.nexa.bg/invoices/" + this.modal.obj.uid, { 'invoice': this.modal.obj }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.system.GetAutomaticInvoices(this.page, this.limit); this.closeModal();
    });
  }
  
  ngOnInit() {
    this.system.GetAutomaticInvoices(this.page, this.limit);
  }

  goToPage(page) { this.page = page; this.system.GetAutomaticInvoices(this.page, this.limit); }
  NextPage() { this.page += 1; this.system.GetAutomaticInvoices(this.page, this.limit); }
  PrevPage() { this.page -= 1; this.system.GetAutomaticInvoices(this.page, this.limit); }
  StartPage() { this.page = 1; this.system.GetAutomaticInvoices(this.page, this.limit); }
  LastPage() { this.page = this.system.ReturnTotalInvoicePages(); this.system.GetAutomaticInvoices(this.page, this.limit); }

  openStatus(invoice) {
    invoice.allowed_close = false;
    invoice.open_status = true;
    setTimeout(()=>{
      invoice.allowed_close = true;
    }, 250);
  }

  openModal(cntx, obj) { 
    this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
    if(obj.contacts.length > 0) {
      this.modal.obj.selected_contact = obj.contacts[0].uid;
    }
  }
  closeModal() { this.modal.open = false; }

  openInvoiceDropdown(invoice) {
    invoice.show = true;
    invoice.allowedClose = false;
    setTimeout(()=> { invoice.allowedClose = true; }, 250);
  }
  onInvoiceDropDownClose(ev, invoice) {
    if(invoice.allowedClose) {
      invoice.show = false;
    }
  }
  returnDateForSend(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "" + date_reorder[1] + "" + date_reorder[0];
  }
  returnFormattedDate(dt) {
    let split_date = dt.split("T");
    let date_reorder = split_date[0].split("-");
    return date_reorder[2] + "." + date_reorder[1] + "." + date_reorder[0];
  }
  returnIsRemoveAllowed(invoice) {
    if(invoice.type == 'proforma') {
      if(invoice.num == this.latest_proforma_num) { return true; } else { return false; }
    } else {
      if(invoice.num == this.latest_invoice_num) { return true; } else { return false; }
    }
  }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
      if(cntx == 'stats') {
          $( "#date_start" ).datepicker({ dateFormat: 'dd.mm.yy' });
          $( "#date_end" ).datepicker({ dateFormat: 'dd.mm.yy' });
      }
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }

  returnPriceWithCurrency(invoice,price) {
    let str = '';
    if(invoice.currency_position == 'front') {
      str += invoice.currency_label;
      if(invoice.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(invoice.currency_spacing == 1) { str += ' ' }
      str += invoice.currency_label;
    }
    return str;
  }

  returnStandardTotal(invoice) {
    let total = 0.00;
    for(let i = 0; i < invoice.items.length; i++) {
      total += invoice.items[i].price * invoice.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal(invoice) {
    let total = 0.00;
    for(let i = 0; i < invoice.items.length; i++) {
      total += (invoice.items[i].price * invoice.items[i].quantity) * ( invoice.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal(invoice) {
    return (parseFloat(this.returnStandardTotal(invoice)) + parseFloat(this.returnVatTotal(invoice))).toFixed(2);
  }

  returnPaymentTypeString(invoice) {
    if(invoice.type_payment == '1') { return this.languageService.ReturnTranslatedLabel('label_payment_bank'); }
    else if(invoice.type_payment == '2') { return this.languageService.ReturnTranslatedLabel('label_payment_post'); }
    else if(invoice.type_payment == '3') { return this.languageService.ReturnTranslatedLabel('label_payment_cash'); }
  }

  ReturnInvoiceType(invoice) {
    if(invoice.subtype == 'invoice') { return this.languageService.ReturnTranslatedLabel('label_invoice'); }
    else { return this.languageService.ReturnTranslatedLabel('label_proforma'); }
  }

  ReturnShadowCopy(obj) { return JSON.parse(JSON.stringify(obj)); }

  returnFixed(num) { return num.toFixed(2); }
}
