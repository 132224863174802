import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageServiceService } from '../language-service.service';
import { SystemService, Product } from '../system.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.css']
})
export class AddOrderComponent {
  items:any = [];
  available_clients = [];
  results:any = [];
  order:any = { client_id:-1, client_name:"", client_data:{}, client:{}, date_added:'00.00.0000', items:[{ id:1, name:"", quantity:1, vat:20, unit:'бр.', price:0.00 }], 'short_description':''};

  sideModal:any = { open:false, 'cntx':'', 'obj':{} };

  constructor(public languageService:LanguageServiceService,private http:HttpClient, public router:Router, public system:SystemService, public location:Location) {
    let dt = new Date();
    this.order.date_added = this.returnPaddedZero(dt.getDate(), 2) + "." + this.returnPaddedZero(dt.getMonth() + 1, 2) + "." + dt.getFullYear();
  
    
    let my_default_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.name == this.system.ReturnProfile()['currency'])[0];
    this.order.currency_name = my_default_currency.name;
    this.order.currency_label = my_default_currency.label;
    this.order.currency_position = my_default_currency.position;
    this.order.currency_spacing = my_default_currency.spacing;

    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      console.log(response);
      this.available_clients = response['clients'];
    });
  }

  changedRow(idx, item) {
    this.ChangedName(item);
    if(idx == this.order.items.length - 1) {
      if(this.order.items[idx].name.length != 0 || this.order.items[idx].quantity != 0 || this.order.items[idx].price != 0) {
        //this.invoice.items.push({ id:1, name:"", quantity:1, vat:20, unit:this.system.ReturnAvailableUnits()[0].name, price:0.00 });
        this.order.items.push(new Product(idx, "", 1, 20, this.system.ReturnAvailableUnits()[0].name, 0.00));
      } 
    }
  }

  focusSelect(item) {
    item.selecting = true;
    this.results = [];
  }

  clearSelect(item) {
    setTimeout(() => {
      item.selecting = false;
    }, 250);
  }

  openSideModal(cntx, obj) { 
    this.sideModal = { open:true, 'cntx':'', 'obj':obj }
    setTimeout(()=>{
      this.sideModal.cntx = cntx;
    }, 250);
  }
  closeSideModal() { this.sideModal.open = false; }

  changedCurrency() {
    let selected_currency = this.system.ReturnAvailableCurrencies().filter(currency => currency.name == this.order.currency_name)[0];
    this.order.currency_label = selected_currency.label;
    this.order.currency_position = selected_currency.position;
    this.order.currency_spacing = selected_currency.spacing;
  }

  selectResult(item, result) {
    item.name = result.name;
    item.name += ' - ' + result.description;
    item.price = result.price;
    item.vat = result.vat;
    if(item.quantity == 0) { item.quantity = 1; }
    item.selecting = false;
  }

  ChangedName(item) {
    this.http.post("https://api.nexa.bg/services/search/name", { 'name':item.name }, {
      withCredentials: true
    })
    .subscribe(response => {
      this.results = response['services'];
    });
  }

  selectedClient() {
    let client = this.available_clients.filter(client => client.uid == this.order.client_id)[0];
    this.http.get("https://api.nexa.bg/clients/" + this.order.client_id, {
      withCredentials: true
    })
    .subscribe(response => {
      this.order.client_name = response['name'];
      this.order.client_data = response;
      this.order.going_to = response['mol'];
    });
  }

  AddClient() {
    this.http.post("https://api.nexa.bg/clients", { 'client':this.sideModal.obj}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.GetAvailableClients();
      this.closeSideModal();
    });
  }

  
  GetAvailableClients() {
    this.http.get("https://api.nexa.bg/clients", {
      withCredentials: true
    })
    .subscribe(response => {
      this.available_clients = response['clients'];
    });
  }


  AddOrder() {
    let split_date_add = this.order.date_added.split(".");
    let dt_added = new Date(split_date_add[2] + "-" + split_date_add[1] + "-" + split_date_add[0]);
    let products = "";
    for(var pi = 0; pi < this.order.items.length; pi++) {
      if(this.order.items[pi].name.length > 0 || this.order.items[pi].price != 0) {
        products += this.order.items[pi].name + "|" + this.order.items[pi].price + "|" + this.order.items[pi].unit +  "|" + this.order.items[pi].quantity + "|" + this.order.items[pi].vat + ";";
      }
    }
    let reformed_dt_added = dt_added.getFullYear() + "-" + this.returnPaddedZero(dt_added.getMonth() + 1, 2) + "-" + this.returnPaddedZero(dt_added.getDate(), 2);
    this.http.post("https://api.nexa.bg/orders", { 'order':{ 'client_name':this.order.client_name, 'products':products, 'client_id':this.order.client_id, 'date_added':reformed_dt_added, total:this.returnTotal(),
    status:0,short_description:this.order.short_description, user_added:-1, date_executed:'0000-00-00', 'currency':'лв.', 'added_by':this.system.ReturnMe().first_name + " "  + this.system.ReturnMe().last_name }}, {
      withCredentials: true
    })
    .subscribe(response => {
      this.router.navigate(['/orders']);
    });
  }

  goBack() {
    this.location.back()
  }

  

  

  ReturnIsAllowedToAddClient(){ 
    if(this.sideModal.obj.type == 'company') {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    } else {
      if(this.sideModal.obj.name.length < 2) {
        return false;
      } else {
        return true;
      }
    }
  }


  removeItemFromOrder(idx) {
    this.order.items.splice(idx, 1);
  }


  returnFixed(num) { return num.toFixed(2); }
  returnPaddedZero(num, len) {
    var n = Math.abs(num);
    var zeros = Math.max(0, len - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString+n;
  }

  returnStandardTotal() {
    let total = 0.00;
    for(let i = 0; i < this.order.items.length; i++) {
      total += this.order.items[i].price * this.order.items[i].quantity;
    }
    return total.toFixed(2);
  }
  returnVatTotal() {
    let total = 0.00;
    for(let i = 0; i < this.order.items.length; i++) {
      total += (this.order.items[i].price * this.order.items[i].quantity) * ( this.order.items[i].vat / 100);
    }
    return total.toFixed(2);
  }
  returnTotal() {
    return (parseFloat(this.returnStandardTotal()) + parseFloat(this.returnVatTotal())).toFixed(2);
  }

  returnPriceWithCurrency(price) {
    let str = '';
    if(this.order.currency_position == 'front') {
      str += this.order.currency_label;
      if(this.order.currency_spacing == 1) { str += ' '; }
      str += price;
    } else {
      str += price;
      if(this.order.currency_spacing == 1) { str += ' ' }
      str += this.order.currency_label;
    }
    return str;
  }
}
